import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { IonInput } from '@ionic/angular';

const regExpNumber = new RegExp(/\D/g);

@Directive({
    selector: '[appOnlyNumber]',
})

export class OnlyNumberDirective implements OnInit, OnDestroy {
    private eventSub: Subscription;
    constructor(
        private readonly ionInputEl: IonInput,
        private readonly controlDirective : NgControl
    ) {}

    public ngOnInit() :void {
        this.eventSub = this.ionInputEl.ionInput.subscribe((ev ) => {
            const value = (ev.target as any).value;

            // Removes non numeric characters
            const filteredValue = value?.toString().replace(regExpNumber, '');

            // Update both the state
            this.controlDirective.control.setValue(Number(filteredValue));
        });
    }

    public ngOnDestroy(): void {
        this.eventSub.unsubscribe();
    }
}
