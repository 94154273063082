export enum NavPath {
    Auth = 'auth',
    Search = 'search',
    Profile = 'profile',
    Professional = 'professional',
    Message = 'message',
    Reviews = 'reviews',
    Service = 'service',
    Order = 'order',
    Orders = 'my-bookings',
    Bookmarks = 'bookmarks',
    ProfessionalLandingRedirect = 'for-performers',
    ProfessionalLanding = 'for-professionals',
    ProfessionalSchedule = 'professional-schedule',
    Eula = 'eula',
    Policy = 'policy',
    About = 'about',
    Main = '',
    Blog = 'blog',
    List = 'list',
    Outbox = 'outbox',
    Inbox = 'inbox',
    Registration = 'registration',
    Login = 'login',
    PasswordRecover = 'password-recover',
    Schedule = 'schedule',
    Services = 'services',
    ClientApplications = 'post-a-job',
    CreativeBanners = 'service-marketplace',
    ServiceCatalog = 'service-catalog',
    StepOne = 'step-one',
    Publish = 'publish',
    NotoficationsSettings = 'notifications-settings',
    AccessToJobsBoard = 'access-to-jobs-board',
    Locations = 'locations',
    Support = 'support'
}

export enum NavBranch {
    // auth path:
    Welcome = 'welcome',
    JobList = 'job-list',
    Login = 'login',
    Registration = 'registration',
    AfterRegistration = 'registration-tutor',

    // profile path:
    ContactAdd = 'contact-add',
    ContactAddDefault = 'contact-add-default',
    ContactEdit = 'contact-edit',
    LocationEdit = 'location-edit',
    LocationAdd = 'location-add',
    Delete = 'delete',

    // professional path:
    MyProfile = 'my-profile',
    MyServices = 'my-services',
    MySchedule = 'my-schedule',
    Profile = 'profile',
    Services = 'services',
    EnablePaymentSystem = 'enable-payment-system',
    Sber = 'sber',
    Schedule = 'schedule',
    ProfessionalContactAdd = 'professional-contact-add',
    ProfessionalContactAddDefault = 'professional-contact-add-default',
    ProfessionalContactEdit = 'professional-contact-edit',

    // message path:
    Chat = 'chat',

    // service path:
    Publish = 'publish',
    Preview = 'preview',
    Edit = 'edit',
    Add = 'add',
    Success = 'success',

    // orders path:
    Inbox = 'inbox',
    Outbox = 'outbox',
    Share = 'share',
    OrderList = 'bookings-list',
    OrderSearch = 'jobs-list-search',
    About = 'about',
    Categories = 'categories',
    //booking
    Direct = 'direct',
    CancelOrder = 'cancel-order',
    SuccessfulPublication = 'successful-publication',
    OrderResponse = 'order-response',
}

export enum PaymentSystem {
    //payment-system
    Root = 'payment-system',
    Successful = 'successful-payment',
    Failed = 'failed-payment',
    Warning = 'warnung-payment',
    WarningDesc = 'warnung-payment/:id',
    Autorization = 'payment-autorization/:id'
}

export enum NavParams {
    MasterId = 'master-id',
    CertificateId = 'certificate-id',
    EducationId = 'education-id',
    ExperienceId = 'experience-id',
    LocationId = 'location-id',
}

export enum NavQueryParams {
    redirectTo = 'redirectTo',
    newUser = 'newUser',
    goBack = 'goBack',
    required = 'required'
}
