<ion-footer>
    <div class="footer-wrapper">
        <div class="footer">
<!--            <div class="footer__logo">-->
<!--                <img routerDirection="root" routerLink="/" src="assets/images/logo.svg" alt=""/>-->
<!--            </div>-->
            <div class="footer__links">
                <div class="footer__links__navbar">
                    <div class="footer__links__navbar__col">
                        <span *ngFor="let item of links.footer.column_one" routerLink="/{{ item.url }}"  [id]="item.id" [attr.routerLink]="'/' + item.url">
                            {{ item.text | translate:  { name: configuration.professional.name | translate } }}
                        </span>
                    </div>
                    <div class="footer__links__navbar__col">
                        <span *ngFor="let item of links.footer.column_two" routerLink="/{{ item.url }}"  [id]="item.id" [attr.routerLink]="'/' + item.url">
                            {{ item.text | translate:  { name: configuration.professional.name | translate } }}
                        </span>
                        <span>
                            {{ 'footer.support' | translate }}
                            <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>
                        </span>
                        <span>
                            {{ 'footer.cooperation' | translate }}
                            <a href="mailto:{{infoEmail}}">{{infoEmail}}</a>
                        </span>
                    </div>
                </div>
                <div class="footer__links__social">
                    <a *ngIf="configuration.social.facebook.visible" [href]="configuration.social.facebook.url">
                        <ion-icon [src]="configuration.social.facebook.icon" id="social-facebook"></ion-icon>
                    </a>
                    <a *ngIf="configuration.social.instagram.visible" [href]="configuration.social.instagram.url">
                        <ion-icon [src]="configuration.social.instagram.icon" id="social-instagram"></ion-icon>
                    </a>
                    <a *ngIf="configuration.social.vk.visible" [href]="configuration.social.vk.url">
                        <ion-icon [src]="configuration.social.vk.icon" id="social-vk"></ion-icon>
                    </a>
                    <a *ngIf="configuration.social.linkedin.visible" [href]="configuration.social.linkedin.url">
                        <ion-icon [src]="configuration.social.linkedin.icon" id="social-icon"></ion-icon>
                    </a>
                    <a *ngIf="configuration.social.twitter.visible" [href]="configuration.social.twitter.url">
                        <ion-icon [src]="configuration.social.twitter.icon" id="social-twitter"></ion-icon>
                    </a>
                    <a *ngIf="configuration.social.youtube.visible" [href]="configuration.social.youtube.url">
                        <ion-icon [src]="configuration.social.youtube.icon" id="social-youtube"></ion-icon>
                    </a>
                    <a *ngIf="configuration.social?.tiktok?.visible" [href]="configuration?.social?.tiktok?.url">
                        <ion-icon [src]="configuration.social?.tiktok?.icon" id="social-tiktok"></ion-icon>
                    </a>
                </div>
            </div>
            <div class="footer__copy">
                © {{ ageProject }} {{ tr.currentLang !== 'ru' ? configuration.app_title : 'СервисСейлс' }}
                | {{ 'footer.copy' | translate }} | {{ 'footer.protected' | translate }}
            </div>
        </div>
    </div>
</ion-footer>
