import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'transDate',
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {

    private readonly defLang = 'en';
    constructor(private readonly translateService: TranslateService) {}

    public transform(value: any, pattern: string = 'mediumDate'): any {
        try {
            const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);

            return datePipe.transform(value, pattern);
        } catch (e) {
            // console.warn(e.message);
            const datePipe: DatePipe = new DatePipe(this.defLang);

            return datePipe.transform(value, pattern);
        }
    }
}
