import {
    Component, ElementRef,
    EventEmitter,
    Input,
    Output, ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ClientApplicationPhoto } from '@app/api/models/client-application-photo';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent {
    @Input() public uploadForm: FormGroup;
    @Input() public images: ClientApplicationPhoto[];
    @Input() public hint: string;
    @Input() public slideOptions: any = {slidesPerGroup: 3, spaceBetween: 16};
    @Output() public addFiles = new EventEmitter<FileList>();
    @Output() public closeImage = new EventEmitter<ClientApplicationPhoto>();
    @ViewChild('fileUpload') public fileUploadRef: ElementRef;

    public upload(files: FileList): void {
        this.addFiles.emit(files);
        this.fileUploadRef.nativeElement.value = '';
    }
}
