import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild,
} from '@angular/core';
import { map, mergeAll, mergeMap, tap } from 'rxjs/operators';
import { ProfessionalsService } from '@app/api/services/professionals.service';
import { Subcategory } from '@app/api/models/subcategory';
import { Category } from '@app/api/models/category';
import { IonicSelectableComponent } from 'ionic-selectable';

let myself = null;

@Component({
    selector: 'app-category-group',
    templateUrl: './category-group.component.html',
    styleUrls: ['./category-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryGroupComponent implements OnInit {
    public get model(): Subcategory {
        return this._model;
    }

    @Input() public set model(value: Subcategory) {
        if(!value) {
            this._model = null;
        } else {
            this._model = value;
        }
    }
    @ViewChild('ionicSelectableComponent') public ionicSelectableComponent: IonicSelectableComponent;
    public categories: Category[] = [];
    public subcategories: Subcategory[] = [];

    @Input() public title: string;
    @Input() public isEnabled: boolean = true;
    @Output() public setValueSignal = new EventEmitter<{
        subcategory: Subcategory;
        category: Category;
    }>();

    private _model: Subcategory;

    constructor(
        private readonly professionalsService: ProfessionalsService,
        private readonly cd: ChangeDetectorRef) {
            myself = this;
    }

    public ngOnInit(): void {
        this.professionalsService.professionalsCategoriesList({}).pipe(
            map(response => response.results),
            tap(d => this.categories = [...d]),
            mergeMap(categories => categories.map(category =>
                this.professionalsService.professionalsSubcategoriesList({ category: category.id }))),
            mergeAll(),
            map(d => d.results),
            map(subcategories => {
                this.subcategories = [...this.subcategories, ...subcategories];
                this.subcategories.sort(this.sortSubcategory.bind(this));
            }),
            tap(() => {
                if(!!this._model) {
                    this.title = this.categories.find(c => this._model.category === c.id)?.name ?? this.title;
                    this.setValueSignal.emit({
                        subcategory: this._model,
                        category: this.categories.find(c => c.id === this._model.category)
                    });
                }
            }),
            tap(() => this.cd.markForCheck()),
        ).subscribe();
    }

    public getGroupText(subcategory: Subcategory, portIndex: number, subcategories: any[]): string {
        if (portIndex === 0 || subcategory.category !== subcategories[portIndex - 1].category) {
            return myself.categories.find(c => c.id === subcategory.category).name;
        }

        return null;
    }

    public setValue(subcategory: Subcategory): void {
        this.title = !!subcategory? this.categories.find(c => c.id === subcategory.category).name: '';
        if(!!subcategory) {
            this.setValueSignal.emit({
                subcategory: subcategory,
                category: this.categories.find(c => c.id === subcategory.category)
            });
        } else {
            this.clear();
        }
    }

    public clear(): void {
        this.title = '';
        this.setValueSignal.emit({
            subcategory: null,
            category: null
        });
    }

    public sortSubcategory(a: Subcategory, b: Subcategory): number {
        const ac = this.categories.find(c => c.id === a.category);
        const bc = this.categories.find(c => c.id === b.category);

        return (ac.order * 1000 + a.order) - (bc.order * 1000 + b.order);
    }

}
