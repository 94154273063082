import { AfterViewInit, Component, HostListener } from '@angular/core';
import { NavPath } from '@app/core/constants/navigation.constants';
import { environment } from '@env/environment';
import { configuration } from '@conf/configuration';

const MOBILE = 999;

@Component({
    selector: 'app-creative-banners',
    templateUrl: './creative-banners.component.html',
    styleUrls: ['./creative-banners.component.scss'],
})
export class CreativeBannersComponent implements AfterViewInit{
    public banner1 = '';
    public banner2 = '';
    public isMobile = false;
    public NavPath = NavPath;
    public tab = 'prof';
    public readonly environment = environment;
    public readonly configuration = configuration;
    private readonly masterBanners = [
        'assets/images/banner/banner_m1.svg',
        'assets/images/banner/banner_m2.svg',
        'assets/images/banner/banner_m3.svg',
        'assets/images/banner/banner_m4.svg',
    ];
    private readonly userBanners = [
        'assets/images/banner/banner_u1.svg',
        'assets/images/banner/banner_u2.svg',
        'assets/images/banner/banner_u3.svg',
        'assets/images/banner/banner_u4.svg',

    ];

    constructor() {
        this.banner1 = this.masterBanners[Math.floor(Math.random() * this.masterBanners.length)];
        this.banner2 = this.userBanners[Math.floor(Math.random() * this.userBanners.length)];
    }

    public currentBunner(banners: []): { f: string; s: string } {
        const ind1 = Math.floor(Math.random() * banners.length);
        const ind2 = ind1 < banners.length -1 ? ind1 + 1 : 0;

        return { f: banners[ind1], s: banners[ind2] };
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
       this.checkMobile();
    }

    public ngAfterViewInit(): void {
        this.checkMobile();
    }

    public changeTab(e: any): void {
        this.tab = e.detail.value;
    }

    private checkMobile(): void {
        this.isMobile = window.innerWidth < MOBILE;
    }
}
