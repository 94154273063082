<div class="wrapper" *ngIf="closeButtonClass$ | async as btn" (click)="navigation()">
    <div class="horn"></div>
    <div [class]="'title' + btn">{{ 'promo.top-banner-text' | translate }}</div>
    <div class="decoration"></div>
    <div [class]="'close-area' + btn" (click)="hideButtonClicked.emit()">
        <ion-icon name="close-outline" class="close-area__button"></ion-icon>
    </div>
</div>


