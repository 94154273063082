import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-more-info',
    templateUrl: './more-info.component.html',
    styleUrls: ['./more-info.component.scss'],
})
export class MoreInfoComponent {
    public expanded = false;
    @Output() public moreInfoExpanded = new EventEmitter<boolean>();

    public expandHandler(): void {
        this.expanded = !this.expanded;
        this.moreInfoExpanded.emit(this.expanded);
    }
}
