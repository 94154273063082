/* eslint-disable */
// @ts-nocheck
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BlogPage } from '../models/blog-page';
@Injectable({
  providedIn: 'root',
})
class BlogService extends __BaseService {
  static readonly blogPagesListPath = '/blog/pages/';
  static readonly blogPagesReadPath = '/blog/pages/{slug}/';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * The blog page viewset.
   * @param params The `BlogService.BlogPagesListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page_size`: Number of results to return per page.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  blogPagesListResponse(params: BlogService.BlogPagesListParams): __Observable<__StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<BlogPage>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pageSize != null) __params = __params.set('page_size', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/blog/pages/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{count: number, next?: null | string, previous?: null | string, results: Array<BlogPage>}>;
      })
    );
  }
  /**
   * The blog page viewset.
   * @param params The `BlogService.BlogPagesListParams` containing the following parameters:
   *
   * - `search`: A search term.
   *
   * - `page_size`: Number of results to return per page.
   *
   * - `page`: A page number within the paginated result set.
   *
   * - `ordering`: Which field to use when ordering the results.
   */
  blogPagesList(params: BlogService.BlogPagesListParams): __Observable<{count: number, next?: null | string, previous?: null | string, results: Array<BlogPage>}> {
    return this.blogPagesListResponse(params).pipe(
      __map(_r => _r.body as {count: number, next?: null | string, previous?: null | string, results: Array<BlogPage>})
    );
  }

  /**
   * The blog page viewset.
   * @param slug undefined
   */
  blogPagesReadResponse(slug: string): __Observable<__StrictHttpResponse<BlogPage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/blog/pages/${encodeURIComponent(String(slug))}/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BlogPage>;
      })
    );
  }
  /**
   * The blog page viewset.
   * @param slug undefined
   */
  blogPagesRead(slug: string): __Observable<BlogPage> {
    return this.blogPagesReadResponse(slug).pipe(
      __map(_r => _r.body as BlogPage)
    );
  }
}

module BlogService {

  /**
   * Parameters for blogPagesList
   */
  export interface BlogPagesListParams {

    /**
     * A search term.
     */
    search?: string;

    /**
     * Number of results to return per page.
     */
    pageSize?: number;

    /**
     * A page number within the paginated result set.
     */
    page?: number;

    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
  }
}

export { BlogService }
