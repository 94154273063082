import { declineDefault } from '@app/core/declination-cities/functions/decline-default';
import {
    constantizeGenderInRules,
    findRule,
    applyRule
} from '@app/core/declination-cities/functions/decline-functions';
import {
    cityRules,
    frozenWords,
    frozenParts,
    frozenPartsAfter
} from '@app/core/declination-cities/rules/city-rules';
import { GendersEnum } from '@app/core/declination-cities/models/genders.enum';
import { GenderStrT } from '@app/core/declination-cities/models/genders.types';
import { DeclensionEnum } from '@app/core/declination-cities/models/declensions.enum';
import { DeclentionStrT } from '@app/core/declination-cities/models/declension.types';


constantizeGenderInRules(cityRules);

export function cityIn(name: string, gender?: GenderStrT): string {
    return declineTo(name, DeclensionEnum.PREPOSITIONAL, gender);
}

function declineTo(name: string, wordCase: DeclentionStrT, gender?: GenderStrT): string {
    if (isFrozen(name, frozenWords)) { return name; };

    return name
        .split(/(\s|-)/g)
        .map((part, i, parts) => {
            if (isFrozenPart(part, i, parts)) { return part; };
            const rule = findRule(part, GendersEnum.NEUTER, cityRules);
            if (rule) {
                return applyRule(rule, part, wordCase);
            };

            return declineDefault(part, wordCase, gender) || part;
        })
        .join('');
}

function isFrozen(str: string, words: string[]): boolean {
    const strLower = str.toLowerCase();
    for (const word of words) {
        if (word === strLower) { return true; };
    }

    return false;
}

function isFrozenPart(part: string, i: number, parts: string[]): boolean {
    if (parts.length > 1) {
        if (isFrozen(part, frozenParts)) { return true; };
        for (let k = 0; k < i; k++) {
            if (isFrozen(parts[k], frozenPartsAfter)) { return true; };
        }
    }

    return false;
}