<ng-container [ngSwitch]="state">
    <app-user-place-selector
        [requiredAddress]="requiredAddress"
        [initLocation]="initLocation"
        (valueIsSet)="setLocationValue($event)"
        [title]="title"
        *ngSwitchCase="'client'"
    ></app-user-place-selector>
    <app-professional-place-selector
        [requiredAddress]="requiredAddress"
        [initLocation]="initLocation"
        (valueIsSet)="setLocationValue($event)"
        [title]="title"
        *ngSwitchCase="'professional'"
    ></app-professional-place-selector>
</ng-container>
