import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ViewChild,
} from '@angular/core';
import {
    ProfessionalLocationsService,
} from '@app/store/local-store/professional-locations/professional-locations.service';
import { mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { LocationResolverService, NgDestroyService } from '@app/core/services';
import { Select } from '@ngxs/store';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { Profile } from '@app/api/models/profile';
import { ProfessionalList } from '@app/api/models/professional-list';
import {
    PlaceSelectorDirective,
// eslint-disable-next-line max-len
} from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/place-selector.directive';
import { NavBranch, NavPath, NavQueryParams } from '@app/core/constants/navigation.constants';
import { Router } from '@angular/router';
import {
    PlaceSelectorComponent,
// eslint-disable-next-line max-len
} from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/place-selector/place-selector.component';

@Component({
    selector: 'app-professional-place-selector',
    templateUrl: './professional-place-selector.component.html',
    styleUrls: ['./professional-place-selector.component.scss'],
}) // TODO: fix thw whole class
export class ProfessionalPlaceSelectorComponent extends PlaceSelectorDirective implements AfterViewInit {
    public profile?: Profile;
    public professional?: ProfessionalList;

    @Select(CurrentUserSelectors.profile)
    public profile$!: Observable<Profile>;

    @Select(CurrentUserSelectors.professionals)
    public professionals$!: Observable<ProfessionalList>;

    @ViewChild(PlaceSelectorComponent, { read: PlaceSelectorComponent })
    private readonly placeSelector!: PlaceSelectorComponent;

    constructor(
        private readonly router: Router,
        private readonly destroy$: NgDestroyService,
        protected readonly cd: ChangeDetectorRef,
        private readonly fullLocationService: LocationResolverService,
        private readonly professionalLocationService: ProfessionalLocationsService,
    ) {
        super();
    }

    public ngAfterViewInit(): void {
        this.init();
        this.professionalLocationService.updateLocation$.pipe(
            tap(d => this.initLocation.next(d.id)),
            takeUntil(this.destroy$),
        ).subscribe();
    }

    public navigation(id: number): void {
        // @ts-ignore
        this.router.navigate([NavPath.Professional, `${this.professional.id}`,
            NavPath.Profile, NavBranch.LocationEdit, `${id}`], {
            queryParams: {
                ...(this.requiredAddress && { required: true }),
                [NavQueryParams.goBack]: true,
            },
        });
    }

    public addItem(): void {
        if (!this.professional?.id) {
            this.professionals$.pipe(take(1),
                tap(d => this.professional = d),
                tap(() => this.navigate()), takeUntil(this.destroy$)).subscribe();
        } else {
            this.navigate();
        }
    }

    protected override open(type: 'online' | 'client' | 'professional'): void {
        this.placeSelector?.open(type);
    }

    protected override init(): void {
        this.locationTextList = [];
        this.professionalLocationService.loadLocations().pipe(
            // map(list => list.filter(l => this.requiredAddress ? !!l?.address?.length : true)),
            switchMap(list => iif(() => !!list?.length, forkJoin(
                // @ts-ignore
                list.map(x => this.fullLocationService.getTextLocation(x))), of([]))),
            tap(d => this.locationTextList = d),
            mergeMap(() => this.profile$),
            tap(profile => this.profile = profile),
            mergeMap(() => this.professionals$),
            // @ts-ignore
            tap((d) => this.professional = d[0] ?? d),
            tap(() => this.cd.markForCheck()),
            takeUntil(this.destroy$)).subscribe();

        this.professionalLocationService.updateLocation$.pipe(
            // @ts-ignore
            mergeMap(ul => this.fullLocationService.getTextLocation(ul)),
            tap(ul => {
                let notexist = true;
                this.locationTextList.forEach(loc => {
                    if (loc.id === ul.id) {
                        loc.text = ul.text;
                        notexist = false;
                    }
                });
                if (notexist) {
                    this.locationTextList = [...this.locationTextList, ul];
                }
            }),
            tap(() => this.cd.markForCheck()), takeUntil(this.destroy$),
        ).subscribe();

        this.professionalLocationService.deleteLocation$.pipe(
            tap(plId => this.locationTextList
                = this.locationTextList.filter(l => l.id !== plId)),
            tap(() => this.cd.markForCheck()),
            takeUntil(this.destroy$)).subscribe();
    }

    private navigate(): void {
        // @ts-ignore
        this.router.navigate([NavPath.Professional, `${this.professional.id}`,
            NavPath.Profile, NavBranch.LocationAdd], {
            queryParams: {
                ...(this.requiredAddress && { required: true }),
                [NavQueryParams.goBack]: true,
            },
        });
    }
}
