<ion-list *ngIf="locations?.length" class="ion-no-padding">
    <ion-item
        *ngFor="let location of locations"
        class=""
        routerLink="location-edit/{{location.id}}"
        button
        detail
        detail-icon="create-outline">
        <ion-label *ngIf="location.is_default">
            <div class="label-loc"> {{ 'profile-form.main-address' | translate }}</div>
            <div class="text-loc">
                <app-location-viewer [location]="location"></app-location-viewer>
            </div>
        </ion-label>

        <ion-label *ngIf="!location.is_default">
            <div class="label-loc"> {{ 'profile-form.additional-address' | translate }}</div>
            <div class="text-loc">
                <app-location-viewer [location]="location"></app-location-viewer>
            </div>
        </ion-label>
    </ion-item>
</ion-list>
<app-add-button routerLink="location-add/"> {{'list-item.add-new-address'|translate}} </app-add-button>
