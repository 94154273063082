// TODO use date-fns for datetime formatting/transforming

import { getDaysInMonth } from 'date-fns';

export const intervalAliace = {
    min_1: 60,        // '1m'
    min_5: 300,       // '5m'
    min_15: 900,      // '15m'
    min_30: 1800,     // '30m'
    hour_1: 3600,     // '1h'
    hour_4: 14400,    // '4h'
    hour_6: 21600,    // '6h'
    hour_12: 43200,   // '12h'
    day_1: 86400      // '1d'
};

export function getCeilDateTime(d: Date, i: number): Date{
    return new Date(Math.ceil(new Date(d).getTime()/(i*1000)) *(i*1000));
}

export function addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
}

export function addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days); // getDate() increments month if necessary

    return newDate;
}

export function stripTime(date: Date): Date {
    const result = new Date(date);
    result.setHours(0, 0, 0, 0);

    return result;
}

export function getCurrentDay(): Date {
    return stripTime(new Date());
}

export function getLocalDateString(date: Date | string): string | null {
    if (!date) {
        return null;
    }

    if (typeof date === 'string') {
        date = new Date(date);
    }

    return `${date.getFullYear()}-${getMonthNumberFormatted(date)}-${date.getDate()}`;
}

export function getLocalDateTimeString(date: Date): string | null {
    if (!date) {
        return null;
    }

    const YYYY = date.getFullYear();
    const MM = getMonthNumberFormatted(date);
    const DD = padWithZero(date.getDate());
    const HH = padWithZero(date.getHours());
    const mm = padWithZero(date.getMinutes());
    const ss = padWithZero(date.getSeconds());

    // 'YYYY-MM-DDTHH:mm:ss'
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}`;
}

export function getMonthDateString(date: Date | string): string | null {
    if (!date) {
        return null;
    }

    if (typeof date === 'string') {
        date = new Date(date);
    }

    return `${date.getFullYear()}-${getMonthNumberFormatted(date)}`;
}

export interface StringDateTimeInterface {
    date: string;
    time: string;
}

export function fromDatetime(datetime: string | null | undefined): StringDateTimeInterface | null {
    return datetime
        ? {
              date: datetime.slice(0, 10),
              time: datetime.slice(11, 16),
          }
        : null;
}

export function calculateAge(birthday: string): number {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * returns first day of the month in the YYYY-MM-DD format
 */
export function getMonthFirstDay(month: number, year: number): string {
    const date = new Date(year, month);
    const monthStr = `0${date.getMonth() + 1}`.slice(-2);

    return `${date.getFullYear()}-${monthStr}-01`;
}

/**
 * returns last day of the month in the YYYY-MM-DD format
 */
export function getMonthLastDay(month: number, year: number): string {
    const date = new Date(year, month - 1);
    const monthStr = `0${date.getMonth() + 1}`.slice(-2);
    const dayStr = `0${getDaysInMonth(date)}`.slice(-2);

    return `${date.getFullYear()}-${monthStr}-${dayStr}`;
}

function getMonthNumberFormatted(date: Date): string {
    const month = date.getMonth() + 1;

    return padWithZero(month);
}

function padWithZero(value: number, size: number = 2): string {
    return value.toString().padStart(size, '0');
}
