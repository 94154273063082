export function hasNonEmptyValues(obj: object): boolean {
    return obj ? Object.values(obj).some(v => !!v) : false;
}

export function clear<T>(obj: T): T {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }

    return obj;
}

export function isEmptyObject(obj: object): boolean {
    return '{}' === JSON.stringify(obj);
}

export function getSize(obj: object): number {
    return Object.keys(obj ?? {}).length;
}