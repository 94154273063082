<ion-item>
    <ion-label [class.label-for-required-input]="true" color="medium" position="floating">
        {{ title | translate }}
    </ion-label>

    <ionic-selectable
        *ngIf="!!locationTextList?.length; else empty"
        canAddItem="true"
        canSearch="true"
        itemTextField="text"
        [formControl]="formLocation"
        [items]="locationTextList"
        [closeButtonText]="'global.cancel' | translate"
        [searchPlaceholder]="'global.titles.search' | translate"
        [searchFailText]="'ion-selectable.search-fail-text' | translate"
        [addButtonText]="'+ ' + ('list-item.add-new-address' | translate)"
        [modalCssClass]="'gl-place-select-modal'"
        (onClose)="onClose()"
        (onChange)="onChangeLocation($event)"
        (onAddItem)="addItemHandler($event)">
        <ng-template ionicSelectableItemTemplate let-item="item">
            <ion-label>
                <h6 *ngIf="item?.id === defaultLocation?.id">{{'profile-form.main-address'| translate}}</h6>
                {{item?.text}}
            </ion-label>
            <ion-button class="ion-no-margin" fill="clear" color="medium" (click)="navigation.emit(item?.id)">
                <ion-icon slot="icon-only" name="create-outline"></ion-icon>
            </ion-button>
        </ng-template>
        <ng-template ionicSelectableMessageTemplate>
            <ion-label class="error-desc" *ngIf="warningMessage">
                {{ 'profile-form.warning-message'| translate }}
            </ion-label>
        </ng-template>
        <ng-template ionicSelectableValueTemplate let-item="value">{{item?.text}}</ng-template>
    </ionic-selectable>
</ion-item>
<ng-template #empty>
    <ion-input (click)="emptyOpen()" class="empty-field">
        <div class="empty-mark">
        <div class="ionic-selectable-inner"><div class="ionic-selectable-value">
            </div><div class="ionic-selectable-icon"><div class="ionic-selectable-icon-inner"></div></div>
            <button type="button" class="ionic-selectable-cover"></button></div>
        </div>
    </ion-input>
</ng-template>
