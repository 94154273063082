import { Injectable } from '@angular/core';
import { City, Country, District, Region, Subregion, UserLocation } from '@app/api/models';
import {
    CitiesApiCache,
    CountriesApiCache,
    DistrictsApiCache,
    RegionsApiCache,
    SubregionsApiCache,
} from '@app/core/services/cache';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export type BriefLocation = {
    country?: Country;
    city?: City;
};

export type FullLocation = {
    country?: Country;
    region?: Region;
    subregion?: Subregion;
    city?: City;
    district?: District;
};

export interface LocationInterface {
    address?: string;
    city?: number;
    country?: number;
    id?: number;
    is_default?: boolean;
}

@Injectable()
export class LocationResolverService {
    // codebeat:disable[ARITY]
    constructor(
        private readonly countriesApi: CountriesApiCache,
        private readonly regionApi: RegionsApiCache,
        private readonly subregionApi: SubregionsApiCache,
        private readonly citiesApi: CitiesApiCache,
        private readonly districtApi: DistrictsApiCache,
    ) {}

    public resolveLocation(location: UserLocation): Observable<BriefLocation> {
        return forkJoin({
            country: !!location?.country ? this.countriesApi.getByEntityId(location.country) : of(void 0),
            city: !!location?.city ? this.citiesApi.getByEntityId(location.city) : of(void 0),
        });
    }

    public getTextLocation(
        location: LocationInterface,
        shortFormat: boolean = false,
    ): Observable<{ id: number; text: string }> {
        return this.getFullLocation({cityId: location.city, countryId: location.country}).pipe(
            map(res => {
                // @ts-ignore
                let locationElements = ['country', 'city'].map(key => res?.[key]?.name);
                if (!shortFormat) {
                    locationElements = locationElements.concat(location.address);
                }

                return {
                    id: location.id as number,
                    text: locationElements.filter(value => Boolean(value)).join(', '),
                    is_default: location?.is_default
                };
            }),
        );
    }

    public getFullLocation(location:  {
        countryId?: number;
        regionId?: number;
        subregionId?: number;
        cityId?: number;
        districtId?: number;
    }): Observable<FullLocation> {
        return forkJoin([
            location.countryId
                ? this.countriesApi.getByEntityId(location.countryId).pipe(catchError(() => of(void 0)))
                : of(void 0),
            location.regionId
                ? this.regionApi.getByEntityId(location.regionId).pipe(catchError(() => of(void 0)))
                : of(void 0),
            location.subregionId
                ? this.subregionApi.getByEntityId(location.subregionId).pipe(catchError(() => of(void 0)))
                : of(void 0),
            location.cityId
                ? this.citiesApi.getByEntityId(location.cityId).pipe(catchError(() => of(void 0)))
                : of(void 0),
            location.districtId
                ? this.districtApi.getByEntityId(location.districtId).pipe(catchError(() => of(void 0)))
                : of(void 0),
        ]).pipe(
            map(([country, region, subregion, city, district]) => ({
                country,
                region,
                subregion,
                city,
                district,
            })),
        );
    }
}
