import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {once} from '@app/core/decorators/once';

const gmt = 'GTM-NN7KSS9K';
const id = 3390206;

@Injectable({
    providedIn: 'root'
})
export class PixelService {

    constructor(@Inject(DOCUMENT) private readonly doc: any) {
    }

    @once
    public init(): void {
        const s = this.doc.createElement('script');
        s.type = 'text/javascript';
        s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${gmt}');`;
        const head = this.doc.getElementsByTagName('head')[0];
        const body = this.doc.getElementsByTagName('body')[0];
        head.appendChild(s);

        const nos = this.doc.createElement('noscript');
        // eslint-disable-next-line max-len
        nos.innerHTML = '<!-- Google Tag Manager (noscript) --><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NN7KSS9K" height="0" width="0" style="display:none;visibility:hidden"></iframe><!-- End Google Tag Manager (noscript) -->';
        body.prepend(nos);
        const nosBot = this.doc.createElement('noscript');
        // eslint-disable-next-line max-len
        nosBot.innerHTML = '<div><img src="https://top-fwz1.mail.ru/counter?id=3390206;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>';
        body.appendChild(nosBot);

        const bottomScript = this.doc.createElement('script');
        bottomScript.type = 'text/javascript';
        bottomScript.innerHTML = `var _tmr = window._tmr || (window._tmr = []);
            _tmr.push({id: "${id}", type: "pageView", start: (new Date()).getTime()});
            (function (d, w, id) {
                if (d.getElementById(id)) return;
                var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                ts.src = "https://top-fwz1.mail.ru/js/code.js";
                var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
            })(document, window, "tmr-code");`;

        body.appendChild(bottomScript);
    }
}
