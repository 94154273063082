import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MustBeAuthorizedGuard } from '@app/core/services/guards/must-be-authorized.guard';
import { OrderResetStateGuardService } from '@app/book/services/order-reset-state-guard.service';
import { BlogStateResetService } from '@app/blog/services/blog-state-reset.service';
import { CreativeBannersComponent } from '@app/shared/banners/creative-banners/creative-banners.component';
import { NavBranch, NavPath, PaymentSystem } from './core/constants/navigation.constants';
import { NotFoundPageComponent } from './shared/components';

const routes: Routes = [
    {
        path: NavPath.Main,
        loadChildren: () => import('./main/main.module').then(m => m.MainPageModule),
        pathMatch: 'full',
    },
    {
        path: NavPath.Auth,
        loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: NavPath.Profile,
        loadChildren: () => import('src/app/profile/profile.module').then(m => m.ProfilePageModule),
        canActivate: [MustBeAuthorizedGuard],
    },
    {
        path: NavPath.Professional,
        loadChildren: () => import('./professional/professional.module').then(m => m.ProfessionalModule),
    },
    {
        path: NavPath.Service,
        loadChildren: () => import('./service/service.module').then(m => m.ServicePageModule),
    },
    {
        path: NavPath.Message,
        loadChildren: () => import('./message/message.module').then(m => m.MessagePageModule),
        canActivate: [MustBeAuthorizedGuard],
    },
    {
        path: NavPath.Search,
        loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule),
    },
    {
        path: NavPath.Order,
        loadChildren: () => import('./book/book.module').then(m => m.BookPageModule),
        canActivate: [OrderResetStateGuardService],
    },
    {
        path: NavPath.Orders,
        loadChildren: () => import('./orders/my-orders.module').then(m => m.MyOrdersModule)
    },

    {
        path: NavPath.Reviews,
        loadChildren: () => import('./reviews/reviews.module').then(m => m.ReviewsModule),
    },
    {
        path: NavPath.Bookmarks,
        loadChildren: () => import('./bookmarks/bookmarks.module').then(m => m.BookmarksModule),
        canActivate: [MustBeAuthorizedGuard],
    },
    {
        path: NavPath.ProfessionalLandingRedirect,
        redirectTo: NavPath.ProfessionalLanding,
    },
    {
        path: NavPath.ProfessionalLanding,
        loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule),
    },
    {
        path: NavPath.ProfessionalSchedule,
        loadChildren: () => import('./professional_schedule/professional_schedule.module')
            .then(m => m.ProfessionalSchedulePageModule),
    },
    {
        path: NavPath.Eula,
        loadChildren: () => import('./eula/eula.module').then(m => m.EulaPageModule),
    },
    {
        path: NavPath.About,
        loadChildren: () => import('./about/about.module').then(m => m.AboutPageModule),
    },
    {
        path: NavPath.Policy,
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyPageModule),
    },
    {
        path: NavPath.Blog,
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogPageModule),
        canDeactivate: [BlogStateResetService]
    },
    {
        path: NavPath.ClientApplications,
        loadChildren: () => import('src/app/journal/journal.module').then(m => m.JournalModule),
    },
    {
        path: PaymentSystem.Root,
        loadChildren: () => import('src/app/payment-system/payment-system.module').then(m => m.PaymentSystemModule),
        canActivate: [MustBeAuthorizedGuard],
    },
    {
        path: NavPath.ServiceCatalog,
        loadChildren: () => import('src/app/service-catalog/service-catalog.module').then(m => m.ServiceCatalogModule),
    },
    {
        path: NavPath.Locations,
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationsPageModule),
    },
    {
        path: NavPath.Support,
        loadChildren: () => import('src/app/support/support.module').then(m => m.SupportModule),
    },
    {
        path: NavPath.CreativeBanners,
        component: CreativeBannersComponent,
    },
    {
        path: '**', component: NotFoundPageComponent
    },

    // redirecting from previous paths
    {
        path: 'saved-professionals',
        redirectTo: NavPath.Bookmarks,
    },
    {
        path: 'my-orders/outbox',
        redirectTo: `${NavPath.Orders}/${NavBranch.OrderList}`,
    },
    {
        path: 'creative-banners',
        redirectTo: NavPath.CreativeBanners,
    },
    {
        path: 'client-applications',
        redirectTo: NavPath.ClientApplications,
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            initialNavigation: 'enabled',
            paramsInheritanceStrategy: 'always',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
