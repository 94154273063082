<ion-row class="ion-wrap ion-justify-content-start">
    <ion-chip color="primary" *ngFor="let tag of tags">
        <ion-label>{{tag.name}}</ion-label>
        <ion-icon name="close" (click)="removeTag(tag)"></ion-icon>
    </ion-chip>
</ion-row>
<form (ngSubmit)="addTag()" [formGroup]="form">
    <ion-item>
        <ion-input
            formControlName="tagName"
            [placeholder]="'tag-editor.enter-tag-name' | translate"
            [maxlength]="maxTagLength"
        ></ion-input>
    </ion-item>
</form>
