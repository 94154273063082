<ng-container *appIfSpinner="servicePhotos">
    <ion-list class="photo-slider" *ngIf="servicePhotos.length > 0">
        <ion-list-header>
            <ion-label>
                <div class="menu-item-title">{{ 'service-photos.title' | translate }}</div>
            </ion-label>
        </ion-list-header>
        <app-image-carousel [photos]="servicePhotos"></app-image-carousel>
    </ion-list>
</ng-container>
