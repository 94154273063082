import { Injectable } from '@angular/core';
import { BlogPage } from '@app/api/models/blog-page';
import { Observable, of } from 'rxjs';
import { BlogService } from '@app/api/services/blog.service';
import { map, tap } from 'rxjs/operators';

const ordering = '-publication_datetime';

@Injectable({
    providedIn: 'root'
})
export class PostCacheService {

    public posts: Map<string, BlogPage> = new Map<string, BlogPage>();
    private initialized: boolean = false;
    private hasNext: boolean;

    constructor(private readonly api: BlogService) {
    }

    public get(slug: string): Observable<BlogPage> {
        return this.posts.has(slug)
            ? of(this.posts.get(slug))
            : this.api.blogPagesRead(slug).pipe(tap(p => this.add(p)));
    }

    public getAll(pageSize: number): Observable<BlogPage[]> {
        return this.initialized
            ? of(Array.from(this.posts.values()) as BlogPage[])
            : this.request({ pageSize, ordering });
    }

    public canGetNext(): boolean {
        return this.hasNext;
    }

    public getNext(pageSize: number, page: number): Observable<BlogPage[]> {
        return this.request({ page, pageSize, ordering });
    }

    public clear(): void {
        this.posts.clear();
    }

    private request(params: BlogService.BlogPagesListParams): Observable<BlogPage[]> {
        return this.api.blogPagesList(params).pipe(
            tap(r => this.hasNext = !!r.next),
            map(r => r.results),
            tap(pages => pages.forEach(p => this.add(p))),
            tap(() => this.initialized = true)
        );
    }

    private add(page: BlogPage): void {
        this.posts.set(page.slug, page);
    }
}
