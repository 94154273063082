<div *ngIf="showBanner" class="search-banner">
    <div class="search-banner__img-wrap">
        <img src="assets/images/banner/order-creator.svg" class="search-banner__img"/>
    </div>
    <div class="search-banner__content">
        <div class="search-banner__content__text">{{'search-banner.message' | translate}}</div>
        <ion-button class="ion-no-margin search-banner__content__success" color="success" (click)="navigation()">
           {{'search-banner.btn' | translate}}
        </ion-button>
    </div>
    <div *ngIf="mode === 'main'" class="search-banner__close-wrap">
        <ion-icon name="close-outline" class="search-banner__close" (click)="close()"></ion-icon>
    </div>
</div>
