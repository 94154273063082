<div *ngIf="client" class="item">
    <app-avatar [class]="{'gl-clickable': client?.professionals}"
                [isLargeMode]="false"
                [avatar]="client?.avatar"
                [user]="client"
                (click)="navToProfile(client?.professionals)"></app-avatar>
    <div class="client-info">
        <ng-container
            *ngTemplateOutlet="client?.professionals ? profileLink : noLink;
            context: {profId: client?.professionals, name: client?.last_name + ' ' + client?.first_name}">
        </ng-container>
        <app-presence-indicator [isOn]="client?.is_online"
                                [lastSeen]="client?.last_seen"></app-presence-indicator>
        <div class="created-by">{{ 'orders-card-description.create.' + (order?.is_another_person ? 'master' : 'client') | translate }}</div>
    </div>
</div>
<ng-template #profileLink let-profId="profId" let-name="name">
    <a class="name" routerLink="{{ '/professional/' + profId + '/profile' }}">{{name}}</a>
</ng-template>
<ng-template #noLink let-name="name">
    <span class="name">{{name}}</span>
</ng-template>
