import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent {
    @Input() public value: string;
    @Input() public ionChange: (e: Event) => any;
    public onChange = (e: Event): any => this.ionChange(e);
}
