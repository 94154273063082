<ion-content>
    <div class="content-404">
        <h1 class="header-404">{{ 'global.page-not-found.title' | translate }}</h1>
        <div class="message-404">
            <span>{{ 'global.page-not-found.message' | translate }}</span>
            <a href="/">{{ 'global.page-not-found.to-main' | translate }}</a>
        </div>
        <div class="image-404">
            <ion-img alt="d8base" src="assets/images/404.svg" class=""></ion-img>
        </div>
    </div>
    <app-footer></app-footer>
</ion-content>

