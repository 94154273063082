<ion-header>
    <ion-toolbar>
        <ion-item lines="none">
            <ion-icon name="globe-outline" slot="start"></ion-icon>
            <ion-label>
                <app-location-viewer type="short" [location]="defaultLocation$ | async"></app-location-viewer>
            </ion-label>
        </ion-item>
    </ion-toolbar>
</ion-header>
<ion-content *ngIf="userSettingsService.userSettings$ | async as userSettings">
    <ion-list>
        <ion-item *ngIf="langList?.length > 1">
            <ion-label> {{ 'flag-menu.language' | translate }}: </ion-label>
            <ionic-selectable
                itemTextField="view"
                itemValueField="code"
                [items]="langList"
                [formControl]="languageControl"
                [searchPlaceholder]="'global.titles.search' | translate"
                [clearButtonText]="'global.clear' | translate"
                [closeButtonText]="'global.cancel' | translate"
                [searchFailText]="'ion-selectable.search-fail-text' | translate"
            ></ionic-selectable>
<!--            <ion-select [formControl]="languageControl" style="height: 100%">-->
<!--                <ion-select-option *ngFor="let lang of userSettingsService.langList" [value]="lang">-->
<!--                    {{ 'language.' + lang | translate }}-->
<!--                </ion-select-option>-->
<!--            </ion-select>-->
        </ion-item>
        <form [formGroup]="form">
            <ion-item *appIfSpinner="rateList$ | ifSpinnerErrorState | async as rateList">
                <ion-label> {{ 'flag-menu.currency' | translate }}: </ion-label>
                <ion-select [formControlName]="formFields.currency">
                    <ion-select-option *ngFor="let rate of rateList" [value]="rate.currency">
                        {{ rate.currency }} ({{ rate.sign }})
                    </ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-label> {{ 'flag-menu.units' | translate }}: </ion-label>
                <ion-select [formControlName]="formFields.units">
                    <ion-select-option *ngFor="let unit of userSettingsService.unitsList" [value]="unit">
                        {{ 'units.' + unit | translate | titlecase }}
                    </ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-label> {{ 'flag-menu.first-day-of-week' | translate }}: </ion-label>
                <ion-select [formControlName]="formFields.is_monday_start_of_a_week">
                    <ion-select-option *ngFor="let day of userSettingsService.firstDayOfWeekList" [value]="day">
                        {{ (day ? 'flag-menu.monday' : 'flag-menu.sunday') | translate }}
                    </ion-select-option>
                </ion-select>
            </ion-item>
        </form>
    </ion-list>
</ion-content>
