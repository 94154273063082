import { Component } from '@angular/core';
import {Subject} from 'rxjs';
import {AuthenticationService} from '@app/core/services';
import {NavBranch, NavPath} from 'src/app/core/constants/navigation.constants';
import { environment } from 'src/environments/environment';
import { configuration } from '@conf/configuration';

@Component({
  selector: 'app-access-to-jobs-board',
  templateUrl: './access-to-jobs-board.component.html',
  styleUrls: ['./access-to-jobs-board.component.scss'],
})
export class AccessToJobsBoardComponent {

    public destroy = new Subject();
    public readonly NavPath = NavPath;
    public readonly NavBranch = NavBranch;
    public readonly environment = environment;
    public readonly configuration = configuration;

    constructor(public readonly authenticator: AuthenticationService) {}

    public onDestroy(): void {
        this.destroy.next();
    }
}
