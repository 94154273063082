import { CalendarComponentState } from '@app/shared/components/calendar-component/state/calendar-component-state';
import { CalendarInterval } from '@app/shared/components/calendar-component/calendar-interval';
import { CalendarUnit } from '@app/shared/components/calendar-component/calendar-unit';
import { Subject } from 'rxjs';
import { DatePicked } from '@app/shared/components/calendar-component/state/date-picked';
import { CalendarViewState } from '@app/shared/components/calendar-component/state/calendar-view-state';
import { CalendarService } from '@app/shared/components/calendar-component/calendar.service';

export class FixedState implements CalendarComponentState{
    public picked = new Subject<DatePicked>();

    constructor(private calendar: CalendarService) {
    }

    public getTimetable(): CalendarInterval[] {
        return this.calendar.timetable;
    }

    public setTimetable(timetable: CalendarInterval[]): void {
        this.calendar.timetable = timetable;
    }

    public setDatetime(
        unit: CalendarUnit | null,
        flexibleServiceLen: number): void {
        this.calendar.timetable.forEach(data => data.units.forEach(u => {
            u.selected = u.datetime.getTime() === unit?.datetime.getTime();
        }));
        this.picked.next( {
            state: CalendarViewState.Fixed,
            start: unit?.datetime
        });
    }

    public deselect(serviceLen: number): void {
        this.calendar.timetable.forEach(data => data.units.forEach(u => {
            u.selected = false;
            u.interval = undefined;
        }));
    }
}
