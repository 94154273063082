import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaConvert'
})
export class CommaConvertPipe implements PipeTransform {
    public transform(value: string): string {
        return value ? (`${value  }`).replace(',', '.'): '';
    }
}
