<!--todo: temporary-->
<div *ngIf="typeofPriceIsNotNumber();then stringContent else numberContent">here is ignored</div>
<ng-template #stringContent>
    <span *ngIf="price?.is_price_fixed && price?.price" class="price">
        {{ price?.price | translate }}
</span>
</ng-template>
<ng-template #numberContent>
      <span *ngIf="price?.is_price_fixed && price?.price" class="price">
    {{ price?.price | currency: price.price_currency:'symbol-narrow':'1.0-2':locale }}
</span>
</ng-template>
<span *ngIf="!price?.is_price_fixed && price?.start_price && price?.end_price" class="price">
    {{ price?.start_price | currency: price.start_price_currency: configuration.price.symbol.start:'1.0-2':locale }}&nbsp;—
    {{ price?.end_price | currency: price.end_price_currency: configuration.price.symbol.end:'1.0-2':locale }}
</span>
