import { Injectable } from '@angular/core';
import * as LoaderActions from '@app/store/loader/loader.actions';
import { Observable, Subject } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    public readonly disable$: Observable<boolean>;
    public readonly waiting$: Observable<boolean>;
    private readonly waitingSubj: Subject<boolean> = new Subject<boolean>();
    private readonly disableSubj: Subject<boolean> = new Subject<boolean>();
    constructor() {
        this.disable$ = this.disableSubj.asObservable();
        this.waiting$ = this.waitingSubj.asObservable();
    }

    public setState(load: boolean, hasSpinner?: boolean): void {
        this.disableSubj.next(!!load);
        this.waitingSubj.next(!load);

        if (hasSpinner) {
            this.spinner(load);
        }
    };

    @Dispatch()
    private spinner(hasLoad: boolean): LoaderActions.ShowLoader | LoaderActions.HideLoader {
        return hasLoad ?
            new LoaderActions.ShowLoader('service-publish') :
            new LoaderActions.HideLoader('service-publish');
    }
}
