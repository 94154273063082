import { Component, Input } from '@angular/core';
import { ContactUnion } from '@app/core/models/contact-union';
import { NavBranch, NavPath, NavQueryParams } from '@app/core/constants/navigation.constants';
import { contactsIcon } from '@app/core/constants/image.constants';

@Component({
    selector: 'app-contacts-edit',
    templateUrl: './contacts-edit.component.html',
    styleUrls: ['./contacts-edit.component.scss'],
})
export class ContactsEditComponent {
    @Input() public addNewContactUrl: string = `/${NavPath.Profile}/${NavBranch.ContactAdd}/`;
    @Input() public editDefaultContactUrl: string = `/${NavPath.Profile}/${NavBranch.ContactAddDefault}/`;
    @Input() public editContactUrl: string = `/${NavPath.Profile}/${NavBranch.ContactEdit}/`;
    @Input() public interactable: boolean = false;
    @Input() public contacts: ContactUnion[];
    @Input() public goBack: boolean = false;

    private readonly queryParams = { [NavQueryParams.goBack]: true };

    public getUrl(contact: ContactUnion): string {
        return contact.id ? this.editContactUrl + contact.id : this.editDefaultContactUrl + contact.contact;
    }

    public getParams(): object {
        return this.goBack ? this.queryParams : {};
    }

    public getContactIcon(contact_code: string): string {
        const contact = contact_code.toLowerCase().replace('-','');

        return contact in contactsIcon? contactsIcon[contact].src:
            'assets/images/social/person-outline.svg';
    }
}
