import { GenderStrT } from '@app/core/declination-cities/models/genders.types';
import {
    DeclentionStrT,
    DeclensionRuleT,
    DeclentionModsT,
    DeclensionRuleSetT
} from '@app/core/declination-cities/models/declension.types';
import { GendersEnum } from '@app/core/declination-cities/models/genders.enum';
import { declinationCases } from '@app/core/declination-cities/models/declination.constants';
import { DeclinationDefault } from '@app/core/declination-cities/strategies/city-case.strategy';


export function constantizeGenderInRules(rules: DeclensionRuleSetT): void {
    if (Array.isArray(rules.exceptions)) {
        rules.exceptions.forEach((rule) => rule.gender as GendersEnum);
    };
    if (Array.isArray(rules.suffixes)) {
        rules.suffixes.forEach((rule) => rule.gender as GendersEnum);
    };
}

export function declineByRules(
    str: string,
    declensionStr: DeclentionStrT,
    genderStr: GenderStrT | null,
    ruleSet: DeclensionRuleSetT
): string {
    const parts = str.split('-');
    const result = [];

    for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const isFirstWord = i === 0 && parts.length > 1;

        const rule = findRule(part, genderStr, ruleSet, {
            firstWord: isFirstWord,
        });

        if (rule) {
            result.push(applyRule(rule, part, declensionStr));
        } else {
            result.push(part);
        };
    };

    return result.join('-');
}

export function findRule(
    str: string,
    gender: GenderStrT | null,
    ruleSet: DeclensionRuleSetT,
    tags: { firstWord?: boolean } = {}
): DeclensionRuleT | null {
    if (!str) { return null; };
    const strLower = str.toLowerCase();

    const tagList = [] as string[];
    Object.keys(tags).forEach((key) => {
        if ((tags as any)[key]) {
            tagList.push(key);
        }
    });

    if (ruleSet.exceptions) {
        const rule = findExactRule(ruleSet.exceptions, gender, (some) => some === strLower, tagList);
        if (rule) { return rule; }
    }

    return ruleSet.suffixes
        ? findExactRule(ruleSet.suffixes, gender, (some) => citynameEndsWith(strLower, some), tagList)
        : null;
}

export function applyRule(
    rule: DeclensionRuleT | { mods: DeclentionModsT },
    word: string,
    declension?: DeclentionStrT | null
): string {
    const declinationCase = declinationCases.find(el => el.name === declension);
    // @ts-ignore TODO: fix this, can be undefined
    const strategy = declinationCase.strategy;
    const mod: DeclinationDefault = new strategy(word, rule.mods);

    return mod.decline();
}

export function applyMod(str: string, mod: string): string {
    for (const chr of mod) {
        switch (chr) {
            case '.':
                break;
            case '-':
                str = str.substr(0, str.length - 1);
                break;
            default:
                str += chr;
        };
    };

    return str;
}

export function citynameEndsWith(str: string, search: string): boolean {
    return str.substring(str.length - search.length, str.length) === search;
}

export function citynameStartsWith(str: string, search: string, pos?: number): boolean {
    return str.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
}

export function getModByIdx(mods: DeclentionModsT, i: number): string {
    if (mods && mods.length >= i) { return mods[i]; };

    return '.';
}

function findExactRule(
    rules: DeclensionRuleT[],
    gender: GenderStrT | null,
    matchFn: (some: string) => boolean,
    tags: string[] = []
): DeclensionRuleT | null {
    for (const rule of rules) {
        // rule with tag should be skipped if tag not listed in args
        if (rule.tags) {
            if (!rule.tags.find((t) => tags.indexOf(t) !== -1)) { continue; }
        };
        // rule must have same gender or be `neuter`
        if (rule.gender !== GendersEnum.NEUTER && gender !== rule.gender) {
            continue;
        };
        if (rule.test) {
            for (const test of rule.test) {
                if (matchFn(test)) { return rule; }
            };
        };
    };

    return null;
}
