import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';

import { GlobalErrorHandler } from './global-error-handler.service';
import { HttpErrorInterceptor } from './http-error-interceptor.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
    ],
})
export class ErrorHandlingModule {}
