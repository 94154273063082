<ion-thumbnail class="avatar" [style]="border" [class.avatar-large]="isLargeMode">
    <ion-img [src]="src" *ngIf="src, else defaultAvatar"></ion-img>
    <ng-template #defaultAvatar>
        <div class="default-avatar" [style]="background">
            <span class="default-avatar__initials" [class.initials-large]="isLargeMode">
                {{ initials }}
            </span>
        </div>
    </ng-template>
</ion-thumbnail>
