import { merge } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { AfterViewInit, ContentChildren, Directive, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { NgDestroyService } from '@app/core/services';
import { PhotoType } from '@app/core/services/image-load/image-api-service-factory.service';
import { PhotoPopoverComponent } from '@app/shared/components';
import Photo from '@app/shared/components/image-carousel/photo.interface';
import { ModalController } from '@ionic/angular';

import { IonImageViewDirective } from './ion-image-view.directive';

@Directive({
    selector: '[appViewImageSlider]',
    providers: [NgDestroyService],
})
export class IonImageViewSliderDirective implements AfterViewInit {
    @ContentChildren(IonImageViewDirective, { descendants: true })
    public readonly imageViews!: QueryList<IonImageViewDirective>;
    @Input() public canDelete: boolean;
    @Input() public favoritable: boolean = false;
    @Input() public photoType: PhotoType;
    @Output() public delete = new EventEmitter<number>();

    constructor(
        private readonly modalController: ModalController,
        private readonly ngDestroy$: NgDestroyService,
    ) { }

    public ngAfterViewInit(): void {
        this.subscribeImagesClicks();
    }

    private async createPopover(photos: Photo[], photoIndex: number): Promise<void> {
        const pop = await this.modalController.create({
            component: PhotoPopoverComponent,
            cssClass: 'modal-fullscreen',
            componentProps: {
                photos,
                photoIndex,
                editable: this.canDelete,
                photoType: this.photoType,
                favoritable: this.favoritable
            },
        });

        pop.onDidDismiss().then(data => {
            if (data.data?.delete && data.data?.photoIndex !== undefined) {
                this.delete.emit(data.data.photoIndex);
            }
        });

        return await pop.present();
    }

    private subscribeImagesClicks(): void {
        this.imageViews.changes
            .pipe(
                startWith(0),
                switchMap(() =>
                    merge(...this.imageViews.map((imageView, index) => imageView.imageClick.pipe(map(() => index)))),
                ),
                takeUntil(this.ngDestroy$),
            )
            .subscribe(photoIndex => {
                const photos = this.imageViews.map(imageView => imageView.photo);
                this.createPopover(
                    photos,
                    photoIndex,
                );
            });
    }
}
