/* eslint-disable max-classes-per-file */

export enum LoaderActions {
    Show = '[AppShell] Show loader',
    Hide = '[AppShell] Hide loader',
}

export class ShowLoader {
    public static readonly type = LoaderActions.Show;

    constructor(public loaderKeyToShow: string) {}
}

export class HideLoader {
    public static readonly type = LoaderActions.Hide;

    constructor(public loaderKeyToHide: string) {}
}
