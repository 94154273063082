<ion-modal #modal [isOpen]="true" (ionModalDidDismiss)="onDestroy()">
    <ng-template>
        <ion-toolbar>
            <ion-buttons slot="end">
                <ion-button color="light" (click)="modal.dismiss()">
                    <ion-icon name="copy-outline" color="dark" size="large" name="close-outline"></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
        <div class="container">

            <div class="img-container">
                <ion-img src="/assets/images/logo.svg"></ion-img>
            </div>
            <div class="content">
                <h4>
                    {{ 'client-applications.order-list.access-window.header' | translate: { app: configuration.app_title | translate } }}
                </h4>
                <p>{{ 'client-applications.order-list.access-window.desc' | translate: { app: configuration.app_title | translate } }}</p>
            </div>
        </div>
        <div class="button-container">
            <ion-button
                *ngIf="(authenticator.isAuthenticated$ | async) === false"
                [routerLink]="[NavPath.Auth, NavPath.Login]"
                [queryParams]="{ redirectTo: [NavPath.ClientApplications, NavBranch.OrderSearch].join('/') }"
                (click)="modal.dismiss()"
            >{{ 'client-applications.order-list.access-window.login-btn' | translate }}</ion-button>
            <ion-button color="light"
                [routerLink]="[NavPath.Service, NavBranch.Publish]"
                (click)="modal.dismiss()"
            >{{ 'client-applications.order-list.access-window.publish-btn' | translate }}</ion-button>
        </div>
    </ng-template>
</ion-modal>
