<ion-grid>
    <ion-row class="ion-padding-horizontal">
        <h4>{{ 'place-selector-title' | translate }}</h4>
    </ion-row>
    <ion-row>
        <ion-col>
            <app-country-selector [formControl]="country"></app-country-selector>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <app-city-selector [formControl]="city" [country]="country.value"></app-city-selector>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-button color="primary" size="block" (click)="submit()">
                {{ 'global.save' | translate }}
            </ion-button>
        </ion-col>
        <ion-col>
            <ion-button color="light" size="block" (click)="dismiss()">
                {{ 'global.cancel' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>
