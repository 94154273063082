<ion-item *ngIf="order?.start_datetime" lines="none">
    <ion-icon slot="start" name="calendar-clear-outline" class="ion-align-self-start"></ion-icon>
    <ion-label>
        <b>
            {{ order.start_datetime | transDate: 'fullDate' }}
            {{ 'global.at' | translate }}
            {{ order.start_datetime | date: 'shortTime' }}
        </b>
    </ion-label>
</ion-item>

<ion-item *ngIf="order?.service | serviceById$ | async as service" lines="none">
    <ion-icon slot="start" name="location-outline" class="ion-align-self-start"></ion-icon>
    <p>
        <b>{{ 'service-location.' + service.service_type | translate }}</b>
    </p>
</ion-item>

<ion-item *ngIf="order?.first_name" lines="none">
    <ion-icon slot="start" name="person-circle-outline" class="ion-align-self-start"></ion-icon>
    <ion-label class="ion-text-wrap">
        <h2>
            <b>{{ 'order.client-details' | translate }}</b>
        </h2>
        <div>
            {{ 'order.name' | translate }}:
            <b>
                {{ order.first_name }}
                {{ order.last_name }}
            </b>
        </div>
        <div *ngIf="order?.phone">
            {{ 'order.phone' | translate }}:
            <b>
                {{ order.phone }}
            </b>
        </div>
        <div *ngIf="order?.note">
            {{ 'order.comment-header' | translate }}:
            <b>
                {{ order.note }}
            </b>
        </div>
    </ion-label>
</ion-item>
