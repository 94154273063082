import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class HelperService {

    constructor(private readonly tr: TranslateService) {
    }

    public getStrMonthFullDate(date: Date): string {
        const monthStr: string = this.tr.instant(`declination.months.${date.getMonth()}`);

        return `${date.getDate()} ${monthStr} ${date.getFullYear()}`;
    }
}
