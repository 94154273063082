import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavBranch, NavPath } from '@app/core/constants/navigation.constants';
import { map } from 'rxjs/operators';
import { UserSettingsService } from '@app/core/services/facades';
import { Router } from '@angular/router';

@Component({
    selector: 'app-professional-promo',
    templateUrl: './professional-promo.component.html',
    styleUrls: ['./professional-promo.component.scss'],
})
export class ProfessionalPromoComponent implements OnInit  {
    @Output()
    public hideButtonClicked = new EventEmitter<void>();
    public closeButtonClass = '';
    constructor(private readonly userSettings: UserSettingsService, private readonly router: Router) {}
    public get servicePublishUrl(): string[] {
        return [NavPath.Service, NavBranch.Publish];
    }
    public ngOnInit(): void {
        this.userSettings.settings$.pipe(
            map(s => s.language === 'ar')
        ).subscribe(isInvert => this.closeButtonClass = isInvert ? '-arabic': '');
    }

    public navigation(): void {
        this.router.navigate([NavPath.Service, NavBranch.Publish]).then();
    }
}
