import { DeclensionEnum } from '@app/core/declination-cities/models/declensions.enum';
import {
    NominativeCityname,
    GenitiveCityname,
    DativeCityname,
    AccusativeCityname,
    InstrumentalCityname,
    PrepositionalCityname
} from '@app/core/declination-cities/strategies/city-case.strategy';


export const declinationCases = [
    {
        name: DeclensionEnum.NOMINATIVE,
        strategy: NominativeCityname
    },
    {
        name: DeclensionEnum.GENITIVE,
        strategy: GenitiveCityname
    },
    {
        name: DeclensionEnum.DATIVE,
        strategy: DativeCityname
    },
    {
        name: DeclensionEnum.ACCUSATIVE,
        strategy: AccusativeCityname
    },
    {
        name: DeclensionEnum.INSTRUMENTAL,
        strategy: InstrumentalCityname
    },
    {
        name: DeclensionEnum.PREPOSITIONAL,
        strategy: PrepositionalCityname
    },
];