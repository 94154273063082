import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonDatetime, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserSettingsService } from '@app/core/services/facades';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export enum DateType {
    start = '00:00',
    end = '23:59'
}

@Component({
    selector: 'app-custom-datetime',
    templateUrl: './custom-datetime.component.html',
    styleUrls: ['./custom-datetime.component.scss'],
})
export class CustomDatetimeComponent implements OnInit {
    @Input() public dateType!: 'start' | 'end';

    @Input()
    public set dateTimeConfig(config: Partial<IonDatetime>) {
        this.config = config;
        if (config.value) {
            [this.dateValue, this.timeValue] = this.prepareDate(config.value as string);
        }
    }

    @Input() public title!: string;
    @Input() public timeLabel!: string;
    @ViewChild('date') public readonly dateRef!: IonDatetime;

    public config?: Partial<IonDatetime>;
    public dateValue?: string;
    public timeValue?: string;
    public firstDayOfWeek$: Observable<number>;

    constructor(
        private readonly modalController: ModalController,
        private readonly userSettingsService: UserSettingsService,
        public readonly translator: TranslateService,
    ) {
        this.firstDayOfWeek$ = this.userSettingsService.userSettings$
            .pipe(map((settings) => + settings.is_monday_start_of_a_week));
    }

    public ngOnInit(): void {
        if (!this.timeValue) {
            this.setDefaultTime();
        }
    }

    public async done(): Promise<void> {
        const data: string = this.prepareDate(this.dateValue, this.timeValue).join('T');
        await this.modalController.dismiss(data, 'done');
    }

    public async close(): Promise<void> {
        await this.modalController.dismiss();
    }

    public clear(): void {
        this.dateRef.reset().then(() => this.dateValue = void 0);
        this.setDefaultTime();
    }

    private prepareDate(date?: string, time?: string): string[] {
        if (!date) {
            return [];
        }
        // eslint-disable-next-line prefer-const
        let [dateValue, timeValue] = date.split('T');
        if (time) {
            timeValue = time.split('T').pop() as string;
        }

        return [dateValue, timeValue];
    }

    private setDefaultTime(): void {
        this.timeValue = this.dateType ? DateType[this.dateType] : new Date().toISOString();
    }
}