import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/core/operators/take-until-destroyed';

@DecorateUntilDestroy()
@Directive({
    selector: '[appDebounce]',
})
export class DebounceDirective implements OnInit {
    @Input() public appDebounce: number = 150;
    @Output() public debounceClick = new EventEmitter();
    private readonly clicks = new Subject();

    public ngOnInit(): void {
        this.clicks
            .pipe(
                throttleTime(this.appDebounce),
                takeUntilDestroyed(this),
            )
            .subscribe(e => this.debounceClick.emit(e));
    }

    @HostListener('click', ['$event'])
    public clickEvent(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }
}
