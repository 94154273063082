import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BlogPage } from '@app/blog/blog.page';
import { PostCacheService } from '@app/blog/services/post-cache.service';

@Injectable({
    providedIn: 'root'
})
export class BlogStateResetService implements CanDeactivate<BlogPage> {

    constructor(private readonly state: PostCacheService) {}

    public canDeactivate(): boolean {
        this.state.clear();

        return true;
    }
}
