import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appPlaceSelector]'
})
export class PlaceSelectorDirective {
    public locationTextList: { id: number; text: string; is_default?: boolean }[] = [];
    @Input() public initLocation: Subject<number>;
    @Input() public title: string;
    @Input() public requiredAddress = false;
    @Output() public valueIsSet = new EventEmitter<number>();

    public setLocationValue($event: number): void {
        this.valueIsSet.emit($event);
    }

    protected init(): void {
        //
    }

    protected open(type: 'online' | 'client' | 'professional'): void {
        //
    }
}
