<ion-col size="12" class="ion-no-padding ion-no-margin">
    <ion-row class="ion-justify-content-between">
        <div class="content">
            <app-avatar [class]="{'gl-clickable': !previewMode}"
                        [avatar]="professional?.user?.avatar_thumbnail"
                        [user]="professional?.user"
                        (click)="navToProfile()"></app-avatar>
            <div class="info">
                <div>
                    <div class="title capitalize-first-letter">
                        <a *ngIf="!previewMode; else noLink" class="gl-clickable" (click)="navToProfile()">{{ professionalName }}</a>
                        <app-presence-indicator [isOn]="professional?.user?.is_online"
                                                [lastSeen]="professional?.user?.last_seen"></app-presence-indicator>
                    </div>
                    <div class="subtitle">
                        <span class="capitalize-first-letter">{{ professional?.name }}</span>
                        <ng-container *ngIf="defaultCityId">
                            <span>&nbsp;&middot;&nbsp;</span>
                            <span class="city">{{ (defaultCityId | cityById$ | async)?.name }}</span>
                        </ng-container>
<!--                        <ng-container *ngIf="!defaultCityId && previewMode">-->
<!--                            <span> address not specified</span>-->
<!--                        </ng-container>-->
                    </div>
                </div>
                <div *ngIf="professional?.rating" class="rating">
                    <app-rating [rating]="professional.rating"></app-rating>
                    <div class="rating__reviews-link">
                        <a (click)="navToReviewsUrl()">
                            {{ professional?.reviews_count | declension : 'reviews' }}
                        </a>
                    </div>
                </div>
            </div>
            <app-saved-professional-toggle *ngIf="showSavedProfessionalToggle" [professionalId]="professional?.id" class="like"></app-saved-professional-toggle>
        </div>
    </ion-row>
    <div *ngIf="showProfessionalDescription && professional?.description" class="ion-margin-top">
        <ion-text class="desc-ws" color="dark">
            <app-shorten [max]="160" [text]="professional?.description"></app-shorten>
        </ion-text>
    </div>
<!--    <div *ngIf="!professional?.description && previewMode">-->
<!--        <ion-text class="desc-ws"-->
<!--        >-->
<!--            description not provided-->
<!--        </ion-text>-->
<!--    </div>-->
</ion-col>

<ng-template #noLink>
    <div>{{ professionalName }}</div>
</ng-template>
