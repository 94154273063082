import { Directive, HostListener, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import Photo from '@app/shared/components/image-carousel/photo.interface';

@Directive({
    selector: '[appViewOnClick]',
})
export class IonImageViewDirective {
    @Input() public readonly fullSizeSrc!: string;
    @Input() public readonly src!: string;
    @Input() public readonly photo!: Photo;

    private readonly clickSubject = new Subject<void>();

    @HostListener('click', ['$event'])
    private viewImage(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.clickSubject.next();
    }

    public get imageClick(): Observable<void> {
        return this.clickSubject.asObservable();
    }
}
