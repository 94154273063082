import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { NgDestroyService } from '@app/core/services/ng-destroy.service';
import { once } from '@app/core/decorators/once';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, pairwise, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {

    public readonly previousUrl: Observable<string | null>; // full url with params
    public readonly currentUrl: Observable<string | null>;
    private readonly previousUrl$ = new BehaviorSubject(null);
    private readonly currentUrl$ = new BehaviorSubject(null);

    constructor(private readonly router: Router, private readonly destroy$: NgDestroyService) {
        this.previousUrl = this.previousUrl$.asObservable();
        this.currentUrl = this.currentUrl$.asObservable();
    }

    @once
    public init(): void {
        this.router.events.pipe(
            takeUntil(this.destroy$),
            filter(evt => evt instanceof RoutesRecognized),
            pairwise(),
        ).subscribe(
            events => {
                this.previousUrl$.next((events as [RoutesRecognized,RoutesRecognized])[0]?.urlAfterRedirects);
                this.currentUrl$.next((events as [RoutesRecognized,RoutesRecognized])[1]?.urlAfterRedirects);
            },
        );
    }
}
