import { Component, Input } from '@angular/core';
import { Search, ServiceList } from '@app/api/models';
import { NavQueryParams } from '@app/core/constants/navigation.constants';
import {
    getProfessionalServicesUrl, getServiceOrderUrl, getUserChatUrl,
} from '@app/core/functions/navigation.functions';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent {

    @Input() public data!: Search;

    public queryParams = {};

    constructor() {
        this.queryParams =  { [NavQueryParams.goBack]: true, prof: this.data?.professional?.id };
    }

    public get professionalServicesUrl(): string {
        return getProfessionalServicesUrl(this.data.professional.id);
    }

    public get professionalChatUrl(): string {
        return getUserChatUrl(this.data.professional.user.id);
    }

    public get serviceOrderUrl(): string {
        return getServiceOrderUrl(this.data.services[0].id);
    }

    public getServiceList(): ServiceList[] {
        return this.data.services.slice(0, 3);
    }

    public getServiceCount(): number {
        return this.data.services.length;
    }

    public get hasMoreServices(): boolean {
        return this.getServiceCount() > this.getServiceList().length;
    }
}
