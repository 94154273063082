import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-contacts-window',
  templateUrl: './contacts-window.component.html',
  styleUrls: ['./contacts-window.component.scss'],
})
export class ContactsWindowComponent {

    @Input() public readonly contacts: [];

    constructor(private readonly popoverController: PopoverController) { }

    public close(): void {
        this.popoverController.dismiss(null, 'cancel');
    }

}
