import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@app/core/services';
import {
    ImageApiServiceFactoryService,
    PhotoModelType,
    PhotoType
} from '@app/core/services/image-load/image-api-service-factory.service';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    private readonly cachedImages = new Map();

    constructor(
        private readonly toast: ToastService,
        private readonly imageUpdater: ImageApiServiceFactoryService
    ) {
    }

    public setFav(photo: PhotoModelType, photoType: PhotoType, isFavorite: boolean): Observable<PhotoModelType | null> {
        return this.imageUpdater.update(
            { ...photo, is_favorite: isFavorite },
            photoType
        ).pipe(
            catchError(error => {
                this.toast.showMessage(error.message);

                return of(null);
            }),
        );
    }

    private checkAndCacheImage(url: string, blob: ArrayBuffer): void {
        if (!this.cachedImages.has(url)) {
            this.cachedImages.set(url, blob);
        }
    }
}
