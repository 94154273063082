// @ts-nocheck TODO: fix the whole class
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProfessionalCalendar, Service } from '@app/api/models';
import { ScheduleService } from '@app/api/services';
import { addDays, getLocalDateString } from '@app/core/functions/datetime.functions';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HelperService } from '@app/core/services/helper-service';
import { NgDestroyService } from '@app/core/services';
import { ServicesApiCache } from '@app/core/services/cache';
import { configuration } from '@conf/configuration';
import { CalendarComponentState } from '@app/shared/components/calendar-component/state/calendar-component-state';
import { UnitStatus } from '@app/shared/components/calendar-component/state/unit-status';
import { FlexibleState } from '@app/shared/components/calendar-component/state/flexible-state';
import { FixedState } from '@app/shared/components/calendar-component/state/fixed-state';
import { CalendarViewState } from '@app/shared/components/calendar-component/state/calendar-view-state';
import { CalendarUnit } from './calendar-unit';
import { CalendarService } from './calendar.service';

@Component({
    selector: 'app-calendar-component',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss'],
    providers: [
        CalendarService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CalendarViewComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarViewComponent extends UnitStatus implements OnInit {
    public loading = false;
    public readonly currentlyViewedDate$ = new BehaviorSubject<Date>(null);
    public state: CalendarComponentState;
    @Input() public flexibleServiceLen: number; //minutes
    @Output() public readonly timePicked = new EventEmitter<Date>();
    @Output() private readonly pickDateEvent = new EventEmitter<void>();
    @Output() private readonly intervalPicked = new EventEmitter<{ start: Date; end: Date }>();
    @Input() private readonly professionalId: number;
    @Input() private readonly serviceId: number;

    constructor(
        private readonly calendar: CalendarService,
        private readonly servicesApiCache: ServicesApiCache,
        private readonly scheduleApi: ScheduleService,
        private readonly helper: HelperService,
        private readonly destroy$: NgDestroyService,
    ) {
        super();
    }

    public ngOnInit(): void {
        this.setState();
        this.initCalendar();
    }

    @Input()
    public set selectedDate(date: Date) {
        this.currentlyViewedDate$.next(date ?? new Date());
    }

    public getButtonText(data: CalendarUnit): string {
        if (data.enabled) {
            return data.datetime.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        }

        return '—';
    }

    public getSelectedDateStr(): string {
        return this.helper.getStrMonthFullDate(this.currentlyViewedDate$.value);
    }

    public pickDate(): void {
        this.pickDateEvent.emit(void 0);
    }

    public shiftBackDisabled(): boolean {
        return this.currentlyViewedDate$.value.toDateString() === (new Date()).toDateString();
    }

    public shiftDate(offset: number): void {
        const newDate = addDays(this.currentlyViewedDate$.value, offset);
        this.currentlyViewedDate$.next(newDate);
    }

    private setState(): void {
        this.state = this.flexibleServiceLen ? new FlexibleState(this.calendar): new FixedState(this.calendar);
        this.state.picked.pipe(
            tap(d => {
                if(!d) {
                    this.intervalPicked.emit(null);
                } else {
                    switch (d.state) {
                        case CalendarViewState.Fixed:
                            this.timePicked.emit(d.start);
                            break;
                        case CalendarViewState.Flexible:
                            this.intervalPicked.emit({
                                start: d.start,
                                end: d.end
                            });
                            break;
                        default:
                            break;
                    }
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    private initCalendar(): void {
        this.currentlyViewedDate$.pipe(
            tap(() => this.loading = true),
            switchMap(startDate => {
                const endDate = addDays(startDate, 1);

                return !this.professionalId || !this.serviceId
                    ? of<ProfessionalCalendar[]>(null)
                    : this.scheduleApi.scheduleCalendarList({
                        service: this.serviceId?.toString(),
                        professional: this.professionalId?.toString(),
                        startDatetime: getLocalDateString(startDate),
                        endDatetime: getLocalDateString(endDate),
                    });
            }),
            map(list =>
                this.calendar.generate(configuration.default_calendar_interval, list, !!this.flexibleServiceLen)),
            tap(() => this.loading = false),
            catchError(() => {
                this.loading = false;

                return of([]);
            }),
            tap(calendar => this.calendar.setIntervals(calendar)),
            mergeMap(() => this.servicesApiCache.getByEntityId(this.serviceId)),
            tap((s: Service) => {
                this.calendar.setDefaultState(s.duration);
                this.intervalPicked.emit(null);
            }),  takeUntil(this.destroy$)).subscribe();
    }
}
