import {
    AfterViewInit,
    Component, EventEmitter,
    Input, Output, ViewChild,
} from '@angular/core';
import { IonContent, Platform } from '@ionic/angular';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
    selector: 'app-content-wrapper',
    templateUrl: './content-wrapper.component.html',
    styleUrls: ['./content-wrapper.component.scss'],
})
export class ContentWrapperComponent implements AfterViewInit {
    @Input() public isBgTransparent: boolean;
    @Input() public isBgLight: boolean = true;
    @Input() public isWidthMiddle: boolean;
    @Input() public isWidthWide: boolean = false;
    @Input() public headerFull: boolean = false;
    @Input() public actionsFull: boolean = false;
    @Output() public ionContent: EventEmitter<IonContent> = new EventEmitter<IonContent>();
    public hideHeader: Observable<boolean>;

    @ViewChild(IonContent, { static: false }) public readonly ionContentRef: IonContent;

    constructor(private readonly platform: Platform) {
    }

    public ngAfterViewInit(): void {
        if (this.platform.width() <= 480 ) {
            this.ionContentRef.scrollEvents = true;
            this.hideHeader = this.ionContentRef.ionScroll.pipe(
                debounceTime(10),
                map((e) => e.detail.deltaY > 0),
                distinctUntilChanged(),
            );
        }
        this.ionContent.emit(this.ionContentRef);
    }
}
