import { Injectable } from '@angular/core';
import { ProfessionalCalendar } from '@app/api/models';
import { addMinutes, stripTime } from '@app/core/functions/datetime.functions';
import { environment } from '@env/environment';
import { configuration } from '@conf/configuration';
import { CalendarInterval } from './calendar-interval';
import { CalendarUnit } from './calendar-unit';

interface Interval {
    start: Date;
    end: Date;
}

@Injectable()
export class CalendarService {
    public timetable: CalendarInterval[] = [];
    public closedUnits: Date[] = [];
    private readonly minutesInDay = 1440;
    private readonly intervals = environment.calendar_day_intervals;
    private readonly minutesInInterval = this.minutesInDay / this.intervals;

    public setIntervals(timetable: CalendarInterval[]): void {
        this.timetable = timetable;
    }

    public generate(stepSize: number, enabledPeriods: ProfessionalCalendar[], isFlexible: boolean): CalendarInterval[] {
        if (!Number.isInteger(this.minutesInInterval / stepSize)) {
            throw Error('cannot generate calendar with given interval');
        }
        if (!Array.isArray(enabledPeriods) || !enabledPeriods.length) {
            return [];
        }
        const calendar: CalendarInterval[] = [];
        const openedPeriodArray: Interval[] = this.generateEnabledPeriodsArray(enabledPeriods);
        const day = stripTime(openedPeriodArray[0].start);
        let firstEnabled = false;
        this.closedUnits = [];
        for (let intervals = 0; intervals < this.intervals; intervals += 1) {
            const units: CalendarUnit[] = [];
            for (
                let minutes = intervals * this.minutesInInterval;
                minutes <= (intervals + 1) * this.minutesInInterval;
                minutes += stepSize
            ) {
                const selectable = this.isSelectable(openedPeriodArray, day, minutes, isFlexible);
                if (selectable) {
                    firstEnabled = true;
                }
                if (firstEnabled) {
                    units.push({
                        datetime: addMinutes(day, minutes),
                        enabled: selectable,
                        selectable,
                    });
                    if(!selectable) {
                        this.closedUnits.push(addMinutes(day, minutes));
                    }
                }
            }
            if (!units.length) {
                continue;
            }
            const startStr = units[0].datetime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
            const endStr = addMinutes(units[units.length - 1].datetime, -1).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });

            if (units.length > 1 && units.filter(this.unitsFilter).length > 0) {
                let extend: CalendarUnit[] = [];
                if (units.length < 8) {
                    const additionalEmptyUnitsNumber = 7 - units.length;
                    extend = this.getEmptyPeriodsFromLast(units[units.length - 1], additionalEmptyUnitsNumber);
                }
                calendar.push(
                    { title: `${startStr} - ${endStr}`, units: [...units, ...extend] },
                );
            }
        }

        return calendar;
    }

    public setDefaultState(serviceLen: number): void {
        let defaultLength = 0;
        // @ts-ignore TODO: fix this
        let prev: CalendarUnit = null;
        for (let i = this.timetable.length - 1; i >= 0; i--) {
            for (let j = this.timetable[i].units.length -1; j >= 0; j--) {
                const unit = this.timetable[i].units[j];
                if(!unit.enabled) {
                    defaultLength = 0;
                }
                if(defaultLength < serviceLen) {
                    unit.close = true;
                    prev = !!prev? {...prev, close: true }: prev;
                }
                if(prev?.datetime.getTime() === unit?.datetime.getTime()) {
                    Object.assign(unit, prev);
                    continue;
                }
                defaultLength += configuration.default_calendar_interval;
                unit.interval = undefined;
                unit.enabled = !this.closedUnits.find(d => d.getTime() === unit.datetime.getTime());
                prev = unit;
            }
        }
    }

    private unitsFilter(unit: CalendarUnit, index: number, list: CalendarUnit[]): boolean {
        const today = index > 0 && unit.datetime.getDate() === list[index - 1].datetime.getDate();

        return unit.enabled && today;
    }

    private getEmptyPeriodsFromLast(fromUnit: CalendarUnit, num: number): CalendarUnit[] {
        const ret: CalendarUnit[] = [];

        for (let i = 1; i <= num; ++i) {
            ret.push({
                enabled: false,
                datetime: addMinutes(fromUnit.datetime, this.minutesInInterval * i),
            });
        }

        return ret;
    }

    private isSelectable(enabledPeriods: Interval[], day: Date, minutes: number, _isFlexible: boolean): boolean {
        const time = addMinutes(day, minutes);
        if ((new Date).getTime() > time.getTime()) {
            return false;
        }

        return enabledPeriods.some(period => time >= period.start && time < period.end);
    }

    private generateEnabledPeriodsArray(enabledPeriods: ProfessionalCalendar[]): Interval[] {
        return enabledPeriods.map(v => ({
            start: new Date(v.start_datetime),
            end: new Date(v.end_datetime),
        }));
    }
}
