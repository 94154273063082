import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { AnalyticEventsEnum, AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { Search } from '@app/api/models/search';
import { ServiceList } from '@app/api/models/service-list';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { IonModal, PopoverController } from '@ionic/angular';
import { ContactsWindowComponent } from '@app/shared/components/search-result-card/contacts-window/contacts-window.component';

const DESKTOP = 412;

@Component({
    selector: 'app-search-result-card',
    templateUrl: './search-result-card.component.html',
    styleUrls: ['./search-result-card.component.scss'],
})
export class SearchResultCardComponent implements OnInit {
    @Input() public data: Search;
    @Input() public queryParams: object;
    @Input() public professionalServicesUrl: string;
    @Input() public professionalChatUrl: string;
    @Input() public serviceOrderUrl: string;
    @Input() public serviceList: ServiceList[];
    @Input() public serviceCount: number;
    @Input() public hasMoreServices: boolean;
    @ViewChild(IonModal) public modal: IonModal;
    public isUserAuthor$: Observable<boolean>;
    public isShort: boolean;
    @Select(CurrentUserSelectors.userId)
    private readonly userID$: Observable<number>;

    constructor(private readonly analytics: AnalyticsService,
                private readonly popoverController: PopoverController) { }

    public ngOnInit(): void {
        const data$ = of(this.data);
        this.isUserAuthor$ = combineLatest([this.userID$, data$])
            .pipe(map(([userID, data]) => userID === data?.professional.user.id));

        this.isShort = window.innerWidth > DESKTOP;
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event): void {
        this.isShort = window.innerWidth > DESKTOP;
    }

    public getQueryParams(data: Search): object {
        return {
            ...this.queryParams,
            prof: data?.professional.id,
        };
    }

    public chat(): void {
        this.analytics.track(AnalyticEventsEnum.Chat);
    }

    public book(): void {
        // this.analytics.track(AnalyticEventsEnum.BookServiceInit);
    }

    public contacts(): void {
        this.analytics.track(AnalyticEventsEnum.ProfessionalContacts);
    }

    public async openContacts(): Promise<void> {
        const popover = await this.popoverController.create({
            component: ContactsWindowComponent,
            translucent: true,
            animated: true,
            cssClass: ['popover-width'],
            componentProps: {
                contacts: this.data?.professional?.contacts,
            },
        });

        await popover.present();
    }
}
