/* eslint-disable max-classes-per-file */

import { cityIn } from '@app/core/declination-cities/functions/city-functions';

export interface MetaData {
    serviceTitle: string;
    preposition: string;
    cityName: string;
}

export abstract class MetatextDefault {
    constructor(public data: MetaData) { }

    public abstract combine(): string;
}

export class CombineRuMetatext extends MetatextDefault {
    public combine(): string {
        return `${this.data.serviceTitle} ${this.data.preposition} ${cityIn(this.data.cityName)}`;
    }
}

export class CombineDefaultMetatext extends MetatextDefault {
    public combine(): string {
        return `${this.data.serviceTitle} ${this.data.preposition} ${this.data.cityName}`;
    }
}
