import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldIsEmpty'
})
export class FieldIsEmptyPipe implements PipeTransform {

  public transform(value: unknown): string {
    return value? `${value}`: '';
  }

}
