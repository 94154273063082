import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ProfessionalContactInline } from '@app/api/models/professional-contact-inline';
import { contactsIcon } from '@app/core/constants/image.constants';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
// @ts-ignore
import { Clipboard } from '@capacitor/clipboard';
import { configuration } from '@conf/configuration';

@Component({
    selector: 'app-contacts-view',
    templateUrl: './contacts-view.component.html',
    styleUrls: ['./contacts-view.component.scss'],
})
export class ContactsViewComponent {
    @Input() public contacts: ProfessionalContactInline[];
    public readonly contactsIcon = contactsIcon;
    @Output() public closeWindow = new EventEmitter();

    constructor(
        private readonly translate: TranslateService,
        private readonly toastController: ToastController) {}

    public getContactIcon(contact_code: string): string {
        const contact = contact_code.toLowerCase().replace('-','');

        return contact in contactsIcon? contactsIcon[contact].src:
            'assets/images/social/person-outline.svg';
    }

    public async copied(value: string): Promise<void> {
        await Clipboard.write({
            // eslint-disable-next-line id-blacklist
            string: value
        });
        this.toastController.create({
            message: `${this.translate.instant('profile-form.copied-to-clipboard')  }: ${ value }`,
            duration: 2000,
        }).then(t => t.present());
    }

    public getContactLink(contact): string {
        switch (contact.contact_display) {
            case 'Telegram':
            return `${ configuration.contacts.telegramPrefix }`;

            case 'WhatsApp':
                return `${ configuration.contacts.whatsappPrefix }`;

            case 'Phone':
                return `${ configuration.contacts.phonePrefix }`;

            case 'E-mail':
                return `${ configuration.contacts.mailPrefix }`;

            case 'Facebook':
                return `${ configuration.contacts.facebookPrefix }`;

            case 'Instagram':
                return `${ configuration.contacts.instagramPrefix }`;

            case 'Vkontakte':
                return `${ configuration.contacts.vkPrefix }`;

            default:
                return '';
        }
    }
}
