import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { declination } from '@app/core/functions/string.functions';
import {
    CombineDefaultMetatext,
    CombineRuMetatext,
    MetatextDefault
} from '@app/core/declination-cities/strategies/combine-metatext.startegy';


export interface MetaPreparationData {
    language: string;
    preposition: string;
    serviceTitle: string;
    cityName: string;
}

@Pipe({
    name: 'declension',
    pure: false,
})
export class DeclensionUniversalPipe implements PipeTransform {
    private declensions: string[] = [];
    constructor(private readonly translateService: TranslateService) {
    }

    public transform(count: number, key: string): string {
        if (!this.declensions.length) {
            this.declensions = [`declination.${key}.1`, `declination.${key}.2`, `declination.${key}.3`];
        }

        return !count ? '' : `${count} ${this.translateService.instant(declination(count, this.declensions))}`;
    }

    public combineMetaText(data: MetaPreparationData): string {
        const strategy = data.language === 'ru' ? CombineRuMetatext : CombineDefaultMetatext;
        const metatext: MetatextDefault = new strategy(data);

        return metatext.combine();
    }

}