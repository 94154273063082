import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserSettingsService } from '@app/core/services/facades';
import { TranslateService } from '@ngx-translate/core';

interface DateTimeIntervalInterface {
    start_datetime: string;
    end_datetime?: string;
}

const minutesInDay = 1440;

@Component({
    selector: 'app-datetime-interval-viewer',
    templateUrl: './datetime-interval-viewer.component.html',
    styleUrls: ['./datetime-interval-viewer.component.scss'],
})
export class DatetimeIntervalViewerComponent {

    public obj: DateTimeIntervalInterface;
    private moreThanOneDayDuration: boolean = false;

    @Input() public set unit(unit: DateTimeIntervalInterface) {
        this.obj = unit;
        if (unit?.end_datetime) {
            const start = new Date(unit?.start_datetime as string);
            const end = new Date(unit?.end_datetime as string);
            if ((end.getTime() - start.getTime()) / 60000 >= minutesInDay) {
                this.moreThanOneDayDuration = true;
            }
        }
    };

    constructor(
        private readonly settings: UserSettingsService,
        public readonly translator: TranslateService) {
    }

    public getStartDateTimeFormat(): Observable<string> {
        return this.getTimeFormat('d MMM y, H:mm', 'MMM d, y, h:mm a');
    }

    public getEndDateTimeFormat(): Observable<string> {
        if (this.moreThanOneDayDuration) {
            return this.getStartDateTimeFormat();
        }

        return this.getTimeFormat('H:mm', 'h:mm a');
    }

    private getTimeFormat(militaryFormat: string, nonMilitaryFormat: string): Observable<string> {
        return this.settings.settings$.pipe(
            first(),
            map(s => (s.is_military_time_format || s.is_military_time_format === undefined)
                ? militaryFormat
                : nonMilitaryFormat
            )
        );
    }
}
