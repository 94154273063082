import { Component, Input } from '@angular/core';
import { ProfessionalList, GeneralOrder, SentOrder, ServiceList } from '@app/api/models';

@Component({
    selector: 'app-service-widget',
    templateUrl: './service-widget.component.html',
    styleUrls: ['./service-widget.component.scss'],
})
export class ServiceWidgetComponent {
    @Input() public service: ServiceList;

    @Input() public order: SentOrder | GeneralOrder;

    @Input() public master: ProfessionalList;

    @Input() public alwaysExpanded = false;

    @Input() public previewMode = false;

    @Input() public activeLink = true;
}
