import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {ProfessionalLocation} from '@app/api/models';
import {AccountsService} from '@app/api/services/accounts.service';
import {StoreLocation} from '@app/store/local-store/store-location';

@Injectable({
    providedIn: 'root'
})
export class ProfessionalLocationsService extends StoreLocation<AccountsService, ProfessionalLocation> {

    constructor(private readonly accountsService: AccountsService) {
        super(accountsService);
    }

    public createLocation = (professionalLocation: ProfessionalLocation):
        Observable<{ allLocations: ProfessionalLocation[]; location: ProfessionalLocation }> =>
        this.locationService.accountsProfessionalLocationsCreate(professionalLocation)
            .pipe(tap(d => {
                    this.updateLocation$.next(d);
                    if (d.is_default && this._defaultLocation) {
                        this._defaultLocation.is_default = false;
                    }
                    this.prepareLocations([d, ...this._locations]);
                }),
                mergeMap((location) => this.initLocations()
                    .pipe(map((allLocations) => ({location, allLocations})))));

    public updateLocation = (professionalLocation: ProfessionalLocation):
        Observable<{ allLocations: ProfessionalLocation[]; location: ProfessionalLocation }> => this.locationService
        .accountsProfessionalLocationsPartialUpdate({id: professionalLocation.id, data: professionalLocation})
        .pipe(tap(data => {
                    this.updateLocation$.next(data);
                    this._locations.forEach((l, i) => {
                        if (l.id === data.id) {
                            this._locations[i] = data;
                        } else if (data.is_default && l.is_default) {
                            this._locations[i].is_default = false;
                        }
                    });
                    this.prepareLocations(this._locations);
                },
            ),
            mergeMap((location) => this.initLocations()
                .pipe(map((allLocations) => ({location, allLocations})))));

    public deleteLocation = (id: number): Observable<ProfessionalLocation[]> =>
        this.accountsService.accountsProfessionalLocationsDelete(id).pipe(tap(() => {
                this._locations = this._locations.filter(l => l.id !== id);
                this.prepareLocations(this._locations);
                this.deleteLocation$.next(id);
            }),
            mergeMap(() => this.initLocations()));

    public getLocationById = (id: number): Observable<ProfessionalLocation> => {
        if (!!this._locations.find(loc => loc.id === id)) {
            return of(this._locations.find(loc => loc.id === id) as ProfessionalLocation);
        } else {
            return this.accountsService.accountsProfessionalLocationsRead(id);
        }
    };

    public loadLocations = (): Observable<ProfessionalLocation[]> =>
        this.accountsService.accountsProfessionalLocationsList({}).pipe(
            map(request => request.results.filter(location => !!location.country && !!location.city) ?? []),
            tap(locations => this.prepareLocations(locations as ProfessionalLocation[])));
}
