<ng-container [ngSwitch]="service?.service_type">
    <ion-item lines="none" *ngSwitchCase="'online'" id="online-service">
        <ion-icon slot="start" name="globe-outline" color="secondary"></ion-icon>
        <ion-text>{{ 'service-location.online' | translate }}</ion-text>
    </ion-item>
    <ion-item lines="none" *ngSwitchCase="'professional'" id="professional-service">
        <ion-icon slot="start" name="location-outline" color="secondary"></ion-icon>
        <ion-text>{{ 'service-location.professional' | translate }}</ion-text>
    </ion-item>
    <ion-item lines="none" *ngSwitchCase="'client'" id="client-service">
        <ion-icon slot="start" name="location-outline" color="secondary"></ion-icon>
        <ion-text>{{ 'service-location.client' | translate }}</ion-text>
    </ion-item>
    <app-loading-indicator *ngSwitchDefault></app-loading-indicator>
    <ng-container *ngIf="service?.locations?.length">
        <ion-item *ngFor="let serviceLocation of service?.locations" lines="none">
            <ion-icon slot="start"></ion-icon>
            <ion-text>
                <app-location-viewer [location]="serviceLocation.location"></app-location-viewer>
            </ion-text>
        </ion-item>
    </ng-container>
</ng-container>
