import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GeneralOrder, ReceivedOrderClient } from '@app/api/models';
import { Router } from '@angular/router';

@Component({
    selector: 'app-client-widget',
    templateUrl: './client-widget.component.html',
    styleUrls: ['./client-widget.component.scss'],
})
export class ClientWidgetComponent implements OnChanges {
    @Input() public order!: GeneralOrder;
    @Input() public currentUserProfId!: number;
    public client?: ReceivedOrderClient; // TODO: check type of client

    constructor(private readonly router: Router) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { order } = changes;
        if (order?.currentValue?.client) {
            const professionalIdArr = order.currentValue.client?.professionals;
            let professionals = null;
            if (professionalIdArr?.length) {
                [ professionals ] = !professionalIdArr?.includes(this.currentUserProfId) ?
                    order.currentValue.client?.professionals?.slice(0, 1) :
                    [null];
            }
            this.client = {...order.currentValue.client, professionals};
        }
    }

    // @ts-ignore TODO: refactor it, typing
    public navToProfile(professional): void {
        if (professional) {
            this.router.navigateByUrl(`/professional/${professional}/profile`).then();
        }
    }
}

