<ion-app [ngClass]="{ 'dark-theme': darkTheme$ | async }" dir="{{ isInvert ? 'rtl' : 'ltr' }}">
    <ion-menu contentId="main-content" menuId="main-menu" side="start">
        <app-main-menu [isAuthenticated]="isAuthenticated$ | async" [isMaster]="masterManager.isMaster$ | async">
        </app-main-menu>
    </ion-menu>
    <ng-container *ngIf="showPromo$ | async">
        <app-professional-promo (hideButtonClicked)="hidePromo()"></app-professional-promo>
    </ng-container>
    <ion-content  id="main-content">
        <app-header></app-header>
        <ion-router-outlet class="router"></ion-router-outlet>
    </ion-content>
    <ion-menu contentId="main-content" class="flag-menu" menuId="flag-menu" side="end">
        <app-flag-menu></app-flag-menu>
    </ion-menu>
</ion-app>
