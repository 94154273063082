/* eslint-disable max-classes-per-file */

import { applyMod, getModByIdx } from '@app/core/declination-cities/functions/decline-functions';
import { DeclentionModsT } from '@app/core/declination-cities/models/declension.types';

export abstract class DeclinationDefault {
    constructor(
        public word: string,
        public mods: DeclentionModsT
    ) { }

    public abstract decline(): string;
}

export class NominativeCityname extends DeclinationDefault {
    public decline(): string {
        const mod = '.';

        return applyMod(this.word, mod);
    }
}

export class GenitiveCityname extends DeclinationDefault {
    public decline(): string {
        const mod = getModByIdx(this.mods, 0);

        return applyMod(this.word, mod);
    }
}

export class DativeCityname extends DeclinationDefault {
    public decline(): string {
        const mod = getModByIdx(this.mods, 1);

        return applyMod(this.word, mod);
    }
}

export class AccusativeCityname extends DeclinationDefault {
    public decline(): string {
        const mod = getModByIdx(this.mods, 2);

        return applyMod(this.word, mod);
    }
}

export class InstrumentalCityname extends DeclinationDefault {
    public decline(): string {
        const mod = getModByIdx(this.mods, 3);

        return applyMod(this.word, mod);
    }
}

export class PrepositionalCityname extends DeclinationDefault {
    public decline(): string {
        const mod = getModByIdx(this.mods, 4);

        return applyMod(this.word, mod);
    }
}
