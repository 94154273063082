<form [formGroup]="form" (ngSubmit)="saveContact()">
    <ion-item>
        <ion-label class="label-for-required-input" color="medium" position="floating">
            {{ 'contact-edit-page.choice-contact' | translate }}
        </ion-label>
        <ion-select interface="popover" formControlName="contact" name="contact">
            <ion-select-option *ngFor="let contactItem of contactItems | contactFilter" [value]="contactItem?.id">
                {{ 'contact-edit-page.' + (contactItem.name | fieldIsEmpty).toLowerCase() | translate }}
            </ion-select-option>
        </ion-select>
    </ion-item>
    <app-form-control-error [controlName]="'contact'"> </app-form-control-error>
    <ion-item [disabled]="!form.value?.contact">
        <ion-label class="label-for-required-input" color="medium" position="floating">
            {{ 'contact-edit-page.enter-contact' | translate }}
        </ion-label>
        <ion-input [ngClass]="prefixClass" (click)="setPrefix()" (ionBlur)="clearFocus()" (ionChange)="inputContact($event)" type="text" name="contact-value" formControlName="value"></ion-input>
    </ion-item>
    <app-form-control-error [controlName]="'value'"> </app-form-control-error>
    <ion-row class="ion-justify-content-center ion-margin-top">
        <ion-col size="6">
            <ion-button
                *ngIf="canDelete"
                (click)="deleteContact()"
                size="medium"
                color="danger"
                expand="block"
                fill="solid"
            >
                {{ 'contact-edit-page.delete' | translate }}
            </ion-button>
        </ion-col>
        <ion-col size="6">
            <ion-button
                [disabled]="form.invalid || form.pristine"
                size="medium"
                expand="block"
                fill="solid"
                type="submit"
            >
                {{ 'contact-edit-page.save' | translate }}
            </ion-button>
        </ion-col>
    </ion-row>
</form>
