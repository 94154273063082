import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {once} from '@app/core/decorators/once';

@Injectable({
  providedIn: 'root'
})
export class ClarityService {

    constructor(@Inject(DOCUMENT) private readonly doc: any) {
    }

    @once
    public init(): void {
        const head = this.doc.getElementsByTagName('head')[0];
            const s = this.doc.createElement('script');
            s.type = 'text/javascript';
            // eslint-disable-next-line max-len
            s.innerHTML = '(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "i8cmevr8ly");';
            head.appendChild(s);
    }
}
