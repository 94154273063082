<app-content-wrapper>
    <app-column-header header [customBack]="customBackButton" (back)="goBack()">
        {{ 'professional-schedule.title' | translate }}
        <ion-button end (click)="signalShowDaySelector()">
            <ion-icon name="add-outline"></ion-icon>
        </ion-button>
    </app-column-header>
    <app-schedule-editor [timeDisabled]="isFullDayService" [formControl]="scheduleEditor" required></app-schedule-editor>
    <!--<ion-button type="submit" expand="block" [disabled]="scheduleEditor.invalid" (click)="saveTimetable()">-->
    <!--    {{ 'global.save' | translate }}-->
    <!--</ion-button>-->
    <div actions>
        <ion-col>
            <ion-button
                expand="block"
                [disabled]="scheduleEditor.invalid || (loadingService.disable$ | async)"
                (click)="saveTimetable()">
                {{ 'global.save' | translate }}
            </ion-button>
        </ion-col>
    </div>
</app-content-wrapper>
