<div class="phone-input-container" *ngIf="countryControl && phoneControl">
    <ion-item class="country-selector">
        <ion-label color="medium" position="floating">
            {{ title | translate }}
        </ion-label>
        <ionic-selectable
            [formControl]="countryControl"
            [items]="countries$ | async"
            [canSearch]="true"
            [shouldFocusSearchbar]="true"
            [hasVirtualScroll]="true"
            [closeButtonText]="'global.cancel' | translate"
            [searchPlaceholder]="'global.titles.search' | translate"
            [clearButtonText]="'global.clear' | translate"
            [closeButtonText]="'global.cancel' | translate"
            [searchFailText]="'ion-selectable.search-fail-text' | translate"
            itemTextField="name"
            itemValueField="id"
        >
            <ng-template ionicSelectableValueTemplate let-value="value">
                <span class="flag-icon flag-icon-{{ value?.tld }}"></span>
                {{ plusSignEnforce(value?.phone) }}
            </ng-template>
            <ng-template ionicSelectableItemTemplate let-item="item">
                <div><span class="flag-icon flag-icon-{{ item?.tld }}"></span> {{ item?.name }}</div>
            </ng-template>
        </ionic-selectable>
    </ion-item>
    <ion-item class="phone-input">
        <ion-label [class.label-for-required-input]="required"
                   color="medium" position="floating">{{ 'registration-form.phone' | translate }}</ion-label>
        <ion-input [formControl]="phoneControl" inputmode="decimal" appNumbersOnly></ion-input>
    </ion-item>
</div>
