import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { configuration } from '@conf/configuration';

@Pipe({
  name: 'fullDateTime',
  pure: false
})
export class FullDateTimePipe implements PipeTransform {
    constructor(private readonly translator: TranslateService) {
    }
    public transform(value: string, short: boolean = false): string {
        const datePipe = new DatePipe(this.translator.currentLang ?? configuration.default_lang);

        return `${datePipe.transform(value, 'fullDate')} ${(short? '': datePipe.transform(value, 'shortTime'))}`;
    }
}
