import { CalendarUnit } from '@app/shared/components/calendar-component/calendar-unit';

export class UnitStatus {
    public getDisabled(unit: CalendarUnit): boolean | undefined {
        return !unit?.enabled || unit?.interval?.disabled || unit?.close;
    }

    public getUnitColor(unit: CalendarUnit): string {
        if (!unit?.enabled || unit?.interval?.disabled) {
            return 'light';
        }
        if (unit?.interval?.start) {
            return 'success';
        }

        return (unit.selected || unit?.interval?.selected) ? 'primary' : 'dark';
    }

    public getUnitFill(unit: CalendarUnit): string {
        if (!unit.enabled) {
            return 'solid';
        }

        return (unit.selected || unit?.interval?.selected) ? 'solid' : 'outline';
    }
}
