<ion-content>
    <div class="main-container horizontal-padding-content">
        <h1 class="header">{{ 'creative-banners.header' | translate: { app: configuration.app_title | translate } }}</h1>
        <div class="slides" *ngIf="!isMobile">
            <ng-template [ngTemplateOutlet]="professional"></ng-template>
            <ng-template [ngTemplateOutlet]="user"></ng-template>
        </div>
        <div class="slides" *ngIf="isMobile">
            <ion-segment [value]="tab" (ionChange)="changeTab($event)" class="segment-slide-container">
                <ion-segment-button value="prof">{{ 'creative-banners.professional' | translate }}</ion-segment-button>
                <div class="separator"></div>
                <ion-segment-button value="user">{{ 'creative-banners.client' | translate }}</ion-segment-button>
            </ion-segment>
            <ng-container [ngSwitch]="tab">
                <div *ngSwitchCase="'prof'" class="to-fade">
                    <ng-template [ngTemplateOutlet]="professional"></ng-template>
                </div>
                <div *ngSwitchCase="'user'" class="to-fade">
                    <ng-template [ngTemplateOutlet]="user"></ng-template>
                </div>
            </ng-container>
        </div>
    </div>
    <app-footer></app-footer>
</ion-content>

<ng-template #professional>
    <div class="slide-container">
        <h2>{{ 'creative-banners.professional' | translate }}</h2>
        <div class="sub-head">{{ 'creative-banners.professional-sub-head' | translate}}</div>
        <div class="img">
            <img *ngIf="banner1" [src]=banner1 />
        </div>
        <div class="description">{{ 'creative-banners.professional-message' | translate }}</div>
        <div class="button-container">
            <ion-button [routerLink]="'/' + NavPath.Service + '/' + NavPath.Publish">{{ 'creative-banners.service-publish' | translate }}</ion-button>
        </div>
    </div>
</ng-template>

<ng-template #user>
    <div class="slide-container">
        <h2>{{ 'creative-banners.client' | translate }}</h2>
        <div class="sub-head">{{ 'creative-banners.client-sub-head' | translate}}</div>
        <div class="img">
            <img *ngIf="banner2" [src]=banner2 />
        </div>
        <div class="description">{{ 'creative-banners.client-message' | translate }}</div>
        <div class="button-container">
            <ion-button [routerLink]="'/' + NavPath.ClientApplications + '/' + NavPath.Publish">{{ 'creative-banners.order-create' | translate }}</ion-button>
            <ion-button [routerLink]="'/' + NavPath.Search">{{ 'creative-banners.search' | translate }}</ion-button>
        </div>
    </div>
</ng-template>
