import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent {
    @Input() public rating: string;
}
