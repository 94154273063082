export const acceptedMimeTypes = 'image/x-png,image/gif,image/jpeg';

export interface ContactIconInterface {
    src: string;
    alt?: string;
}

export interface ContactListInterface {
    [key: string]: ContactIconInterface;
}

export const contactsIcon: ContactListInterface = {
    vk: {
        src: 'assets/images/social/vk.png',
        alt: 'vk'
    },
    vkontakte: {
        src: 'assets/images/social/vk.png',
        alt: 'vk'
    },
    instagram: {
        src: 'assets/images/social/insta.svg',
        alt: 'instagram'
    },
    skype: {
        src: 'assets/images/social/skype.svg',
        alt: 'skype'
    },
    telegram: {
        src: 'assets/images/social/tg.png',
        alt: 'telegram'
    },
    whatsapp: {
        src: 'assets/images/social/wa.png',
        alt: 'whatsapp'
    },
    email: {
        src: 'assets/images/social/mail.svg',
        alt: 'email'
    },
    phone: {
        src: 'assets/images/social/phone.svg',
        alt: 'phone'
    },
    facebook: {
        src: 'assets/images/social/fb.png',
        alt: 'facebook'
    },
    web: {
        src: 'assets/images/social/www.png',
        alt: 'web'
    }
};
