import { NavPath } from '@app/core/constants/navigation.constants';
import { Category } from '@app/api/models/category';

export const links = {
    origin: 'https://servicesalesapp.com',
    email: "info@servicesalesapp.com",
    email_support: "support@servicesalesapp.com",
    footer: {
        column_one: [
            {
                url: '/',
                id: 'main',
                text: 'footer.main'
            },
            {
                url: NavPath.About,
                id: 'about',
                text: 'footer.about'
            },
            // ru & com differ
            {
                url: NavPath.ProfessionalLanding,
                id: 'professional-landing',
                text: 'footer.professional'
            },
            {
                url: NavPath.ProfessionalSchedule,
                id: 'professional-schedule',
                text: 'footer.professional-schedule'
            },
            {
                url: NavPath.CreativeBanners,
                id: 'client-applications',
                text: 'footer.client-applications'
            },
            {
                url: NavPath.Blog,
                id: 'blog',
                text: 'blog.title'
            },
        ],
        column_two: [
            {
                url: NavPath.Support,
                id: 'support-videos',
                text: 'footer.support-page'
            },
            {
                url: NavPath.Eula,
                id: 'eula',
                text: 'footer.eula'
            },
            {
                url: NavPath.Policy,
                id: 'policy',
                text: 'footer.policy'
            }
        ]
    },
    domain: "https://servicesalesapp.com",
    catalogLinks: (category: Category) => `/${NavPath.Search}/0/0/${category.slug}/0/${btoa(`,,${category.id},`)}`,
    catalogParams: (id: number) => null
};
