import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { BookingStateService } from '@app/book/services/booking-state.service';
import { BookPage } from '@app/book/book.page';
import { first, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NavigationService } from '@app/core/services/navigation.service';

const urlExclusion = ['/profile/location-edit', '/auth/registration', '/auth/login', '/profile/location-add'];
const quoteSpecial = (str: string): string => str.replace(/([\[\]^$|()\\+*?{}=!.])/g, '\\$1');

@Injectable({
    providedIn: 'root',
})
export class OrderResetStateGuardService implements CanDeactivate<BookPage>, CanActivate {

    private readonly re = new RegExp(`(?:${  urlExclusion.map(quoteSpecial).join('|')  })`);

    constructor(
        private readonly state: BookingStateService,
        private readonly navigation: NavigationService,
    ) {
    }

    public canDeactivate(_component: BookPage, _route: ActivatedRouteSnapshot): boolean {
        this.state.reset();

        return true;
    }

    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        return this.navigation.previousUrl.pipe(
            first(),
            map(url => url?.split('?')?.shift()),
            tap(prev => {
                    if (!prev || !this.checkExclusion(prev)) {
                        this.state.reset();
                    } else {
                        void 0;
                    }
                },
            ),
            map(() => true),
        );
    }

    private checkExclusion(url: string): boolean {
        return this.re.test(url);
    }
}
