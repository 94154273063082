import { Component, Input } from '@angular/core';
import { UserExtended } from '@app/api/models';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
    @Input()
    public set user(user: UserExtended) {
        this.initials =
            (user?.first_name ? user?.first_name[0]?.toUpperCase(): '') +
            (user?.last_name ? user?.last_name[0]?.toUpperCase(): '');
        this.name = (user?.first_name ? user?.first_name?.toUpperCase(): '') +
            (user?.last_name ? user?.last_name?.toUpperCase(): '');
        this.getAvatarStyle();
    }

    @Input()
    public set avatar(avatar: string) {
        this.src = avatar;
    }

    @Input() public isLargeMode: boolean = false;

    public src: string = '';
    public initials: string = '';
    public name: string = '';
    public border: string = 'border: 1px solid  var(--ion-color-primary);';
    public background: string = 'background: rgba(var(--ion-color-primary-rgb), 0.1);';

    private readonly colours = [
        'primary',
        'secondary',
        'tertiary',
        'success',
        'warning',
        'danger',
    ];

    private getAvatarStyle(): void {
        if (!this.src) {
            this.background =`background: rgba(var(--ion-color-${  this.generateColour() }-rgb), 0.1);`;
            this.border = `border: 1px solid  var(--ion-color-${  this.generateColour() });`;
        } else {
            this.border = 'border: none;';
        }
    }

    private generateColour(): string {
        let sum = 0;
        for (const i of this.name){
            sum += i.charCodeAt(0);
        }

        return this.colours[sum % this.colours.length];
    }
}
