<app-service-title [order]="order" [service]="service" [activeLink]="activeLink"></app-service-title>
<app-professional-card [previewMode]="previewMode" *ngIf="master" class="ion-padding" [professional]="master"></app-professional-card>
<app-more-info *ngIf="!alwaysExpanded; else serviceDetails">
    <ng-container *ngTemplateOutlet="serviceDetails"></ng-container>
</app-more-info>

<ng-template #serviceDetails>
    <ion-item lines="none" *ngIf="service?.description">
        <div class="preserve-line-breaks capitalize-first-letter">{{ service.description }}</div>
    </ion-item>
    <app-service-tags-viewer *ngIf="service.tags?.length" [tags]="service.tags"></app-service-tags-viewer>
    <app-service-photos [serviceId]="service.id"></app-service-photos>
    <ion-list>
        <ion-list-header>
            <ion-label>
                <div class="menu-item-title">{{ 'service-details.location-title' | translate }}</div>
            </ion-label>
        </ion-list-header>
        <app-service-location [service]="service"></app-service-location>
    </ion-list>

    <ion-list>
        <ion-list-header>
            <ion-label>
                <div class="menu-item-title">{{ 'service-details.payment-title' | translate }}</div>
            </ion-label>
        </ion-list-header>
        <app-payment-method-viewer [price]="service.price"></app-payment-method-viewer>
    </ion-list>

    <ion-list *ngIf="service.is_auto_order_confirmation">
        <ion-list-header>
            <ion-label>
                <div class="menu-item-title">{{ 'service-details.instant-book-title' | translate }}</div>
            </ion-label>
        </ion-list-header>
        <ion-item lines="none">
            <ion-icon slot="start" name="checkbox-outline" color="secondary"></ion-icon>
            <ion-text>
                {{ 'service-details.instant-book-description' | translate }}
            </ion-text>
        </ion-item>
    </ion-list>
</ng-template>
