import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ScheduleEditorComponent } from '@app/shared/components';
import { FormControl } from '@angular/forms';
import { ScheduleUnion } from '@app/core/models/schedule-union';
import { LoadingService } from '@app/core/services';
@Component({
    selector: 'app-schedule-page',
    templateUrl: './schedule-page.component.html',
    styleUrls: ['./schedule-page.component.scss'],
})
export class SchedulePageComponent {
    public scheduleEditor = new FormControl();
    public isFullDayService: boolean = false;
    @Output() public setSchedule = new EventEmitter<any>();
    @Output() public discard = new EventEmitter<any>();
    @Input() public set schedule (schedule: ScheduleUnion[]) {
        this._schedule = schedule;
        this.scheduleEditor.setValue(schedule);
    }
    public get schedule(): ScheduleUnion[] {
        return this._schedule;
    }

    @Input() public customBackButton = true;
    @ViewChild(ScheduleEditorComponent)
    private readonly scheduleEditorComponent!: ScheduleEditorComponent;
    private _schedule: ScheduleUnion[] = [];

    constructor(public loadingService: LoadingService){}

    public signalShowDaySelector(): void {
        this.scheduleEditorComponent.showDaySelector();
    }

    public saveTimetable(): void {
        this.setSchedule.emit(this.scheduleEditor.value);
    }

    public goBack(): void {
        this.discard.emit(this._schedule);
    }
}
