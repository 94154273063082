import { Component, EventEmitter, HostBinding, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavPath, NavQueryParams } from '@app/core/constants/navigation.constants';
import { SHOW_BANNER } from '@app/shared/banners/tokens/show-banner.token';
import { Subject } from 'rxjs';
import { NgDestroyService } from '@app/core/services';
import { takeUntil } from 'rxjs/operators';

export enum BannerMode {
    search = 'search',
    main = 'main'
}

const SHOW_ORDER_BANNER = 'show_order_banner';

@Component({
    selector: 'app-place-order-banner',
    templateUrl: './place-order-banner.component.html',
    styleUrls: ['./place-order-banner.component.scss'],
    providers: [NgDestroyService],
})
export class PlaceOrderBannerComponent implements OnInit {
    @Input() public mode: BannerMode = BannerMode.main;
    @Output() public closeEmit: EventEmitter<void> = new EventEmitter<void>();

    public showBanner: boolean = true;

    @HostBinding('attr.class')
    private get class(): string {
        return this.mode;
    };

    constructor(
        private readonly router: Router,
        @Inject(SHOW_BANNER) private readonly showBanner$: Subject<{ [key: string]: boolean }>,
        private readonly destroy$: NgDestroyService,
    ) {
    }

    public ngOnInit(): void {
        if (this.mode === BannerMode.main) {
            this.showBanner = !sessionStorage[SHOW_ORDER_BANNER];
            this.showBanner$
                .pipe(takeUntil(this.destroy$))
                .subscribe(ev => this.showBanner = ev[SHOW_ORDER_BANNER]);
        }
    }

    public navigation(): void {
        this.router.navigate([`/${NavPath.CreativeBanners}`],
            { queryParams: { [NavQueryParams.goBack]: true } }).then();
    }

    public close(): void {
        if (this.mode === BannerMode.main) {
            sessionStorage.setItem(SHOW_ORDER_BANNER, 'false');
        }
        this.showBanner$.next({ [SHOW_ORDER_BANNER]: false });
        this.closeEmit.emit();
    }
}
