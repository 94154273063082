import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/core/operators/take-until-destroyed';

@DecorateUntilDestroy()
@Directive({
    selector: '[appTrimOnBlur]',
})
export class TrimBlurDirective implements OnInit, OnDestroy {
    private readonly ionBlurEvent$: Observable<unknown>;
    private eventSub!: Subscription;

    constructor(
        private readonly host: ElementRef,
        private readonly controlDirective: NgControl,
    ) {
        this.ionBlurEvent$ = fromEvent(this.host.nativeElement, 'ionBlur');
    }

    public ngOnInit(): void {
        this.eventSub = this.ionBlurEvent$
            .pipe(takeUntilDestroyed(this))
            .subscribe(() => {
                const value = this.controlDirective.control?.value;
                if (value) {
                    this.controlDirective.control?.setValue(value?.trim());
                }
            });
    }

    public ngOnDestroy(): void {
        this.eventSub.unsubscribe();
    }
}
