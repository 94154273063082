import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    H4Component,
} from '@app/shared/ui/h4/h4.component';


import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IonicSelectableModule } from 'ionic-selectable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IfSpinnerModule } from '@app/shared/if-spinner/if-spinner.module';
import { NgStringPipesModule } from 'ngx-pipes';


@NgModule({
    declarations: [
        H4Component,
    ],
    exports: [
        H4Component,
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        IonicSelectableModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        LeafletModule,
        ImageCropperModule,
        IfSpinnerModule,
        NgStringPipesModule,
    ],
    providers: [],
})

export class UIModule {
}
