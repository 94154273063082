<div class="col">
    <div class="footer">
        <ion-button expand="block" class="footer__btn" (click)="confirm()" [disabled]="disabled">
            <ion-label>{{ transKey | translate }}</ion-label>
        </ion-button>
    </div>
    <div class="footer ion-padding-top" *ngIf="showBackBtn">
        <ion-button expand="block" class="footer__btn" color="light" (click)="backCLick()">
            <ion-label>{{ 'global.back' | translate }}</ion-label>
        </ion-button>
    </div>
    <div class="footer" *ngIf="showFooter">
        <div class="footer__btn">
            <app-service-publish-agreement [button]="transKey"></app-service-publish-agreement>
        </div>
    </div>
</div>
