import { Injectable } from '@angular/core';
import { City } from '@app/api/models';
import { LocationService } from '@app/api/services';
import { ApiCache } from '@app/core/services/cache/api-cache.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CitiesApiCache extends ApiCache<City> {
    constructor(private readonly locationService: LocationService) {
        super();
    }

    public searchByName(query: string): Observable<City[]> {
        return this.locationService.locationCitiesList({ byName: query}).pipe(map(c => c.results));
    }

    protected read(id: number): Observable<City> {
        return this.locationService.locationCitiesRead(id);
    }
}
