import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormArray,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validator, ValidationErrors, NG_VALIDATORS,
} from '@angular/forms';
import * as ScheduleConstants from '@app/core/constants/schedule.constants';
import { dayOfWeekSorter, mondayOrSundayOrder, ScheduleUnion } from '@app/core/models/schedule-union';
import { NgDestroyService } from '@app/core/services';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { PopoverController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DaySelectorComponent } from '@app/shared/components';
import { TimePickerComponent } from '@app/shared/components/schedule-editor/editor/time-picker/time-picker.component';
import { createFormGroup, createShceduleForm, normalizeScheduleFormat } from './functions';
import { ScheduleEditorFormFields } from './schedule-editor-form-fields.enum';

@Component({
    selector: 'app-schedule-editor',
    templateUrl: './schedule-editor.component.html',
    styleUrls: ['./schedule-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ScheduleEditorComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: ScheduleEditorComponent,
            multi: true,
        },
    ],
})
export class ScheduleEditorComponent implements ControlValueAccessor, Validator, OnInit {
    @Select(CurrentUserSelectors.isMondayFirstDayOfWeek)
    public isMondayFirstDayOfWeek$!: Observable<boolean>;

    public disabled: boolean = false;
    public timetable: FormArray;
    public error = false;
    public formFields = ScheduleEditorFormFields;
    @Input() public timeDisabled!: boolean;

    private onChange!: (value: ScheduleUnion[]) => void;
    private onTouched!: () => void;


    private startTime = '00:00';
    private endTime = '00:00';

    constructor(
        private readonly popoverController: PopoverController,
        private readonly cd: ChangeDetectorRef,
        private readonly ngDestroy$: NgDestroyService,
    ) {
        this.timetable = createShceduleForm();

    }

    public ngOnInit(): void {
        this.timetable.valueChanges.pipe(takeUntil(this.ngDestroy$)).subscribe((schedules: ScheduleUnion[]) => {
            if (this.onChange) {
                this.onChange(schedules.map(schedule => ({ ...schedule, is_enabled: true })));
            }
        });
    }

    public validate(_control: AbstractControl): ValidationErrors | null {
        const controlError = this.timetable.controls.find(c => !!c.errors);
        const error = (controlError || this.timetable.errors);

        return error ? { error: true } : null;
    }

    public registerOnValidatorChange?(_fn: () => void): void {
        // todo: does not register anything
    }

    @Input()
    public set schedule(schedule: ScheduleUnion[]) {
        if (schedule && schedule.length) {
            // @ts-ignore TODO: refactor it, value cannot be undefined
            this.startTime = schedule[0].start_time;
            // @ts-ignore TODO: refactor it, value cannot be undefined
            this.endTime = schedule[0].end_time;
        }
        // todo: remove Input(), use only writeValue()
        this.isMondayFirstDayOfWeek$.pipe(takeUntil(this.ngDestroy$)).subscribe(isMondayFirstDayOfWeek => {
            const initialValue = (schedule ?? ScheduleConstants.defaultSchedule)
                .map(normalizeScheduleFormat)
                .sort(dayOfWeekSorter(mondayOrSundayOrder(isMondayFirstDayOfWeek)));
            this.fillFromSchedules(initialValue);
        });
    }

    public get controls(): FormGroup[] {
        return this.timetable.controls as FormGroup[];
    }

    public getDayByIndex(i: number): string {
        return ScheduleConstants.defaultWeek[i];
    }

    public async showDaySelector(): Promise<void> {
        const popover = await this.popoverController.create({
            component: DaySelectorComponent,
            translucent: true,
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        if (data !== undefined) {
            this.addNewFormGroup(data, this.startTime.substr(0, 5), this.endTime.substr(0, 5));
        }
        this.cd.markForCheck();
    }

    public async showTimeSelector(formControl: FormGroup, type: ('start_time' | 'end_time')): Promise<void> {
        const popover = await this.popoverController.create({
            component: TimePickerComponent,
            componentProps: {
                value: formControl.value[type],
                ionChange: ({ detail }: any) => {
                    if (detail.value.length > 5) {
                        formControl.value[type] = detail.value.split('T')[1].slice(0, -9);
                    } else {
                        formControl.value[type] = detail.value;
                    }
                },
            },
            translucent: true,
        });

        await popover.present();
        await popover.onWillDismiss();
        this.cd.markForCheck();
    }

    public deleteDay(index: number): void {
        if (this.onTouched) {
            this.onTouched();
        }
        this.timetable.removeAt(index);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(value: ScheduleUnion[]): void {
        if (!value) {
            return;
        }
        this.schedule = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onSelectionChange(element: FormGroup): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        element.enabled ? element.disable() : element.enable();
    }

    public checkScheduleLength(dayForm: FormGroup): boolean {
        return this.controls.filter(c => c.status === 'VALID').length === 1 && dayForm.status === 'VALID';
    }

    private fillFromSchedules(schedules: ScheduleUnion[]): void {
        this.timetable.clear();
        schedules.forEach(data => this.addNewFormGroup(data.day_of_week, data.start_time, data.end_time));
    }

    private addNewFormGroup(dayCode: number, startTime: string | null = null, endTime: string | null = null): void {
        // @ts-ignore TODO: refactor it, typings
        const newFormGroup = createFormGroup({ dayCode, startTime, endTime });
        this.timetable.push(newFormGroup);
    }
}
