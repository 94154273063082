/* eslint-disable max-lines */
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
    AddButtonComponent,
    AvatarComponent,
    CategorySelectorComponent,
    ClientWidgetComponent,
    ColumnHeaderComponent,
    ContentWrapperComponent,
    CountryFlagComponent,
    DateIntervalEditorComponent,
    DaySelectorComponent,
    DurationEditorComponent,
    DurationViewerComponent,
    FooterComponent,
    GenderSelectorComponent,
    HeaderComponent,
    HintComponent,
    ImageCarouselComponent,
    LanguageComponent,
    LoadingIndicatorComponent,
    LocationViewerComponent,
    MainMenuComponent,
    MoreInfoComponent,
    NotFoundPageComponent,
    OrderDetailsComponent,
    PaymentMethodViewerComponent,
    PhoneEditorComponent,
    PhotoPopoverComponent,
    PlaceholderComponent,
    PresenceIndicatorComponent,
    PriceComponent,
    PriceEditorComponent,
    ProfessionalCardComponent,
    ScheduleEditorComponent,
    ScheduleViewerComponent, SearchbarTypeaheadComponent,
    SectionHeadingComponent,
    ServiceLocationComponent,
    ServiceTagsEditorComponent,
    ServiceTagsViewerComponent,
    ServiceTitleComponent,
    ServiceWidgetComponent,
    SubcategorySelectorComponent,
} from '@app/shared/components';
import { CalendarViewComponent } from '@app/shared/components/calendar-component/calendar-view.component';
import { CalendarMonthComponent } from '@app/shared/components/calendar/calendar-month/calendar-month.component';
import { CalendarWeekComponent } from '@app/shared/components/calendar/calendar-week/calendar-week.component';
import { CollapseItemComponent } from '@app/shared/components/collapse-item/collapse-item.component';
import { ContactsViewComponent } from '@app/shared/components/contacts-view/contacts-view.component';
import { ErrorFlashbagComponent } from '@app/shared/components/error-flashbag/error-flashbag.component';
import { FlagMenuComponent } from '@app/shared/components/flag-menu/flag-menu.component';
import { InfoRowComponent } from '@app/shared/components/info-row/info-row.component';
import { MediaLinksComponent } from '@app/shared/components/media-links/media-links.component';
import { PaymentMethodEditorComponent } from '@app/shared/components/payment-method-editor/payment-method-editor.component';
import { PictureSelectorComponent } from '@app/shared/components/picture-selector/picture-selector.component';
import { RatingFullStarComponent } from '@app/shared/components/rating-full-star/rating-full-star.component';
import { RatingHalfStarComponent } from '@app/shared/components/rating-half-star/rating-half-star.component';
import { RatingComponent } from '@app/shared/components/rating/rating.component';
import { ServicePublishAgreementComponent } from '@app/shared/components/service-publish-agreement/service-publish-agreement.component';
import { SuccessFlashbagComponent } from '@app/shared/components/success-flashbag/success-flashbag.component';
import { HintDirective, TrimOnBlurDirective } from '@app/shared/directives';
import {
    AsStringPipe,
    CategoryByIdPipe,
    CityByIdPipe,
    CountryByIdPipe,
    DeclensionUniversalPipe,
    DefaultIfEmptyPipe,
    MasterByIdPipe,
    RegionByIdPipe,
    ServiceByIdPipe,
    ServiceDataFilterPipe,
    StatusTimePipe,
    SubcategoryByIdPipe,
    SubregionByIdPipe,
    SubstringFilterPipe,
} from '@app/shared/pipes';
import { UIModule } from '@app/shared/ui/ui.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { IonicSelectableModule } from 'ionic-selectable';
import { ClipboardModule } from 'ngx-clipboard';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShortenComponent } from '@app/shared/components/shorten/shorten.component';
import { NgStringPipesModule } from 'ngx-pipes';
import { LocalizedDatePipe } from '@app/shared/pipes/trans-date.pipe';
import { DatetimeIntervalViewerComponent } from '@app/shared/components/datetime-interval-viewer/datetime-interval-viewer.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { SearchResultCardComponent } from '@app/shared/components/search-result-card/search-result-card.component';
import { ServiceLinkSearchCardComponent } from '@app/shared/components/service-link-search-card/service-link-search-card.component';
import { BottomBtnComponent } from '@app/shared/components/booking-bottom-btn/bottom-btn.component';
import { MasterProfileServiceEditComponent } from '@app/shared/components/master-profile-service-edit/master-profile-service-edit.component';
import { TypeaheadComponent } from '@app/shared/components/searchbar-typeahead/typeahead/typeahead.component';
import { CommaConvertPipe } from '@app/shared/pipes/comma-convert.pipe';
import { ImageUploadComponent } from '@app/shared/components/image-upload/image-upload.component';
import { SwitchSelectorComponent } from '@app/shared/components/switch-selector/switch-selector.component';
import { SearchResultComponent } from '@app/shared/search-result/search-result.component';
import { TrimBlurDirective } from '@app/shared/directives/trim-blur.directive';
import { SchedulePageComponent } from '@app/shared/components/schedule-editor/schedule-page/schedule-page.component';
import { TimePickerComponent } from '@app/shared/components/schedule-editor/editor/time-picker/time-picker.component';
import { OnlyNumberDirective } from '@app/shared/directives/only-number.directive';
import { SpellCheckerComponent } from '@app/shared/components/spell-checker/spell-checker.component';
import { LocationListComponent } from '@app/shared/components/location-list/location-list.component';
import { CategoryGroupComponent } from '@app/shared/components/category-group/category-group.component';
import { CustomDatetimeComponent } from '@app/shared/components/custom-datetime/custom-datetime.component';
import { AccessToJobsBoardComponent } from '@app/shared/banners/access-to-jobs-board/access-to-jobs-board.component';
import { BannersModule } from '@app/shared/banners/banners.module';
import { PlaceSelectorWrapperComponent } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-wrapper.component';
import { ProfessionalPlaceSelectorComponent } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/professional-place-selector/professional-place-selector.component';
import { UserPlaceSelectorComponent } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/user-place-selector/user-place-selector.component';
import { PlaceSelectorDirective } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/place-selector.directive';
import { PlaceSelectorComponent } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/place-selector/place-selector.component';
import { ContactsWindowComponent } from '@app/shared/components/search-result-card/contacts-window/contacts-window.component';
import { ServicesApiCache } from '@app/core/services/cache';
import { ChatButtonComponent } from './components/chat-button/chat-button.component';
import { ContactEditComponent } from './components/contact-edit/contact-edit.component';
import { ContactsEditComponent } from './components/contacts-edit/contacts-edit.component';
import { DatePickerPopoverComponent } from './components/date-picker-popover/date-picker-popover.component';
import { FormControlErrorComponent } from './components/form-control-error/form-control-error.component';
import { HintPopoverComponent } from './components/hint-popover/hint-popover.component';
import { LoadingErrorComponent } from './components/loading-error/loading-error.component';
import { ImageCropPopoverComponent } from './components/picture-selector/image-cropper/image-crop-popover.component';
import { SavedProfessionalToggleComponent } from './components/saved-professional-toggle/saved-professional-toggle.component';
import { ServicePhotosComponent } from './components/service-photos/service-photos.component';
import { DebounceDirective } from './directives/debounce.directive';
import { IonImageSpinnerDirective } from './directives/ion-image-spinner.directive';
import { IonImageViewSliderDirective } from './directives/ion-image-view-slider.directive';
import { IonImageViewDirective } from './directives/ion-image-view.directive';
import { NumberDirective } from './directives/number.directive';
import { IF_SPINNER_MODULE_CONFIG_TOKEN } from './if-spinner/if-spinner.config';
import { IfSpinnerModule } from './if-spinner/if-spinner.module';
import { FieldIsEmptyPipe } from './pipes/field-is-empty.pipe';
import { ContactFilterPipe } from './pipes/contact-filter.pipe';
import { PriceInputDirective } from './directives/price-input.directive';
import { ReplacePipe } from './pipes/replace.pipe';
import { SpellCheckerDirective } from './directives/spell-checker.directive';
import { FullDateTimePipe } from './pipes/full-date-time.pipe';
import { OnlyLongDatePipe } from './pipes/only-long-date.pipe';
import { AccessToJobsBoardDirective } from './directives/access-to-jobs-board.directive';
import { SafeUrlPipe } from './pipes/safe-pipe/safe-url.pipe';

const components = [
    AddButtonComponent,
    ErrorFlashbagComponent,
    PictureSelectorComponent,
    MediaLinksComponent,
    HeaderComponent,
    FooterComponent,
    MainMenuComponent,
    CollapseItemComponent,
    InfoRowComponent,
    ServicePublishAgreementComponent,
    ContactsViewComponent,
    RatingComponent,
    RatingFullStarComponent,
    RatingHalfStarComponent,
    DurationEditorComponent,
    ImageCropPopoverComponent,
    ServiceWidgetComponent,
    MoreInfoComponent,
    ProfessionalCardComponent,
    FlagMenuComponent,
    CalendarViewComponent,
    OrderDetailsComponent,
    ClientWidgetComponent,
    ScheduleEditorComponent,
    DaySelectorComponent,
    PriceComponent,
    ScheduleViewerComponent,
    DurationViewerComponent,
    FormControlErrorComponent,
    SuccessFlashbagComponent,
    ServicePhotosComponent,
    PhotoPopoverComponent,
    LocationViewerComponent,
    ContentWrapperComponent,
    ServiceLocationComponent,
    LoadingIndicatorComponent,
    LoadingErrorComponent,
    PaymentMethodViewerComponent,
    PaymentMethodEditorComponent,
    PriceEditorComponent,
    GenderSelectorComponent,
    ServiceTitleComponent,
    ColumnHeaderComponent,
    DateIntervalEditorComponent,
    NotFoundPageComponent,
    LanguageComponent,
    SavedProfessionalToggleComponent,
    PhoneEditorComponent,
    ImageCarouselComponent,
    CountryFlagComponent,
    ContactEditComponent,
    ContactsEditComponent,
    ChatButtonComponent,
    HintComponent,
    HintPopoverComponent,
    CategorySelectorComponent,
    SubcategorySelectorComponent,
    SectionHeadingComponent,
    AvatarComponent,
    ServiceTagsViewerComponent,
    ServiceTagsEditorComponent,
    PlaceholderComponent,
    CalendarMonthComponent,
    CalendarWeekComponent,
    DatePickerPopoverComponent,
    ShortenComponent,
    DatetimeIntervalViewerComponent,
    SearchResultCardComponent,
    ContactsWindowComponent,
    ServiceLinkSearchCardComponent,
    BottomBtnComponent,
    MasterProfileServiceEditComponent,
    SearchbarTypeaheadComponent,
    TypeaheadComponent,
    PresenceIndicatorComponent,
    ImageUploadComponent,
    SwitchSelectorComponent,
    SearchResultComponent,
    SchedulePageComponent,
    TimePickerComponent,
    PlaceSelectorComponent,
    SpellCheckerComponent,
    LocationListComponent,
    CategoryGroupComponent,
    CustomDatetimeComponent,
];

const directives = [
    DebounceDirective,
    NumberDirective,
    IonImageSpinnerDirective,
    IonImageViewDirective,
    IonImageViewSliderDirective,
    HintDirective,
    TrimOnBlurDirective,
    PriceInputDirective,
    TrimBlurDirective,
    OnlyNumberDirective,
    SpellCheckerDirective,
];

const pipes = [
    ServiceByIdPipe,
    MasterByIdPipe,
    ServiceDataFilterPipe,
    CountryByIdPipe,
    ContactFilterPipe,
    SubstringFilterPipe,
    CityByIdPipe,
    CategoryByIdPipe,
    SubcategoryByIdPipe,
    AsStringPipe,
    DefaultIfEmptyPipe,
    RegionByIdPipe,
    SubregionByIdPipe,
    LocalizedDatePipe,
    FieldIsEmptyPipe,
    StatusTimePipe,
    CommaConvertPipe,
    ReplacePipe,
    DeclensionUniversalPipe,
    FullDateTimePipe,
    OnlyLongDatePipe,
    SafeUrlPipe,
];

@NgModule({
    declarations: [
        ...components,
        ...directives,
        ...pipes,
        AccessToJobsBoardComponent,
        AccessToJobsBoardDirective,
        PlaceSelectorWrapperComponent,
        UserPlaceSelectorComponent,
        ProfessionalPlaceSelectorComponent,
        PlaceSelectorDirective,
        PlaceSelectorComponent
    ],
    exports: [
        ...components,
        ...directives,
        ...pipes,
        IfSpinnerModule,
        AccessToJobsBoardComponent,
        PlaceSelectorWrapperComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        IonicSelectableModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        UIModule,
        ImageCropperModule,
        IfSpinnerModule,
        NgStringPipesModule,
        GalleryModule,
        ClipboardModule,
        BannersModule,
    ],
    providers: [
        {
            provide: IF_SPINNER_MODULE_CONFIG_TOKEN,
            useValue: {
                errorComponent: LoadingErrorComponent,
                loadingComponent: LoadingIndicatorComponent,
            },
        },
        ServicesApiCache,
        DatePipe,
        DeclensionUniversalPipe
    ],
})
export class SharedModule { }
