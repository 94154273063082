<ng-container *ngFor="let dayForm of controls; let dayIndex = index">
    <ng-container [formGroup]="dayForm">
        <div class="date-row">
            <ion-item (click)="onSelectionChange($event, dayForm)" lines="none" class="label-item"
                      [disabled]="checkScheduleLength(dayForm)">
                <ion-checkbox [checked]="dayForm.enabled" slot="start"></ion-checkbox>
                <ion-label slot="start" class="ion-padding-horizontal">
                    {{ 'global.weekdays.' + getDayByIndex(dayForm.controls[formFields.Day].value) | translate }}
                </ion-label>

                <ion-label *ngIf="dayForm.controls[formFields.Day].errors">
                    {{ dayForm.controls[formFields.Day].errors }}
              </ion-label>
            </ion-item>
            <ion-item
                lines="none"
                class="date-item"
                [ngClass]="{'time-disable': !dayForm.enabled}"
                (click)="showTimeSelector(dayForm, formFields.StartTime)"
            >
                <ion-input
                    class="date-field"
                    [value]="dayForm.value[formFields.StartTime]"
                    [formControlName]="formFields.StartTime"
                    [readonly]="true"
                ></ion-input>
            </ion-item>
            <ion-item
                lines="none"
                class="date-item"
                [ngClass]="{'time-disable': !dayForm.enabled}"
                (click)="showTimeSelector(dayForm, formFields.EndTime)"
            >
                <ion-input
                    class="date-field"
                    [value]="dayForm.value[formFields.EndTime]"
                    [formControlName]="formFields.EndTime"
                    [readonly]="true"
                ></ion-input>
            </ion-item>
        </div>
        <app-form-control-error [controlName]="formFields.StartTime"></app-form-control-error>
        <app-form-control-error [controlName]="formFields.EndTime"></app-form-control-error>
        <app-form-control-error [control]="dayForm"></app-form-control-error>
    </ng-container>
</ng-container>
<span class="error-desc ion-padding-horizontal" *ngIf="timetable.errors">{{ 'form-errors.time-overlap' | translate }}</span>
<ion-popover trigger="date" >
    <ng-template>
        <ion-datetime presentation="time" ></ion-datetime>
    </ng-template>
</ion-popover>