import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';
import { ru, de, arSA, el, es, fr } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';

const BASE_UNIT_TIME = 60000;
const LOWER_UNIT = 0;
const UPPER_UNIT = 43200;

const LOCALE_SOURCE = (name: string): Locale | null => (
    {
        ru: ru,
        de: de,
        ar: arSA,
        es: es,
        fr: fr,
        el: el
    }[name] ?? null);

@Pipe({
    name: 'statusTime',
    pure: true,
})

export class StatusTimePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}
    public transform(lastSeen: string, prefix: string = '', capitalize: boolean = true): string {
        if (!lastSeen) {
            return '';
        }

        const lastSeenDate = new Date(lastSeen);
        const now = Date.now();
        const lastingMinutes = Math.floor((now - lastSeenDate.getTime())/BASE_UNIT_TIME);
        let status: string;

        if (lastingMinutes <= LOWER_UNIT) {
            status = `${this.translateService.instant('presence-indicator.recently')}`;
        } else if (lastingMinutes < UPPER_UNIT) {
            status = `${formatDistance(lastSeenDate, now, {
                addSuffix: true,
                locale: LOCALE_SOURCE(this.translateService.currentLang)})}`;
        } else {
            status = `${this.translateService.instant('presence-indicator.long-ago')}`;
        }

        if (prefix) {
            status = `${prefix} ${status}`;
        }

        if (capitalize){
            status = status.charAt(0).toUpperCase() + status.slice(1);
        }

        return status;
    }
}
