import { ScheduleUnion } from '../models/schedule-union';

export const defaultWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const defaultDay: ScheduleUnion = {
    day_of_week: 0,
    is_enabled: true,
    start_time: '09:00',
    end_time: '17:00',
    // @ts-ignore
    id: null
};

const defaultFullDay: ScheduleUnion = {
    day_of_week: 0,
    is_enabled: true,
    start_time: '00:00',
    end_time: '23:59',
    // @ts-ignore
    id: null
};

export const defaultSchedule: ScheduleUnion[] = [
    {
        ...defaultDay,
        day_of_week: 0,
    },
    {
        ...defaultDay,
        day_of_week: 1,
    },
    {
        ...defaultDay,
        day_of_week: 2,
    },
    {
        ...defaultDay,
        day_of_week: 3,
    },
    {
        ...defaultDay,
        day_of_week: 4,
    }
];

export const defaultFullDaySchedule: ScheduleUnion[] = [
    {
        ...defaultFullDay,
        day_of_week: 0,
    },
    {
        ...defaultFullDay,
        day_of_week: 1,
    },
    {
        ...defaultFullDay,
        day_of_week: 2,
    },
    {
        ...defaultFullDay,
        day_of_week: 3,
    },
    {
        ...defaultFullDay,
        day_of_week: 4,
    },
    {
        ...defaultFullDay,
        day_of_week: 5,
    },
    {
        ...defaultFullDay,
        day_of_week: 6,
    }
];
