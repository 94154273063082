import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import { NgDestroyService } from '@app/core/services';
import { debounceTime, filter, map, pairwise, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { TypeaheadService } from '@app/api/services/typeahead.service';
import { FormControl } from '@angular/forms';
import { NavPath } from '@app/core/constants/navigation.constants';


const inactiveTypeaheadPages = [
    NavPath.Support,
];

@Component({
    selector: 'app-searchbar-typeahead',
    templateUrl: './searchbar-typeahead.component.html',
    styleUrls: ['./searchbar-typeahead.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [NgDestroyService]
})
export class SearchbarTypeaheadComponent implements OnInit {
    public searchButtonClass = 'default-search-button';
    public typeaheadList = [];
    public viewList: boolean = false;
    public query: FormControl = new FormControl('');
    public typeaheadPermitted: boolean;

    @Input() public innerClass = '';
    @Input() public debounceTime: number = 250;
    @Output() public search = new EventEmitter<string>();

    @Input() private readonly updateListOnClearSearch: boolean = false;
    @Input() private readonly pagename: string = 'main-page';

    constructor(
        public readonly cd: ChangeDetectorRef,
        private readonly typeaheadService: TypeaheadService,
        private readonly destroy$: NgDestroyService,
        router: Router,
    ) {
        this.typeaheadPermitted = !!!inactiveTypeaheadPages.find(el => router.url.includes(el));
    }

    public ngOnInit(): void {
        this.query.valueChanges
            .pipe(
                startWith(''),
                pairwise(),
                filter(([prev, next]) => {
                    const hasPrevious = !!prev;
                    const hasCurrent = !!next;

                    if (hasPrevious !== hasCurrent) {
                        this.setButtonColor();
                    }

                    if (!hasCurrent) {
                        this.updateTypeaheadList([]);
                    }

                    return hasCurrent;
                }),
                debounceTime(this.debounceTime),
                switchMap(([prev, query]) => this.typeaheadService.typeaheadListResponse({ needle: query })),
                map((data) => data.body.map(value => value.result)),
                takeUntil(this.destroy$)
            )
            .subscribe((result) => this.updateTypeaheadList(result));
    }

    public setPlaceholder(): string {
        return `${this.pagename}.search-placeholder`;
    }

    public setQuery(query): void {
        this.query.setValue(query, { emitEvent: false });
        if (this.innerClass === 'searchbar-without-icon') {
            this.search.emit(query);
        }
    }

    public updateTypeaheadList(typeaheadList: string[]): void {
        this.typeaheadList = typeaheadList;
        this.hasViewList(!!typeaheadList?.length);
    }

    public searchByClick(): void {
        this.hasViewList(false);
        this.search.emit(this.query.value);
    }

    public clearSearch(): void {
        if (this.updateListOnClearSearch) {
            this.search.emit(null);
        };
    }

    public hasViewList(isView: boolean): void {
        this.viewList = isView;
        this.cd.markForCheck();
    }

    private setButtonColor(): void {
        this.searchButtonClass = this.query.value.length ? 'active-search-button' : 'default-search-button';
        this.cd.markForCheck();
    }
}
