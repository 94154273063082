import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

export class JsonTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<any> {
        if (!lang) {
            return of();
        }

        return fromPromise(import(`../../../assets/i18n/${lang}.json`));
    }
}
