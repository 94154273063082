import { Inject, Injectable } from '@angular/core';
import { NavigationService } from '@app/core/services/navigation.service';
import { once } from '@app/core/decorators/once';
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';
import { AnalyticsServiceInterface } from '@app/core/interfaces/analytics-service-interface';

declare let gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService implements AnalyticsServiceInterface {

    constructor(private readonly nav: NavigationService, @Inject(DOCUMENT) private readonly doc: any) {
    }

    @once
    public init(): void {
        if (environment.ga_id) {
            const s = this.doc.createElement('script');
            s.type = 'text/javascript';
            s.src = `https://www.googletagmanager.com/gtag/js?id=${ environment.ga_id }`;
            s.setAttribute('async', '');
            const head = this.doc.getElementsByTagName('head')[0];
            head.appendChild(s);

            const ss = this.doc.createElement('script');
            ss.type = 'text/javascript';
            ss.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag(\'js\', new Date());
            gtag(\'config\', '${  environment.ga_id  }');`;
            head.appendChild(ss);

            this.nav.currentUrl.subscribe(
                u => gtag('config', environment.ga_id, { page_path: u })
            );
        }
    }

    public enabled(): boolean {
        return typeof gtag === 'function' && !!environment.ga_id;
    }

    public sendNotFoundErrorEvent(): void {
        gtag('event', '404_error', { event_category: 'page', event_action: '404_error' });
    }

    public sendOneMinuteActivityEvent(): void {
        gtag('event', 'activ_60', { event_category: 'activ_60', event_action: 'done' });
    }

    public sendRegisterEvent(): void {
        gtag('event', 'registration_done', { event_category: 'registration', event_action: 'done' });
    }

    public sendServicePublishEvent(): void {
        gtag('event', 'publication_usluga', { event_category: 'publication', event_action: 'usluga' });
    }
}
