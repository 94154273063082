// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { City, Country } from '@app/api/models';
import { Observable } from 'rxjs';
import { AbstractIpService, GeolocationData } from '@app/core/services/location/abstract-ip-service';
import {
    ApiIpdataCo,
    ExtremeIpLookupCom,
    IpApiCo,
    IpApiCom,
    IpNf,
    IpwhoisApp,
} from '@app/core/services/location/interfaces';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { LocationService } from '@app/api/services/location.service';

@Injectable()
export class GuessLocationByApiIpdataCo extends AbstractIpService {
    constructor(protected http: HttpClient, protected api: LocationService){
        super(http, api);
    };

    public getGeolocationData(data: ApiIpdataCo): GeolocationData {
        if (!data || !data.city || !data.country_code){
            throw new Error();
        }

        return { city: data.city, countryCode: data.country_code };
    }

    public guess(): Observable<{ country: Country; city: City }> {
        return super.extractData<ApiIpdataCo>(
            this.http.get<ApiIpdataCo>(environment.geolocationServiceUrl.ApiIpdataCo));
    }
}

@Injectable()
export class GuessLocationByExtremeIpLookupCom extends AbstractIpService {

    constructor(protected http: HttpClient, protected api: LocationService){
        super(http, api);
    };

    public getGeolocationData(data: ExtremeIpLookupCom): GeolocationData {
        if (!data || !data.city || !data.countryCode){
            throw new Error();
        }

        return { city: data.city, countryCode: data.countryCode };
    }

    public guess(): Observable<{ country: Country; city: City }> {
        return super.extractData(
            this.http.get<ExtremeIpLookupCom>(environment.geolocationServiceUrl.ExtremeIpLookupCom));
    }
}

@Injectable()
export class GuessLocationByIpApiCo extends AbstractIpService {

    constructor(protected http: HttpClient, protected api: LocationService){
        super(http, api);
    };

    public getGeolocationData(data: IpApiCo): GeolocationData {
        if (!data || !data.city || !data.country_code){
            throw new Error();
        }

        return { city: data.city, countryCode: data.country_code };
    }

    public guess(): Observable<{ country: Country; city: City }> {
        return super.extractData(
            this.http.get<IpApiCo>(environment.geolocationServiceUrl.IpApiCo));
    }
}

@Injectable()
export class GuessLocationByIpApiCom extends AbstractIpService {

    constructor(protected http: HttpClient, protected api: LocationService){
        super(http, api);
    };

    public getGeolocationData(data: IpApiCom): GeolocationData {
        if (!data || !data.city || !data.countryCode){
            throw new Error();
        }

        return { city: data.city, countryCode: data.countryCode };
    }

    public guess(): Observable<{ country: Country; city: City }> {
        return super.extractData(
            this.http.get<IpApiCom>(environment.geolocationServiceUrl.IpApiCom));
    }
}

@Injectable()
export class GuessLocationByIpNf extends AbstractIpService {

    constructor(protected http: HttpClient, protected api: LocationService){
        super(http, api);
    };

    public getGeolocationData(data: IpNf): GeolocationData {
        if (!data || !data.ip.city || !data.ip.country_code){
            throw new Error();
        }

        return { city: data.ip.city, countryCode: data.ip.country_code };
    }

    public guess(): Observable<{ country: Country; city: City }> {
        return super.extractData(
            this.http.get<IpNf>(environment.geolocationServiceUrl.IpNf));
    }
}

@Injectable()
export class GuessLocationByIpwhoisApp extends AbstractIpService {

    constructor(protected http: HttpClient, protected api: LocationService){
        super(http, api);
    };

    public getGeolocationData(data: IpwhoisApp): GeolocationData {
        if (!data || !data.city || !data.country_code){
            throw new Error();
        }

        return { city: data.city, countryCode: data.country_code };
    }

    public guess(): Observable<{ country: Country; city: City }> {
        return super.extractData(
            this.http.get<IpwhoisApp>(environment.geolocationServiceUrl.IpwhoisApp));
    }
}

@Injectable()
export class IpServiceList {

    public services: Observable<{ country: Country; city: City }>[] = [];

    constructor(
        private readonly guessLocationByApiIpdataCo: GuessLocationByApiIpdataCo,
        private readonly guessLocationByExtremeIpLookupCom: GuessLocationByExtremeIpLookupCom,
        private readonly guessLocationByIpApiCo: GuessLocationByIpApiCo,
        private readonly guessLocationByIpApiCom: GuessLocationByIpApiCom,
        private readonly guessLocationByIpNf: GuessLocationByIpNf,
        private readonly guessLocationByIpwhoisApp: GuessLocationByIpwhoisApp,
        ) {
        this.services.push(this.guessLocationByIpwhoisApp.guess());
        this.services.push(this.guessLocationByApiIpdataCo.guess());
        this.services.push(this.guessLocationByExtremeIpLookupCom.guess());
        this.services.push(this.guessLocationByIpApiCo.guess());
        this.services.push(this.guessLocationByIpApiCom.guess());
        this.services.push(this.guessLocationByIpNf.guess());
    }
}
