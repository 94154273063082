import { Injectable } from '@angular/core';
import { GoogleAnalyticsComService } from '@app/core/services/analytics/google-analytics-com.service';
import { YandexAnalyticsService } from '@app/core/services/analytics/yandex-analytics.service';
import { GoogleAnalyticsService } from '@app/core/services/analytics/google-analytics.service';
import { once } from '@app/core/decorators/once';
import { timer } from 'rxjs';
import { environment } from '@env/environment';
import { PixelService } from '@app/core/services/analytics/pixel.service';
import { ClarityService } from '@app/core/services/analytics/clarity.service';
import { configuration } from '@conf/configuration';
import { Analytics } from '@app/api/models/analytics';

export enum AnalyticEventsEnum {
    RegisterEvent = 'registration',
    NotFoundErrorEvent = '404_error',
    OneMinuteActivityEvent = 'activ_60',
    ServicePublishEvent = 'servicePublishSuccessful',
    OpenStepOneEvent = 'form_usluga_open',
    CompleteStepOneEvent = 'step1',
    CompleteStepTwoEvent = 'step2',
    CompleteStepThreeEvent = 'step3',
    CompleteStepFourEvent = 'step4',
    CompleteStepFiveEvent = 'step5',
    CompleteStepSixEvent = 'step6',
    CompleteStepSevenEvent = 'step7',
    CompleteStepEightEvent = 'step8',
    CompleteStepFinalEvent = 'step9',
    OneMoreServiceEvent = 'onemore',

    JobPublication = 'jobPublication',
    RespondToJob = 'respondToJob',
    BookServiceSuccessful = 'bookServiceSuccessful',
    BookServiceInit = 'bookServiceInit',
    Share = 'share',
    SearchServices = 'searchServices',
    SearchJobs = 'searchJobs',
    Chat = 'chat',
    Repeat = 'repeat',
    ProfessionalProfile = 'professionalProfile',
    AboutInbox = 'aboutInbox',
    AboutOutBox = 'aboutOutBox',
    ProfessionalContacts = 'professionalContacts',
}


@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(
        private readonly ya: YandexAnalyticsService,
        private readonly ga: GoogleAnalyticsService,
        private readonly ga_com: GoogleAnalyticsComService,
        private readonly pixel: PixelService,
        private readonly clarity: ClarityService,
        ) {
    }

    @once
    public init(): void {
        // @ts-ignore TODO: fix this
        if (configuration.analytics.includes(Analytics.Pixel)) {
            this.pixel.init();
        }
        // @ts-ignore
        if (configuration.analytics.includes(Analytics.Clarity)) {
            this.clarity.init();
        }
        // @ts-ignore
        if (configuration.analytics.includes(Analytics.YandexAnalytics)) {
            this.ya.init();
        }
        // @ts-ignore
        if (configuration.analytics.includes(Analytics.GoogleAnalytics)) {
            this.ga.init();
        }
        // @ts-ignore
        if (configuration.analytics.includes(Analytics.GoogleAnalyticsCom)) {
            this.ga_com.init();
        }
            timer(environment.analytics_activity_ms).subscribe(
                () => this.track(AnalyticEventsEnum.OneMinuteActivityEvent));
    }

    public track(event: AnalyticEventsEnum): void {
        this.ya.sendEvent(event);
        if (this.ga.enabled()) {
            switch (event) {
                case AnalyticEventsEnum.RegisterEvent:
                    this.ga.sendRegisterEvent();
                    break;
                case AnalyticEventsEnum.NotFoundErrorEvent:
                    this.ga.sendNotFoundErrorEvent();
                    break;
                case AnalyticEventsEnum.OneMinuteActivityEvent:
                    this.ga.sendOneMinuteActivityEvent();
                    break;
                case AnalyticEventsEnum.ServicePublishEvent:
                    this.ga.sendServicePublishEvent();
                    break;
                default:
                    break;
            }
        }
    }
}
