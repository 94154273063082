import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-bottom-btn',
    templateUrl: './bottom-btn.component.html',
    styleUrls: ['./bottom-btn.component.scss'],
})
export class BottomBtnComponent {
    @Input() public disabled: boolean = false;
    @Input() public transKey: string;
    @Input() public showBackBtn = false;
    @Input() public showFooter = true;
    @Output() public clicked = new EventEmitter<void>();
    @Output() public back = new EventEmitter<void>();

    public confirm(): void {
        this.clicked.emit(void 0);
    }

    public backCLick(): void {
        this.back.next(void 0);
    }
}
