import { Injectable } from '@angular/core';
import { StorageManagerService } from '@app/core/services/storage-manager.service';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, shareReplay } from 'rxjs/operators';

const DARK_MODE_STORAGE_KEY = 'is_dark_mode';

@Injectable()
export class DarkModeService {
    public darkTheme$: Observable<boolean>;

    private readonly darkThemeSubject = new BehaviorSubject(false); /** TO DO remove after preparing dark theme **/

    constructor(private readonly storage: StorageManagerService) {
        this.darkTheme$ = concat(this.isDarkMode(), this.darkThemeSubject).pipe(shareReplay(1));
    }

    public setMode(isDark: boolean): void {
        this.storage.set(DARK_MODE_STORAGE_KEY, isDark).then(() => this.darkThemeSubject.next(isDark));
    }

    private isDarkMode(): Observable<boolean> {
        return fromPromise(this.storage.get(DARK_MODE_STORAGE_KEY)).pipe(map(Boolean));
    }
}
