import { Component, Input, OnInit } from '@angular/core';
import { UserLocation } from '@app/api/models/user-location';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent {
    @Input() public label = false;
    @Input() public locations: UserLocation[] = [];
}
