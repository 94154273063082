<ion-row class="smooth-corners searchbar-container">
    <ion-searchbar
        class="search-form_search-bar smooth-corners searchbar-no-inner-paddings"
        [ngClass]="innerClass"
        [debounce]="0"
        search-icon="construct-outline"
        show-clear-button="always"
        [formControl]="query"
        [placeholder]="setPlaceholder() | translate"
        (ionClear)="clearSearch()"
        (focusin)="hasViewList(!!typeaheadList?.length)"
        (keyup.escape)="updateTypeaheadList([])"
        (keydown.enter)="search.emit(query.value)"
    >
        <ion-button 
            *ngIf="innerClass === 'searchbar-without-icon'" 
            [ngClass]="searchButtonClass" 
            (click)="searchByClick()"
        >
            <ion-icon name="search"></ion-icon>
        </ion-button>
    </ion-searchbar>
    <ng-container *ngIf="typeaheadPermitted">
        <app-typeahead  *ngIf="viewList"
            [typeaheadList]="typeaheadList"
            (query)="setQuery($event)"
            (hideList)="hasViewList(false)">
        </app-typeahead>
    </ng-container>
</ion-row>
