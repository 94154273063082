<ion-segment (ionChange)="change($event)" [value]="defaultValue" [disabled]="disabled">
    <ion-segment-button [value]="0">
        <ion-icon name="man-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button [value]="1">
        <ion-icon name="woman-outline"></ion-icon>
    </ion-segment-button>
    <ion-segment-button [value]="null">
        <ion-label>{{ 'global.not-specified' | translate }}</ion-label>
    </ion-segment-button>
</ion-segment>
