import { Component, Input } from '@angular/core';
import { Service } from '@app/api/models/service';
import { NavQueryParams } from '@app/core/constants/navigation.constants';
import { getServiceUrl } from '@app/core/functions/navigation.functions';

@Component({
  selector: 'app-service-link-search-card',
  templateUrl: './service-link-search-card.component.html',
  styleUrls: ['./service-link-search-card.component.scss'],
})
export class ServiceLinkSearchCardComponent {
  @Input() public service: Service;
  @Input() public preview = false;

  public queryParams = { [NavQueryParams.goBack]: true };

  public get serviceUrl(): string {
    return getServiceUrl(this.service.id);
  }
}
