import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-presence-indicator',
    templateUrl: './presence-indicator.component.html',
    styleUrls: ['presence-indicator.component.scss']
})

export class PresenceIndicatorComponent {
    @Input() public isOn: boolean = false;
    @Input() public lastSeen!: string;
}