<ion-item>
    <ion-label class="label-for-required-input" color="dark" position="floating" *ngIf="!!title; else empty">{{ title}}</ion-label>
    <ng-template  #empty>
    <ion-label class="label-for-required-input" color="dark" position="floating">
        {{ 'client-applications.main-information.select-service-category' | translate }}</ion-label>
    </ng-template>

    <ionic-selectable
        #ionicSelectableComponent
        (ngModelChange)="setValue($event)"
        [(ngModel)]="model"
        [canClear]="true"
        [canSearch]="true"
        [clearButtonText]="'global.clear' | translate"
        [closeButtonText]="'global.cancel' | translate"
        [hasVirtualScroll]="true"
        [isEnabled]="isEnabled"
        [items]="subcategories"
        [searchFailText]="'ion-selectable.search-fail-text' | translate"
        [searchPlaceholder]="'global.titles.search' | translate"
        [virtualScrollHeaderFn]="getGroupText"
        item-content
        itemTextField="name"
        itemValueField="id"
    >
        <ng-template ionicSelectableItemTemplate let-subcategory="item" >
            <span [attr.data-testid]="subcategory.id">
                <ion-label>{{ subcategory.name }}</ion-label>
            </span>
        </ng-template>
    </ionic-selectable>
</ion-item>
