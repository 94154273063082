import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import UserLocationSelectors from '@app/store/current-user/user-locations/user-locations.selectors';
import { Observable } from 'rxjs';
import { ResolvedUserLocation } from '@app/core/interfaces/user-location.interface';
import { UserLocation } from '@app/api/models/user-location';
import { LocationResolverService } from '@app/core/services';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AutocompleteLocationService {

    @Select(UserLocationSelectors.guessedResolvedLocation)
    private readonly guessedResolvedLocation: Observable<ResolvedUserLocation>;

    @Select(UserLocationSelectors.locations)
    private readonly savedLocations: Observable<UserLocation[]>;

    constructor(private readonly locationResolver: LocationResolverService) {
    }

    public get(): Observable<ResolvedUserLocation | null> {
        return this.savedLocations.pipe(
            first(),
            switchMap(locations => {
                if (locations.length) {
                    return this.locationResolver.resolveLocation(this.getDefaultLocation(locations) ?? locations[0]);
                }

                return this.guessedResolvedLocation.pipe(
                    first(l => l?.country !== undefined),
                );
            }),
        );
    }

    private getDefaultLocation(locations: UserLocation[]): UserLocation | undefined {
        return locations.find(l => l.is_default);
    }
}
