import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { DarkModeService } from '@app/core/services/user-interface/dark-mode.service';
import { MasterManagerService } from '@app/core/services/managers/master-manager.service';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { UserSettingsService } from '@app/core/services/facades';
import { filter, map } from 'rxjs/operators';
import { SEOService } from '@app/service/seo/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuController, Platform } from '@ionic/angular';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { NavigationEnd, Router } from '@angular/router';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/core/operators/take-until-destroyed';
import LoaderSelectors from './store/loader/loader.selectors';

@DecorateUntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    @Select(LoaderSelectors.isLoaderShown)
    public isLoaderShown$!: Observable<boolean>;
    public isAuthenticated$: Observable<boolean>;
    public darkTheme$: Observable<boolean>;
    public isInvert: boolean = false;
    public showPromo$: Observable<boolean>;
    private readonly hidePromo$ = new BehaviorSubject<boolean>(false);


    constructor(
        public readonly masterManager: MasterManagerService,
        public readonly translator: TranslateService,
        private readonly userSettings: UserSettingsService,
        private readonly seo: SEOService,
        authenticator: AuthenticationService,
        darkModeService: DarkModeService,
        private readonly menu: MenuController,
        private readonly platform: Platform,
        store: Store,
        router: Router

    ) {
        this.darkTheme$ = darkModeService.darkTheme$;
        this.isAuthenticated$ = authenticator.isAuthenticated$;
        this.showPromo$ = combineLatest([
            store.select(CurrentUserSelectors.isAuthenticated),
            this.hidePromo$,
            router.events.pipe(filter((e) => e instanceof NavigationEnd))
        ]).pipe(
            map(([isAuthenticated, isHidden, navTo ]) =>
                !isAuthenticated && !isHidden && (navTo as NavigationEnd).url === '/' ),
        );
    }

    public ngOnInit(): void {
        this.userSettings.settings$.pipe(
            map(s => s?.language === 'ar'),
            takeUntilDestroyed(this),
        ).subscribe(show => this.isInvert = show);

        this.translator.onLangChange
            .pipe(takeUntilDestroyed(this))
            .subscribe(() => this.seo.addMetaTags());

        this.menu.swipeGesture(this.platform.is('desktop'),'main-menu').then();
        this.menu.swipeGesture(this.platform.is('desktop'),'flag-menu').then();
    }

    public ngAfterViewInit(): void {
        // this.translator.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => this.seo.addMetaTags());
    }

    public hidePromo(): void {
        this.hidePromo$.next(true);
    }
}
