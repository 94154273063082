import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { environment } from '@env/environment';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { configuration } from '@conf/configuration';

@Injectable()
export class LangInterceptorService implements HttpInterceptor {
    @Select(CurrentUserSelectors.language)
    public readonly language$: Observable<string>;

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        try {
            const url = new URL(req.url);
            if (url.origin !== configuration.backend.url) {
                return next.handle(req);
            }
        } catch (e) {
            return next.handle(req);
        }
        // if (req.method === 'PATCH') {
        //     return next.handle(req);
        // }
        if (this.getExcludedUrls().includes(req.url)) {
            return next.handle(req);
        }

        if (this.getExcludedMedia().find(media => req.url.indexOf(media) > 0)) {
            return next.handle(req);
        }
        if (this.getUserUrls().includes(req.url)) {
            return next.handle(this.getInitRequest(req));
        }

        return this.language$.pipe(
            first(language => Boolean(language)),
            switchMap(language => next.handle(req.clone(this.getRequestData(req, language)))),
        );
    }

    private getInitRequest(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone(this.getRequestData(req));
    }

    private getRequestData(req: HttpRequest<any>, language?: string): {url: string; headers: HttpHeaders} {
        const url = new URL(req.url);
        const lang = language ?? configuration.default_lang;
        const newUrl = `${url.origin}/${lang}${url.pathname}`;
        const headers = req.headers.append('Accept-Language', lang);

        return {url: newUrl, headers};
    }

    private getExcludedUrls(): string[] {
        return [
            configuration.backend.url + environment.backend.auth,
            configuration.backend.url + environment.backend.refresh,
            configuration.backend.url + environment.backend.media,
        ];
    }

    private getExcludedMedia(): string[] {
        return [
            environment.backend.media,
        ];
    }

    private getUserUrls(): string[] {
        return [
            configuration.backend.url + environment.backend.user_settings,
            configuration.backend.url + environment.backend.user,
        ];
    }
}
