import { Injectable } from '@angular/core';
import { Country } from '@app/api/models';
import { LocationService } from '@app/api/services';
import { ApiCache } from '@app/core/services/cache/api-cache.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

const PAGE_SIZE = 250;

@Injectable()
export class CountriesApiCache extends ApiCache<Country> {
    private listCache: Country[] = [];
    constructor(private readonly locationService: LocationService) {
        super();
    }

    public list(): Observable<Country[]> {
        return this.locationService.locationCountriesList({ pageSize: PAGE_SIZE }).pipe(
            map(({ results }) => results),
            tap(list => {
                this.listCache = [...new Set([...list, ...this.listCache])];
            }),
            shareReplay(1));
    }

    public read(id: number): Observable<Country> {
        const country = this.listCache.find(c => c.id === id);
        if(!country) {
            return this.locationService.locationCountriesRead(id).pipe(tap(d=> this.listCache.push(d)));
        }

        return of(country);
    }
}
