import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

export function getErrorListFromHttpErrorResponse(errorList: { [param: string]: string[] | string }): string[] {
    const result: string[] = [];
    for (const errorListElement in errorList) {
        if (errorList.hasOwnProperty(errorListElement)) {
            if (Array.isArray(errorList[errorListElement])) {
                result.push(...errorList[errorListElement]);
            }
            if (typeof errorList[errorListElement] === 'string' || errorList[errorListElement] instanceof String) {
                result.push(errorList[errorListElement] as string);
            }
        }
    }

    return result;
}

// TODO there can be only one
export function getHttpErrorMessages(response: HttpErrorResponse): string[] {
    const error = response.error;
    const all = error.error?.__all__ || error.__all__;

    return Array.isArray(all)
        ? all
        : Array.isArray(error.password)
        ? error.password
        : error.error_description
        ? [error.error_description]
        : Object.entries(error).map(e => `${e[0]}: ${e[1]}`);
}


export function getErrorMessage (maxRetry: number): string {
    return `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;
}

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_BACKOFF = 1000;

export function retryWithBackoff(
    delayMs= DEFAULT_BACKOFF,
    maxRetry = DEFAULT_MAX_RETRIES,
    backoffMs = DEFAULT_BACKOFF): (src: Observable<any>) => Observable<any> {
    let retries = maxRetry;

    return (src: Observable<any>) =>
        src.pipe(
            retryWhen((errors: Observable<any>) => errors.pipe(
                mergeMap(error => {
                    // eslint-disable-next-line no-plusplus
                        if (retries-- > 0) {
                            const backoffTime = delayMs + (maxRetry - retries) * backoffMs;

                            return of(error).pipe(delay(backoffTime));
                        }

                        return throwError(getErrorMessage(maxRetry));
                    }))));
}
