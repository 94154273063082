<ion-datetime
    class="date-field"
    presentation="time"
    [cancelText]="'global.buttons.cancel' | translate"
    [doneText]="'global.buttons.done' | translate"
    [value]="value"
    hourCycle = "h23"
    displayFormat="H:mm"
    (ionChange)="onChange($event)"
    showDefaultButtons
    minute-values="0,15,30,45">
</ion-datetime>