import { AfterViewInit, Component, Input, Renderer2 } from '@angular/core';
import { PhotoModelType, PhotoType } from '@app/core/services/image-load/image-api-service-factory.service';
import { ImageService } from '@app/core/services/image-load/image.service';
import { ModalController, Platform } from '@ionic/angular';
import {
    AdvancedLayout, ButtonEvent, ButtonsConfig, ButtonsStrategy, ButtonType, Description, DescriptionStrategy, Image,
    ImageModalEvent, PlainGalleryConfig, PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery';
import { TranslateService } from '@ngx-translate/core';
import { InternalLibImage } from '@ks89/angular-modal-gallery/lib/model/image-internal.class';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/core/operators/take-until-destroyed';

@DecorateUntilDestroy()
@Component({
    templateUrl: './photo-popover.component.html',
    styleUrls: ['./photo-popover.component.scss'],
})
export class PhotoPopoverComponent implements AfterViewInit {
    @Input() public photos!: PhotoModelType[];
    @Input() public photoType!: PhotoType;
    @Input() public photoIndex: number = 0;
    @Input() public editable: boolean = false;
    @Input() public favoritable: boolean = false;

    public currentFavorite?: boolean;

    public customPlainGalleryRowConfig: PlainGalleryConfig = {
        strategy: PlainGalleryStrategy.CUSTOM,
        layout: new AdvancedLayout(-1, true),
    };

    public buttonsConfigAdvanced: ButtonsConfig = {
        visible: true,
        strategy: ButtonsStrategy.CUSTOM,
        buttons: [
            {
                className: 'close-image inside',
                type: ButtonType.CLOSE,
            },
        ],
    };

    public customDescription: Description = {
        strategy: DescriptionStrategy.HIDE_IF_EMPTY,
        style: {
            bgColor: 'rgba(240,65,65,.2)',
            textColor: '#eaeaea',
        },
    };

    private readonly favButtonClass = 'fav-gallery-photo';

    constructor(
        private readonly modalController: ModalController,
        private readonly platform: Platform,
        private readonly imageService: ImageService,
        private readonly trans: TranslateService,
        private readonly renderer: Renderer2,
    ) {
    }

    public ngAfterViewInit(): void {
        this.customPlainGalleryRowConfig = Object.assign(
            {},
            this.customPlainGalleryRowConfig,
            { layout: new AdvancedLayout(this.photoIndex, true) },
        );
    }

    public onCustomButtonBeforeHook(event: ButtonEvent): void {
        if (!event || !event.button) {
            return;
        }
        if (event.button.type === ButtonType.DELETE) {
            this.delete((event.image as InternalLibImage).id);
        }
        // to favorite
        if (event.button.type === ButtonType.CUSTOM) {
            this.favoriteToggle((event.image as InternalLibImage).id);
        }
    }

    public getImages(): Image[] {
        const res: Image[] = [];
        const clientWidth = this.platform.width();
        const favText = this.trans.instant('photo-edit.favorite');
        this.photos.forEach((p, i) => {
            res.push(new Image(i, {
                img: (clientWidth <= 600 ? p.photo_s : p.photo_l) as string,
                description: p.is_favorite && this.editable ? favText : '',
            }));
        });

        return res;
    }

    /**
     * @deprecated change after update of the package
     */
    public show(event: ImageModalEvent): void {
        const photoIndex = event.result as number - 1;
        const photo = this.photos[photoIndex];
        this.initButtons(photo);
        const el = document.getElementsByClassName(this.favButtonClass)[0];
        if (el) {
            if (photo.is_favorite) {
                this.renderer.addClass(el, 'selected');
            } else {
                this.renderer.removeClass(el, 'selected');
            }
        }
    }

    public close(): void {
        this.modalController.dismiss();
    }

    public async delete(photoIndex: number): Promise<void> {
        this.modalController.dismiss({ delete: true, photoIndex: photoIndex });
    }

    public favoriteToggle(photoIndex: number): void {
        const photo = this.photos[photoIndex];
        this.currentFavorite = !photo.is_favorite;
        if (!photo.is_favorite) {
            this.imageService.setFav(photo, this.photoType, true)
                .pipe(takeUntilDestroyed(this))
                .subscribe(
                    p => this.photos[photoIndex].is_favorite = p?.is_favorite,
                );

            return;
        }

        this.imageService.setFav(photo, this.photoType, false)
            .pipe(takeUntilDestroyed(this))
            .subscribe(
                p => this.photos[photoIndex].is_favorite = p?.is_favorite,
            );
    }

    private initButtons(photo: PhotoModelType): void {
        if (this.editable) {
            this.buttonsConfigAdvanced.buttons?.unshift(
                {
                    className: 'delete-image inside',
                    type: ButtonType.DELETE,
                    title: this.trans.instant('messages.context-menu.delete'),
                    ariaLabel: this.trans.instant('messages.context-menu.delete'),
                },
            );
        }
        if (this.favoritable) {
            let className = this.favButtonClass;
            className += photo.is_favorite ? ' selected' : '';
            this.buttonsConfigAdvanced.buttons?.unshift(
                {
                    className: className,
                    type: ButtonType.CUSTOM,
                    title: this.trans.instant('photo-edit.to-favorites'),
                    ariaLabel: this.trans.instant('photo-edit.to-favorites'),
                },
            );
        }
    }
}
