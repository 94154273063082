import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Price, GeneralOrder, SentOrder, Service } from '@app/api/models';
import { NavPath, NavQueryParams } from '@app/core/constants/navigation.constants';

@Component({
    selector: 'app-service-title',
    templateUrl: './service-title.component.html',
    styleUrls: ['./service-title.component.scss'],
})
export class ServiceTitleComponent implements OnChanges { // TODO: refactor it
    @Input() public service: Partial<Service> = {};
    @Input() public order: SentOrder | GeneralOrder;
    @Input() public link: any[];
    @Input() public activeLink = true;

    public detailsLink: string;
    public orderPrice: Partial<Price>;
    public orderTime: { start_datetime: string; end_datetime: string };
    public queryParams = { [NavQueryParams.goBack]: true };

    public ngOnChanges(changes: SimpleChanges): void {
        const { link, order } = changes;

        if (order?.currentValue) {
            this.orderTime = this.getOrderTime(order.currentValue);
            this.orderPrice = this.getOrderPrice(order.currentValue);
        }

        if (this.activeLink) {
            this.prepareLink(link?.currentValue
                ? link.currentValue
                : ['/', NavPath.Service, order?.currentValue?.service]);
        }
    }

    private prepareLink(linkItems: any[]): void {
        if (!linkItems?.every(item => !!item)) {
            return;
        }

        this.detailsLink = linkItems.join('/');
    }

    private getOrderTime(order: SentOrder | GeneralOrder ): { start_datetime: string; end_datetime: string } {
        return order as { start_datetime: string; end_datetime: string };
    }

    private getOrderPrice(order: SentOrder | GeneralOrder): Partial<Price> {
        return {
            is_price_fixed: true,
            price: this.temporaryCringe(order),
            price_currency: order.price_currency
        };
    }

    private temporaryCringe(order: SentOrder | GeneralOrder): string { // todo after api update
        if ('price' in order) {
            return order.price || 'orders-card-description.agreed-price';
        }
        if ('price_amount' in order) {
            return order.price_amount;
        }

        return null;
    }

    // private priceFromService(): string {
    //     let price = '';
    //     if ('start_price' in this.service.price) {
    //         price += (`${this.service.price.start_price  } - `);
    //     }
    //     if ('end_price' in this.service.price) {
    //         price += this.service.price.end_price;
    //     }
    //
    //     return price;
    // }
}
