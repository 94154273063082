<form [formGroup]="form">
    <ion-row>
        <ion-col class="ion-no-padding" *ngIf="type === 'days'">
            <ion-item lines="none" class="ion-item-bordered">
                <ion-label position="stacked">{{ 'duration.days' | translate }}</ion-label>
                <ion-select interface="popover" label="Default label" [formControlName]="formFields.Days" name="days" type="number" [value]="0">
                    <ion-select-option *ngFor="let item of [].constructor(configuration.servicePublish.dayCount); let i = index" [value]="i">{{ i }} {{ 'duration.d' | translate }}</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-col>
        <ion-col size="6" class="ion-no-padding" *ngIf="type === 'hours'">
            <ion-item lines="none" class="ion-item-bordered ion-margin-end">
                <ion-label position="stacked">{{ 'duration.hours' | translate }}</ion-label>
                <ion-select interface="popover" label="Default label" [formControlName]="formFields.Hours" name="hours" type="number" [value]="0">
                    <ion-select-option *ngFor="let item of [].constructor(configuration.servicePublish.hourCount); let i = index" [value]="i">{{ i }} {{ 'duration.hr' | translate }}</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-col>
        <ion-col size="6" class="ion-no-padding" *ngIf="type === 'hours'">
            <ion-item lines="none" class="ion-item-bordered">
                <ion-label position="stacked">{{ 'duration.minutes' | translate }}</ion-label>
                <ion-select interface="popover" label="Default label" [formControlName]="formFields.Minutes" name="minutes" type="number" [value]="0">
                    <ion-select-option *ngFor="let item of configuration.minutes_duration; let i = index" [value]="item">{{ item }} {{ 'duration.min' | translate }}</ion-select-option>
                </ion-select>

            </ion-item>
        </ion-col>
    </ion-row>
    <ng-content select="[errors]"></ng-content>
</form>
