import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap} from 'rxjs/operators';
import { AccountsService } from '@app/api/services/accounts.service';
import { UserLocation } from '@app/api/models/user-location';
import { StoreLocation } from '@app/store/local-store/store-location';

@Injectable({
    providedIn: 'root',
})
export class UserLocationService extends StoreLocation<AccountsService, UserLocation> {

    constructor(private readonly accountsService: AccountsService) {
        super(accountsService);
    }

    public createLocation = (userLocation: UserLocation):
        Observable<{ allLocations: UserLocation[]; location: UserLocation }> =>
        this.locationService.accountsLocationsCreate(userLocation)
            .pipe(tap(d => {
                    this.updateLocation$.next(d);
                    if (d.is_default) {
                        this.getDefaultLocation().is_default = false;
                    }
                    this.prepareLocations([d,...this._locations]);
                }),
                mergeMap((location) => this.initLocations()
                    .pipe(map((allLocations) => ({ location, allLocations })))));

    public updateLocation = (userLocation: UserLocation):
        Observable<{ allLocations: UserLocation[]; location: UserLocation }> =>
        this.locationService.accountsLocationsUpdate({ id: userLocation.id, data: userLocation })
            .pipe(tap(data => {
                        this.updateLocation$.next(data);
                        this._locations.forEach((l, i) => {
                            if (l.id === data.id) {
                                this._locations[i] = data;
                            } else if (data.is_default && l.is_default) {
                                this._locations[i].is_default = false;
                            }
                        });
                        this.prepareLocations(this._locations);
                    },
                ),
                mergeMap((location) => this.initLocations()
                    .pipe(map((allLocations) => ({ location, allLocations })))));

    public deleteLocation = (id: number): Observable<UserLocation[]> => this.accountsService.accountsLocationsDelete(id)
        .pipe(tap(() => {
                this._locations = this._locations.filter(l => l.id !== id);
                this.prepareLocations(this._locations);
                this.deleteLocation$.next(id);
            }),
            mergeMap((location) => this.initLocations()));

    public getLocationById = (id: number): Observable<UserLocation> => {
        if(!id) {
            return of(null);
        }

        if (!!this._locations.find(loc => loc.id === id)) {
            return of(this._locations.find(loc => loc.id === id));
        } else {
            return this.accountsService.accountsLocationsRead(id);
        }
    };

    public loadLocations = (): Observable<UserLocation[]> => this.accountsService.accountsLocationsList({}).pipe(
        map(request => request.results.filter(location => !!location.country && !!location.city) ?? []),
        tap(locations => this.prepareLocations(locations as UserLocation[])));
}
