import { UserSettings } from '@app/api/models';
import { AuthResponseInterface } from '@app/core/interfaces/auth-response.interface';
import { environment } from '@env/environment';
import { configuration } from '@conf/configuration';
import { CurrentUserStateModel } from './current-user-state.model';

// const defaultLanguage: UserSettings['language'] = navigator?.language === ('ru' || 'ru-RU')
//     ? 'ru'
//     : (environment.default_lang as UserSettings['language']);
//
// const defaultCurrency: UserSettings['currency'] = navigator?.language === ('ru' || 'ru-RU')
//     ? 'RUB'
//     : 'USD';

export const defaultSettings: UserSettings = {
    units: 0,
    currency: configuration.default_currency as UserSettings['currency'],
    is_monday_start_of_a_week: true,
};

export const emptyTokens: AuthResponseInterface = {
    access_token: null,
    expires_in: 0,
    token_type: null,
    scope: null,
    refresh_token: null,
};

export const notLoadedState: CurrentUserStateModel = {
    profile: null,
    tokens: null,
    professionals: null,
    settings: { ...defaultSettings },
    errors: null,
};

export const guestState: CurrentUserStateModel = {
    profile: {},
    tokens: emptyTokens,
    professionals: [],
    settings: { ...defaultSettings, language: configuration.default_lang  as UserSettings['language'] },
    errors: [],
};
