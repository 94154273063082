import { registerLocaleData } from '@angular/common';

import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { CoreModule } from '@app/core/core.module';
import { ErrorHandlingModule } from '@app/core/error-handling/error-handling.module';
import { JsonTranslateLoader } from '@app/core/services/json-translate-loader';
import { SharedModule } from '@app/shared/shared.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy, isPlatform, Platform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { IonicConfig } from '@ionic/core/dist/types/utils/config';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { configuration } from '@conf/configuration';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from './store/store.module';
import 'hammerjs';
import 'mousetrap';
import { BannersModule } from './shared/banners/banners.module';

for(const item of configuration.locales) {
    registerLocaleData(item.locale, item.id);
}

const getConfig = (browserName: string): IonicConfig => {
    const config: IonicConfig = { mode: 'md' }; // TODO change to "ios" eventually
    if (isPlatform('ios') && (/chrome|chromium|crios/i).test(browserName)) {
        return {...config, animated: false};
    }

    return config;
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        IonicModule.forRoot({
            ...getConfig(window.navigator.userAgent),
        }),
        IonicStorageModule.forRoot(),
        BrowserTransferStateModule,
        StoreModule.forRoot(),
        LeafletModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: JsonTranslateLoader,
            },
            defaultLanguage: configuration.default_lang,
        }),
        ApiModule.forRoot({ rootUrl: `${configuration.backend.url}/api` }),
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        CoreModule,
        ErrorHandlingModule,
        SharedModule,
        AppRoutingModule,
        GalleryModule.forRoot(),
        BannersModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        Title,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        Geolocation,
        {
            provide: LocationAccuracy,
            useFactory: (platform: Platform) =>
                !platform.is('desktop')
                    ? LocationAccuracy
                    : {
                          request: () => Promise.resolve(true),
                          canRequest: () => Promise.resolve(),
                      },
            deps: [Platform],
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        Platform
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
