export abstract class ValueAccessor<T> {
    public onChange:(change: T) => void = () => void 0;

    public registerOnChange(fn: (change: T) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    protected onTouched: () => void = () => void 0;
}
