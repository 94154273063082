import { citynameEndsWith, citynameStartsWith } from '@app/core/declination-cities/functions/decline-functions';
import { GendersEnum } from '@app/core/declination-cities/models/genders.enum';
import {
    GenderStrT,
    GenderRuleSetT,
    GenderRulesT
} from '@app/core/declination-cities/models/genders.types';


export function getGenderByRuleSet(name: string, ruleSet: GenderRuleSetT): GenderStrT | null {
    if (!name || !ruleSet) {
        return null;
    }
    const nameLower = name.toLowerCase();
    if (ruleSet.exceptions) {
        const gender = getGenderByRule(ruleSet.exceptions, (some) => {
            if (citynameStartsWith(some, '-')) {
                return citynameEndsWith(nameLower, some.substr(1));
            }

            return some === nameLower;
        });
        if (gender) { return gender; };
    }

    return ruleSet.suffixes
        ? getGenderByRule(ruleSet.suffixes, (some) => citynameEndsWith(nameLower, some))
        : null;
}

function getGenderByRule(
    rules: GenderRulesT,
    matchFn: (some: string) => boolean
): GenderStrT | null {
    const genders = Object.keys(rules).filter((genderKey) => {
        const array = (rules as any)[genderKey];

        return Array.isArray(array) && array.some(matchFn);
    });
    if (genders.length !== 1) { return null; };

    return genders[0] as GendersEnum;
}
