import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Country, Phone } from '@app/api/models';
import { NgDestroyService } from '@app/core/services';
import { CountriesApiCache } from '@app/core/services/cache';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { AutocompleteLocationService } from '@app/profile/services/autocomplete-location.service';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/core/operators/take-until-destroyed';

@DecorateUntilDestroy()
@Component({
    selector: 'app-phone-editor',
    templateUrl: './phone-editor.component.html',
    styleUrls: ['./phone-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneEditorComponent),
            multi: true,
        },
        NgDestroyService,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneEditorComponent implements ControlValueAccessor, OnInit { // TODO: refactor it later
    @Input() public required: boolean = false;
    @Output() public disableEvent = new EventEmitter<boolean>();
    public phoneControl: FormControl = new FormControl();
    public countryControl?: FormControl;
    public countries$ = this.countriesApi.list().pipe(
        map(countries =>
            countries.map(country => ({
                ...country,
                name: `${country.name} ${this.plusSignEnforce(country.phone)}`,
            })),
        ),
    );
    public title = 'location-edit-page.country';
    private readonly writeValue$ = new Subject<Phone>();
    private onChange!: (value: string) => void;
    private onTouch!: (value: string) => void;

    constructor(
        private readonly countriesApi: CountriesApiCache,
        private readonly ngDestroy$: NgDestroyService,
        private readonly cd: ChangeDetectorRef,
        private readonly locationAutocomplete: AutocompleteLocationService,
    ) {
    }

    public ngOnInit(): void {
        this.initCountry()
            .pipe(
                tap(() => this.subscribeControlValuesChanges()),
                tap(() => this.subscribeWriteValue()),
                takeUntilDestroyed(this),
            )
            .subscribe();
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disableEvent.emit(isDisabled);
        if (this.countryControl && this.phoneControl) {
            if (isDisabled) {
                this.countryControl.disable();
                this.phoneControl.disable();
            } else {
                this.countryControl.enable();
                this.phoneControl.enable();
            }
        }
    }

    public writeValue(value: any): void {
        if (this.isPhone(value)) {
            this.writeValue$.next(value);
        }
    }

    public plusSignEnforce(countryPhone: string): string {
        return !countryPhone?.length || countryPhone?.includes('+') ? countryPhone : `+${countryPhone}`;
    }

    private initCountry(): Observable<any> {
        return this.locationAutocomplete.get().pipe(
            tap(l => this.countryControl = new FormControl(l.country ?? null)),
        );
    }

    private subscribeWriteValue(): void {
        combineLatest([this.countries$, this.writeValue$])
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(([countries, value]) => {
                const country = countries.find(c => c.tld === `${value.region_code}`);
                this.countryControl?.setValue(country, { emitEvent: false });
                if (country && country.phone) {
                    const countryCode = this.plusSignEnforce(country.phone.replace(/[^0-9]+/g, ''));
                    const nationalNumber = value.phone.slice(countryCode.length);
                    this.phoneControl.setValue(nationalNumber, { emitEvent: false });
                }
            });
    }

    private subscribeControlValuesChanges(): void {
        combineLatest([
            // eslint-disable-next-line max-len
            (this.countryControl as FormControl).valueChanges.pipe(startWith((this.countryControl as FormControl).value)),
            this.phoneControl.valueChanges.pipe(startWith(this.phoneControl.value)),
        ])
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(([country, phone]: [Country, string]) => {
                const countryCode = this.plusSignEnforce(country?.phone);
                const value: string = phone && country ? `${countryCode}${phone}` : '';
                if (this.onChange) {
                    this.onChange(value);
                }
                this.cd.markForCheck();
            });
    }

    private isPhone(value: any): value is Phone {
        return value && 'region_code' in value;
    }
}
