import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { AnalyticEventsEnum, AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { SEOService } from '@app/service/seo/seo.service';

@Component({
    selector: 'app-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit, OnDestroy {
    constructor(
        @Optional() @Inject(RESPONSE) private readonly response: any,
        private readonly seo: SEOService,
        private readonly analytics: AnalyticsService) { }

    public ngOnInit(): void {
        this.seo.setPrerender404MetaTags();
        this.analytics.track(AnalyticEventsEnum.NotFoundErrorEvent);
        if(this.response && this.response.statusCode) {
            this.response.statusCode = 404;
        } else {
            console.warn('express-engine no init');
        }
    }

    public ngOnDestroy(): void {
        this.seo.removePrerender404MetaTags();
    }
}
