<ion-title class="ion-margin-top ion-margin-bottom">{{title}}</ion-title>
<ion-datetime presentation="date" #date
              [firstDayOfWeek]="firstDayOfWeek$ | async"
              [locale]="translator.currentLang"
              [min]="config?.min"
              [max]="config?.max"
              [(ngModel)]="dateValue">
</ion-datetime>
<ion-item lines="full">
    <ion-label>{{timeLabel}}</ion-label>
    <ion-datetime-button slot="end" datetime="datetime"></ion-datetime-button>
</ion-item>
<ion-popover keepContentsMounted="true" side="top" alignment="center">
    <ng-template>
        <ion-datetime presentation="time" id="datetime"
                    [locale]="translator.currentLang"
                    [minuteValues]="config?.minuteValues"
                    [(ngModel)]="timeValue"></ion-datetime>
    </ng-template>
</ion-popover>
<ion-toolbar>
    <ion-button color="primary" fill="clear" (click)="close()">
        {{'global.buttons.close' | translate | uppercase}}
    </ion-button>
    <ion-buttons slot="end">
        <ion-button color="danger" fill="clear" (click)="clear()">
            {{'global.buttons.clear' | translate | uppercase}}
        </ion-button>
        <ion-button color="primary" fill="clear" (click)="done()">
            {{'global.buttons.done' | translate | uppercase}}
        </ion-button>
    </ion-buttons>
</ion-toolbar>