import { Injectable } from '@angular/core';
import { IpServiceList } from '@app/core/services/location/guess-location-by-ip.services';
import { OnMapPopoverComponent } from '@app/shared/location-editor/on-map-popover/on-map-popover.component';
import { AlertController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, onErrorResumeNext } from 'rxjs';
import { concatMap, defaultIfEmpty, map, mergeMap, take, tap } from 'rxjs/operators';
import { DefaultSettingsDetectorService } from '@app/core/services/default-settings-detector.service';
import { Country } from '@app/api/models/country';
import { City } from '@app/api/models/city';
import { CitiesApiCache, CountriesApiCache } from '@app/core/services/cache';
import { configuration } from '@conf/configuration';
import { ResolvedUserLocation } from '../../interfaces/user-location.interface';
import { skippedLocationNotification } from './decorators';

@Injectable()
export class CurrentLocationService {
    constructor(
        private readonly ipServiceList: IpServiceList,
        private readonly alertController: AlertController,
        public readonly popoverController: PopoverController,
        private readonly translator: TranslateService,
        private readonly citiesApi: CitiesApiCache,
        private readonly countriesApi: CountriesApiCache,
        private readonly settingUpdater: DefaultSettingsDetectorService,
    ) { }

    public guessLocationPrefield(): Observable<any> {
        return onErrorResumeNext<{ country: Country; city: City }>([
            ...this.ipServiceList.services,
        ]).pipe(take(1), defaultIfEmpty(null));
    }

    public guessLocation(): Observable<ResolvedUserLocation> {
        return this.guessLocationPrefield().pipe(
            tap((r) => this.settingUpdater.updateSettings(r?.country)),
            concatMap(d => this.checkSkippedLocationNotification(d)),
            mergeMap(resolved =>
                this.confirmLocation(resolved.location, resolved.skipped)));
    }

    public guessLocationNoConfirm(): Observable<ResolvedUserLocation> {
        const guesses$ = onErrorResumeNext<{ country: Country; city: City }>([
            ...this.ipServiceList.services,
        ]).pipe(take(1), defaultIfEmpty(null));

        return guesses$.pipe(
            map((r) => {
                this.settingUpdater.updateSettings(r?.country);

                return r;
            })
        );
    }

    public async confirmLocation(guessedLocation: ResolvedUserLocation | null, skipped: boolean)
        : Promise<ResolvedUserLocation> {
        if(skipped) {
            return guessedLocation;
        }
        if(sessionStorage.getItem('location') === 'reject') {
            return null;
        }
        if (!!guessedLocation && !!guessedLocation.city) {
            const { city } = guessedLocation;
            const alert = await this.alertController.create({
                subHeader: this.translator.instant('city-confirmation.title', { city: city.name }),
                buttons: [
                    { text: this.translator.instant('global.yn.y'), role: 'confirm' },
                    { text: this.translator.instant('city-confirmation.choose'), role: 'choose' },
                ],
            });
            await alert.present();

            const { role } = await alert.onDidDismiss();
            if (role === 'confirm') {
                sessionStorage.setItem('location', 'confirm');

                return guessedLocation;
            }
            if (role === 'choose') {
                sessionStorage.setItem('location', 'choose');

                return this.specifyNewLocation(guessedLocation);
            }
            if (role === 'backdrop') {
                sessionStorage.setItem('location', 'reject');
            }
        } else if (!guessedLocation.city) {
            return this.specifyNewLocation(guessedLocation);
        }

        return null;
    }

    private async specifyNewLocation(guessedLocation?: ResolvedUserLocation): Promise<ResolvedUserLocation> {
        const { country, city } = guessedLocation ?? { country: null, city: null };
        const popover = await this.popoverController.create({
            component: OnMapPopoverComponent,
            translucent: true,
            animated: true,
            componentProps: {
                data: { country, city },
            },
            cssClass: ['map-popover-width'],
        });

        await popover.present();
        const { data } = await popover.onDidDismiss();

        if (undefined !== data && data?.country?.id !== country?.id) {
            this.settingUpdater.updateSettings(data?.country, true);
        } else {
            sessionStorage.setItem('location', 'reject');
        }

        return { country: data?.country, city: data?.city };
    }

    @skippedLocationNotification()
    private checkSkippedLocationNotification(guessedLocation: ResolvedUserLocation, skippedCountry: number[] = [])
        :Observable<{
        location: ResolvedUserLocation;
        skipped: boolean; }> {

        if (configuration?.listOfSkippedCountryIDs?.includes(guessedLocation?.country?.id)) {
           const country$ = this.countriesApi.getByEntityId(configuration?.listOfSkippedCountryIDs[0]);

            return country$.pipe(mergeMap(country => this.citiesApi.searchByName(country.capital).pipe(
                map(cities=> ({
                location: {
                    country,
                    city: cities.find(c => c.is_capital)
                },
                skipped: true
            })))));
        }

        return of({
            skipped: skippedCountry.includes(guessedLocation.country.id),
            location: guessedLocation
        });
    }
}
