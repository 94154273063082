/* eslint-disable max-lines */
import { Inject, Injectable } from '@angular/core';
import { Profile } from '@app/api/models';
import { AccountsService } from '@app/api/services';
import { GrantTypes } from '@app/store/current-user/grant-types';
import { AuthResponseInterface } from '@app/core/interfaces/auth-response.interface';
import { WINDOW } from '@app/core/injection-tokens';
import { LoginDataInterface } from '@app/core/interfaces/login-data-interface';
import { RefreshDataInterface } from '@app/core/interfaces/refresh-data-interface';
import { ApiClientService } from '@app/core/services/api/api-client.service';
import * as UserLanguagesActions from '@app/store/current-user/user-language-state/user-language.actions';
import { environment } from '@env/environment';
import { configuration } from '@conf/configuration';
import { Storage } from '@ionic/storage';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { normalizeString } from '@app/core/functions/string.functions';
import { NavigationService } from '@app/core/services/navigation.service';
import { ServicePublishDataHolderService } from '@app/service/services/service-publish-data-holder.service';
import { AnalyticEventsEnum, AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { CurrentUserStateModel } from './current-user-state.model';
import * as CurrentUserActions from './current-user.actions';
import { emptyTokens, guestState, notLoadedState } from './current-user.constants';
import * as SavedUserProfessionalsActions from './saved-professionals/saved-professionals.actions';
import { UserSavedProfessionalState } from './saved-professionals/saved-professionals.state';
import * as UserContactActions from './user-contacts/user-contacts.actions';
import { UserContactState } from './user-contacts/user-contacts.state';
import { UserLanguageState } from './user-language-state/user-language.state';
import * as UserLocationActions from './user-locations/user-locations.actions';
import { UserLocationState } from './user-locations/user-locations.state';

const TOKEN_OBTAIN_URL = environment.backend.auth;
const TOKEN_DATA_STORAGE_KEY = 'api_token_data';
const USER_SETTINGS_STORAGE_KEY = 'user_settings';

export const isAuthenticated = (state: CurrentUserStateModel): boolean => {
    const {tokens} = state;

    return Boolean(tokens?.access_token);
};

@State<CurrentUserStateModel>({
    name: 'currentUser',
    defaults: notLoadedState,
    children: [UserLanguageState, UserSavedProfessionalState, UserContactState, UserLocationState],
})
@Injectable()
export class CurrentUserState implements NgxsOnInit {
    constructor(
        private readonly api: AccountsService,
        private readonly storage: Storage,
        private readonly client: ApiClientService,
        @Inject(WINDOW) private readonly window: Window,
        private readonly navigationService: NavigationService,
        private readonly analytics: AnalyticsService,
        private readonly servicePublishState: ServicePublishDataHolderService
    ) {
    }

    public ngxsOnInit(context: StateContext<CurrentUserStateModel>): void {
        context.dispatch(new CurrentUserActions.Initialize());
        this.navigationService.init();
        this.analytics.init();
    }

    @Action(CurrentUserActions.Initialize)
    public initialize({patchState, dispatch}: StateContext<CurrentUserStateModel>): Observable<any> {
        return from(this.storage.get(TOKEN_DATA_STORAGE_KEY)).pipe(
            tap(tokens => {
                if (!tokens) {
                    dispatch([
                        new CurrentUserActions.RestoreSettingsLocal(),
                        new UserLocationActions.GuessCurrentLocation(),
                    ]);
                } else {
                    patchState({tokens});
                    dispatch(new CurrentUserActions.LoadProfile());
                }
            }),
        );
    }

    @Action(CurrentUserActions.Login)
    public login(
        {patchState, dispatch}: StateContext<CurrentUserStateModel>,
        {credentials}: CurrentUserActions.Login,
    ): Observable<any> {
        patchState({tokens: null});

        return this.client
            .post<AuthResponseInterface, LoginDataInterface>(TOKEN_OBTAIN_URL, {
                username: credentials.username,
                password: credentials.password,
                grant_type: GrantTypes.PasswordGrantType,
                client_id: environment.client_id,
                client_secret: environment.client_secret,
            })
            .pipe(
                catchError(error => {
                    if (400 === error.status && error.error.error === 'invalid_grant') {
                        patchState({errors: ['login-page.incorrect-login-data'], tokens: emptyTokens});
                    }

                    return throwError(error);
                }),
                switchMap(result => from(this.storage.set(TOKEN_DATA_STORAGE_KEY, result))),
                tap(tokens => {
                    patchState({tokens});
                }),
                mergeMap(() => dispatch(new CurrentUserActions.LoadProfile())),
            );
    }

    @Action(CurrentUserActions.Logout)
    public logout({patchState, getState, dispatch}: StateContext<CurrentUserStateModel>): Observable<any> {
        const {settings} = getState();
        this.servicePublishState.reset();

        return from(this.storage.remove(TOKEN_DATA_STORAGE_KEY).then(() => {
            patchState({...guestState, settings});
            dispatch(new UserLocationActions.RemoveLocations());
        }));
    }

    @Action(CurrentUserActions.AuthenticateWithToken)
    public authenticateWithToken(
        {patchState, dispatch}: StateContext<CurrentUserStateModel>,
        {tokens}: CurrentUserActions.AuthenticateWithToken,
    ): Observable<any> {
        return from(this.storage.set(TOKEN_DATA_STORAGE_KEY, tokens)).pipe(
            tap(t => patchState({tokens: t})),
        );
    }

    @Action(CurrentUserActions.CreateProfessional)
    public createProfessional(
        {dispatch}: StateContext<CurrentUserStateModel>,
        {master}: CurrentUserActions.CreateProfessional,
    ): Observable<any> {
        return this.api.accountsProfilePartialUpdate({account_type: 'professional'}).pipe(
            switchMap(() => this.api.accountsProfessionalsCreate(master)),
            switchMap(() => dispatch(new CurrentUserActions.LoadProfile())),
        );
    }

    @Action(CurrentUserActions.LoadProfile)
    public loadProfile({patchState, dispatch}: StateContext<CurrentUserStateModel>): Observable<any> {
        return this.api.accountsProfileList().pipe(
            tap(profile => {
                patchState({profile: profile as Profile}); // TODO fix swagger
            }),
            mergeMap(() =>
                dispatch([
                    new CurrentUserActions.LoadSettings(),
                    new CurrentUserActions.LoadProfessionals(),
                    new UserLocationActions.LoadAllUserLocations(),
                    new UserLocationActions.LoadGuessedLocation(),
                    new UserLanguagesActions.LoadAllUserLanguages(),
                    new SavedUserProfessionalsActions.LoadAllUserSavedProfessionals(),
                    new UserContactActions.LoadAllUserContacts(),
                ]),
            ),
        );
    }

    @Action(CurrentUserActions.LoadProfessionals)
    public loadProfessionals({patchState}: StateContext<CurrentUserStateModel>): Observable<any> { // get master
        return this.api
            .accountsProfessionalsList({})
            .pipe(
                tap(response => patchState({professionals: response.results}))
            );
    }

    @Action(CurrentUserActions.Register)
    public register(
        {dispatch}: StateContext<CurrentUserStateModel>,
        {user, userData}: CurrentUserActions.Register,
    ): Observable<any> {
        user.email = normalizeString(user.email);

        return this.api.accountsRegisterCreate(user).pipe(
            // TODO fix swagger; returned user contains the "token" field
            tap(() => this.analytics.track(AnalyticEventsEnum.RegisterEvent)),
            mergeMap((u: any) => dispatch(new CurrentUserActions.AuthenticateWithToken(u.token))),
            mergeMap(() =>
                userData?.location ? dispatch(new UserLocationActions.CreateUserLocation(userData.location)) : of(),
            ),
            mergeMap(() => dispatch(new CurrentUserActions.LoadProfile())),
        );
    }

    @Action(CurrentUserActions.LoadSettings)
    public loadSettings({patchState, dispatch}: StateContext<CurrentUserStateModel>): Observable<any> {
        return this.api.accountsSettingsList({}).pipe(
            tap(response => {
                const settings = response.results[0];

                if (!!settings) {
                    settings.language = this.checkLanguge(settings.language);
                }

                settings.language = this.checkLanguge(settings.language);
                dispatch(new CurrentUserActions.StoreSettingsLocal(settings));
                if (settings) {
                    patchState({settings});
                }
            }),
        );
    }

    @Action(CurrentUserActions.RestoreSettingsLocal)
    public restoreSettings({patchState}: StateContext<CurrentUserStateModel>): Observable<any> {
        return from(this.storage.get(USER_SETTINGS_STORAGE_KEY)).pipe(
            tap((data) => {
                if (!!data) {
                    data.language = this.checkLanguge(data.language);
                }
            }),

            tap(settings => {
                if (settings) {
                    patchState({...guestState, settings});
                } else {
                    patchState(guestState);
                }
            }),
        );
    }

    @Action(CurrentUserActions.ChangeGuessedUserSettings)
    public changeGuessedSettings(
        {dispatch}: StateContext<CurrentUserStateModel>,
        {changes}: CurrentUserActions.ChangeGuessedUserSettings,
    ): Observable<any> {
        return from(this.storage.get(USER_SETTINGS_STORAGE_KEY)).pipe(
            tap((data) => {
                if (!!data) {
                    data.language = this.checkLanguge(data.language);
                }
            }),
            tap(s => !s ? dispatch(new CurrentUserActions.ChangeUserSettings(changes)) : void 0),
        );
    }

    @Action(CurrentUserActions.ChangeUserSettings)
    public changeUserSettings(
        {getState, patchState, dispatch}: StateContext<CurrentUserStateModel>,
        {changes}: CurrentUserActions.ChangeUserSettings,
    ): void {
        // TODO actions should return observable
        const state = getState();
        const isAuth = isAuthenticated(state);
        const {settings} = state;

        const newSettings = {
            ...settings,
            ...changes,
        };

        if (isAuth) {
            dispatch(new CurrentUserActions.SaveSettings(newSettings));
        }
        dispatch(new CurrentUserActions.StoreSettingsLocal(newSettings));

        patchState({settings: newSettings});
    }

    @Action(CurrentUserActions.ChangeUserSettingsLanguage)
    public saveUserSettingsLanguage(
        {getState, dispatch, patchState}: StateContext<CurrentUserStateModel>,
        {newLanguage}: CurrentUserActions.ChangeUserSettingsLanguage,
    ): Observable<any> {
        // TODO actions should return observable
        const state = getState();
        const isAuth = isAuthenticated(state);
        const {settings} = state;
        const id = settings?.id;
        const newSettings = {...settings, language: newLanguage};
        patchState({settings: newSettings});

        const saveLanguage$ = id
            ? this.api.accountsSettingsUpdate({id, data: newSettings})
            : this.api.accountsSettingsCreate(newSettings);

        return forkJoin([
            dispatch(new CurrentUserActions.StoreSettingsLocal(newSettings)),
            ...(isAuth ? [saveLanguage$] : []),
        ]).pipe(
            switchMap(() => dispatch(new UserLocationActions.UpdateResolvedLocation())),
            tap(() => this.window.location.reload()),
        );
    }

    @Action(CurrentUserActions.SaveSettings)
    public saveUserSettings(
        {}: StateContext<CurrentUserStateModel>,
        {newSettings}: CurrentUserActions.SaveSettings,
    ): Observable<any> {
        const id = newSettings.id;

        return id
            ? this.api.accountsSettingsUpdate({id, data: newSettings})
            : this.api.accountsSettingsCreate(newSettings);
    }

    @Action(CurrentUserActions.StoreSettingsLocal)
    public storeUserSettings(
        {}: StateContext<CurrentUserStateModel>,
        {newSettings}: CurrentUserActions.StoreSettingsLocal,
    ): Observable<any> {
        return from(this.storage.set(USER_SETTINGS_STORAGE_KEY, newSettings));
    }

    @Action(CurrentUserActions.UpdateProfile)
    public updateProfile(
        {patchState, dispatch, getState}: StateContext<CurrentUserStateModel>,
        {changes}: CurrentUserActions.UpdateProfile,
    ): Observable<any> {
        const {profile} = getState();
        const existingEmail = profile.email;
        const newEmail = changes.email;

        return this.api.accountsProfilePartialUpdate(changes).pipe(
            tap(p => {
                patchState({profile: p});
            }),
            mergeMap(() => {
                if (newEmail && existingEmail !== newEmail) {
                    return dispatch(new CurrentUserActions.RegisterNewEmail(newEmail));
                }

                return of();
            }),
        );
    }

    @Action(CurrentUserActions.UpdateAvatar)
    public updateAvatar(
        {patchState}: StateContext<CurrentUserStateModel>,
        {avatar}: CurrentUserActions.UpdateAvatar,
    ): Observable<any> {
        return this.api.accountsProfilePartialUpdate({avatar}).pipe(
            tap(profile => {
                patchState({profile});
            }),
        );
    }

    @Action(CurrentUserActions.RegisterNewEmail)
    public registerNewEmail(
        {}: StateContext<CurrentUserStateModel>,
        {newEmail}: CurrentUserActions.RegisterNewEmail,
    ): Observable<any> {
        return this.api.accountsRegisterEmailCreate({email: newEmail});
    }

    @Action(CurrentUserActions.ResendEmailVerification)
    public resendEmailVerification(): Observable<any> {
        return this.api.accountsResendVerifyRegistrationCreate();
    }

    @Action(CurrentUserActions.VerifyEmailAction)
    public verifyEmail(
        {patchState, getState}: StateContext<CurrentUserStateModel>,
        {verifyEmail}: CurrentUserActions.VerifyEmailAction,
    ): Observable<any> {
        const {profile} = getState();
        const {email, user_id} = verifyEmail;

        if (profile.id !== parseInt(user_id, 10)) {
            throw Error('Try to change another\'s user email');
        }

        return this.api.accountsVerifyEmailCreate(verifyEmail).pipe(
            tap(() => {
                patchState({
                    profile: {
                        ...profile,
                        email,
                        is_confirmed: true,
                    },
                });
            }),
        );
    }

    @Action(CurrentUserActions.RefreshTokens)
    public refreshTokens({getState, patchState}: StateContext<CurrentUserStateModel>): Observable<any> {
        const tokens = getState().tokens;
        const refreshData: RefreshDataInterface = {
            refresh_token: tokens.refresh_token,
            grant_type: GrantTypes.RefreshGrantType,
        };

        return this.client
            .post<AuthResponseInterface, RefreshDataInterface>(environment.backend.refresh, refreshData)
            .pipe(
                tap(t => patchState({tokens: t})),
                tap((t) => from(this.storage.set(TOKEN_DATA_STORAGE_KEY, t))),
                catchError(response => {
                    console.warn('Got error:', response.error);
                    patchState({tokens: emptyTokens});

                    return of();
                }),
            );
    }

    private checkLanguge(lang: string): any {
        if (!configuration.lang_list.includes(lang)) {
            return configuration.default_lang;
        }

        return lang;
    }
}
