import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-row',
    templateUrl: './info-row.component.html',
    styleUrls: ['./info-row.component.scss'],
})
export class InfoRowComponent {
    @Input() public redMessage = false;
    @Input() public set state(s: string) {
        switch (s) {
            case 'info':
                this.classState =  'item-info-background';
                this.iconColor = 'primary';
                break;
            case 'warning':
                this.classState =  'item-warning-background';
                this.iconColor = 'warning';
                break;
            default:
                break;
        }
    };

    public classState = 'item-info-background';
    public iconColor = 'primary';
}
