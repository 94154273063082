<ks-modal-gallery
    [id]="0"
    [modalImages]="getImages()"
    (close)="close()"
    (show)="show($event)"
    [buttonsConfig]="buttonsConfigAdvanced"
    (buttonBeforeHook)="onCustomButtonBeforeHook($event)"
    [currentImageConfig]="{description: customDescription}"
    [plainGalleryConfig]="customPlainGalleryRowConfig"
>
</ks-modal-gallery>