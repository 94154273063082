<ng-container *ngIf="getStartDateTimeFormat() | async as start">
    <ng-container *ngIf="getEndDateTimeFormat() | async as end">
        <ng-container *ngIf="obj?.start_datetime && !obj?.end_datetime">
            {{ obj?.start_datetime | date: start:undefined:translator.currentLang }}
        </ng-container>
        <ng-container *ngIf="obj?.end_datetime && obj?.start_datetime">
            <ng-container>
                {{ obj?.start_datetime | date: start:undefined:translator.currentLang }} -
                {{ obj?.end_datetime | date: end:undefined:translator.currentLang }}
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
