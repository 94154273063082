import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NavBranch, NavPath } from '@app/core/constants/navigation.constants';
import { environment } from '@env/environment';
import { configuration } from '@conf/configuration';
import { TranslateService } from '@ngx-translate/core';
import { links } from '@conf/links';

const CURRENT_YEAR = new Date().getFullYear().toString();
const START_YEAR = configuration.start_year_project;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    public infoEmail = links.email;
    public readonly configuration = configuration;
    public supportEmail = links.email_support;
    public ageProject: string;
    public readonly NavPath = NavPath;
    public readonly NavBranch = NavBranch;
    public readonly environment = environment;
    public readonly links = links;

    constructor(
        public readonly platform: Platform,
        public readonly tr: TranslateService
    ) {
        this.ageProject = START_YEAR && (START_YEAR !== CURRENT_YEAR) ? `${START_YEAR} - ${CURRENT_YEAR}`: CURRENT_YEAR;
    }
}
