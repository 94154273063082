import { PlaceOrderBannerComponent } from '@app/shared/banners/place-order-banner/place-order-banner.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Subject } from 'rxjs';
import { SHOW_BANNER } from '@app/shared/banners/tokens/show-banner.token';
import { ProfessionalPromoComponent } from '@app/shared/banners/professional-promo-banner/professional-promo.component';

const components = [PlaceOrderBannerComponent, ProfessionalPromoComponent];

@NgModule({
    declarations: components,
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
    ],
    exports: components,
    providers: [{ provide: SHOW_BANNER, useValue: new Subject<{ [key: string] : boolean }>() }]
})

export class BannersModule {}
