<ion-content>
    <ion-list class="ion-no-padding">
        <ng-container *ngFor="let item of mainMenuItems">
            <ion-item-divider *ngIf="item.isHeader && isShown(item)" color="light">
                <ion-label>
                    <div class="menu-item-title">{{ item.title | translate }}</div>
                </ion-label>
            </ion-item-divider>
            <ng-container *ngIf="item.title.includes(jobSearch)">
                <ion-menu-toggle *ngIf="isShown(item)" menu="main-menu" [autoHide]="false">
                    <ion-item
                        (click)="menuClick(jobsBoard)"
                        lines="full"
                        button
                        [ngClass]="{ 'active': checkActiveLink(item.link) }"
                        [id]="item.title"
                        appAccessToJobsBoard>
                        <ion-icon [name]="jobsBoard.icon" slot="start"></ion-icon>
                        {{ jobsBoard.title | translate }}
                    </ion-item>
                </ion-menu-toggle>
            </ng-container>
            <ion-menu-toggle *ngIf="!item.isHeader && isShown(item) && !!item?.hide === false" menu="main-menu" [autoHide]="false">
                <ion-item
                    (click)="menuClick(item)"
                    [routerLink]="item.link"
                    [queryParams]="item.params"
                    routerLinkActive="active"
                    lines="full"
                    [id]="item.title"
                >
                    <ion-icon [name]="item.icon" slot="start"></ion-icon>
                    {{ item.title | translate }}
                </ion-item>
            </ion-menu-toggle>
        </ng-container>
    </ion-list>
</ion-content>
<!--TO DO remove after preparing dark theme-->
<!--<ion-footer>-->
<!--    <ion-item>-->
<!--        <ion-label> {{ 'layout.dark-theme' | translate }}:</ion-label>-->
<!--        <ion-toggle (ionChange)="changeTheme($event)" [checked]="darkTheme$ | async"></ion-toggle>-->
<!--    </ion-item>-->
<!--</ion-footer>-->
