import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from '@app/api/models';
import { ContactUnion } from '@app/core/models/contact-union';
import { configuration } from '@conf/configuration';
import { BackableRedirectService } from '@app/core/services/backable-redirect.service';

const httpsPrefix = 'https://';

@Component({
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.scss'],
})
export class ContactEditComponent {
    public prefixClass = '';
    @Input() public contactItems: Contact[];
    @Input() public set contact(contact: ContactUnion) {
        this._contact = contact;
        this.canDelete = !!contact?.id;
        this.form.setValue({
            contact: contact.contact,
            value: contact.value
        });
        this.form.controls.contact.enable();
    }
    public get contact(): ContactUnion {
        return this._contact;
    }

    @Output() public delete: EventEmitter<ContactUnion['id']> = new EventEmitter<ContactUnion['id']>();
    @Output() public save: EventEmitter<ContactUnion> = new EventEmitter<ContactUnion>();

    public canDelete: boolean;

    public form: FormGroup = this.fb.group({
        contact: ['', Validators.required],
        value: ['', Validators.required],
    });
    private _contact: ContactUnion;

    constructor(private readonly backableRedirect: BackableRedirectService,
        private readonly fb: FormBuilder) {
    }

    public deleteContact(): void {
        this.delete.emit(this.contact.id);
    }

    public saveContact(): void {
        this.save.emit({ ...this.contact, ...this.form.value });
    }

    public setPrefix($event: MouseEvent): void {
        this.prefixClass = this.contactItems.find(c => c.id === this.form.value.contact).code;
    }

    public clearFocus($event: any): void {
        this.prefixClass = '';
    }

    public inputContact({detail}): void {
        const key = Object.values(configuration.contacts).find(
            val => detail.value.includes(val)
                || detail.value.includes(val.replace(httpsPrefix,'').replace('/',''))
        );

        this.form.patchValue({ value: detail.value.replace(key, '') });
    }
}
