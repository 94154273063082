import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getLocalDateString, getMonthDateString } from '@app/core/functions/datetime.functions';
import DateInterval from './date-interval.interface';

@Component({
    selector: 'app-date-interval-editor',
    templateUrl: './date-interval-editor.component.html',
    styleUrls: ['./date-interval-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateIntervalEditorComponent),
            multi: true,
        },
    ],
})
export class DateIntervalEditorComponent implements ControlValueAccessor {
    public startDate?: string;
    public endDate?: string;
    public isOngoing?: boolean;

    // @ts-ignore
    private onChange: (value: DateInterval) => void;
    // @ts-ignore
    private onTouched: () => void;

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(value: DateInterval): void {
        this.startDate = getMonthDateString(value?.startDate) || void 0;
        this.endDate = getMonthDateString(value?.endDate) || void 0;
        this.isOngoing = value?.isOngoing;
    }

    public toggleOngoing(event: CustomEvent): void {
        this.isOngoing = event.detail.checked;
        if (this.isOngoing) {
            this.endDate = void 0;
        }
        this.emitOnChange();
    }

    public setStartDate(event: CustomEvent): void {
        this.startDate = event.detail.value;
        this.emitOnChange();
    }

    public setEndDate(event: CustomEvent): void {
        this.endDate = event.detail.value;
        this.emitOnChange();
    }

    public setTouched(): void {
        if (this.onTouched) {
            this.onTouched();
        }
    }

    private emitOnChange(): void {
        if (this.onChange) {
            this.onChange({
                startDate: getLocalDateString(this.startDate as string) as string,
                endDate: getLocalDateString(this.endDate as string) as string,
                isOngoing: this.isOngoing as boolean,
            });
        }
    }
}
