import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MustBeAuthorizedGuard implements CanActivate {
    constructor(private readonly authenticator: AuthenticationService, private readonly router: Router) {}

    public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.authenticator.isAuthenticated$.pipe(
            map(isAuthenticated => {
                if (isAuthenticated) {
                    return true;
                }

                return this.router.parseUrl(`/auth/login?redirectTo=${encodeURIComponent(state.url)}`);
            }),
        );
    }
}
