// @ts-nocheck
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized, UrlSerializer } from '@angular/router';
import { NavPath } from '@app/core/constants/navigation.constants';
import { SearchFilterStateConverter } from '@app/core/services/search/search-filter-state-converter.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import {
    concatMap,
    defaultIfEmpty,
    filter,
    map,
    pairwise, switchMap, take,
    tap
} from 'rxjs/operators';
import { SearchFilterFormValue } from '@app/search/interfaces/search-filter-form-value.interface';
import { SearchListParams } from '@app/api/models/search-list-params';
import { InfiniteScrollData, PaginatedResult } from '@app/shared/infinite-scroll/models/infinite-scroll.model';
import { Search } from '@app/api/models/search';
import { SearchService } from '@app/api/services/search.service';
import { CategoriesApiCache, CitiesApiCache, CountriesApiCache, SubcategoriesApiCache } from '@app/core/services/cache';
import { SearchFilterStateService } from '@app/core/services';
import { environment } from '@env/environment';
import { NavController } from '@ionic/angular';
import { Country } from '@app/api/models/country';
import { City } from '@app/api/models/city';
import { Category } from '@app/api/models/category';
import { Subcategory } from '@app/api/models/subcategory';

export interface ISelectedParams {
    country: Observable<Country>;
    city: Observable<City>;
    category: Observable<Category>;
}

// TODO: FIX THE WHOLE CLASS AND ALL DEPENDENCIES
@Injectable()
export class SearchQueryService {
    public readonly doLoad$ = new Subject<InfiniteScrollData<SearchListParams, Search>>();
    public params: SearchListParams = {};
    public locationSubj = new BehaviorSubject({});
    public receivedLocation$ = this.locationSubj.asObservable();
    public selected = new BehaviorSubject<ISelectedParams>(null);
    public selected$ = this.locationSubj.asObservable();
    private readonly params$ = new BehaviorSubject<SearchListParams | null>(null);
    // @ts-ignore
    private readonly apiRequestFunction: (
        params: SearchListParams,
    ) => Observable<PaginatedResult<Search>> = this.search.searchList.bind(this.search);
    private routerStopListener = false;

    constructor(
        public readonly navCtrl: NavController,
        public readonly state: SearchFilterStateService,
        private readonly searchFilterStateConverter: SearchFilterStateConverter,
        private readonly countriesApiCache: CountriesApiCache,
        private readonly citiesApiCache: CitiesApiCache,
        private readonly categoriesApiCache: CategoriesApiCache,
        private readonly subcategoriesApiCache: SubcategoriesApiCache,
        private readonly search: SearchService,
        private readonly urlSerializer: UrlSerializer,
        private readonly router: Router
    ) {
        this.canSearch().subscribe();
        this.params$.pipe(
            filter(x => !!x),
            tap((queryParams) => {

                if (!this.routerStopListener) {
                    this.params = {
                        ...queryParams,
                    };
                    this.doLoad$.next({
                        params: this.params,
                        apiRequestFunction: this.apiRequestFunction,
                    });
                }
            })).subscribe();
    }

    public initParams(params: SearchListParams): void {
        this.params$.next(params);
    }

    public searchByFormValue(formValue: SearchFilterFormValue): Observable<null> {
        const queryParams = this.searchFilterStateConverter.getSearchListParams(formValue);
        this.routerStopListener = false;

        return this.initParamsById(queryParams);
    }

    public searchFromFilter(formValue: SearchFilterFormValue): Observable<any> {
        return forkJoin({
            ...(!!formValue?.location?.city &&
                { city: this.citiesApiCache.getByEntityId(formValue.location.city) }),
            ...(!!formValue?.location?.country &&
                { country: this.countriesApiCache.read(formValue.location.country) }),
            ...(!!formValue?.categoryAndSubcategory?.categories?.length &&
                { category: of(formValue.categoryAndSubcategory.categories) }),
            ...(!!formValue?.categoryAndSubcategory?.subcategories?.length &&
                { subcategory: of(formValue.categoryAndSubcategory.subcategories) }),
            queryParams: of(this.searchFilterStateConverter.getSearchListParams(this.getQueryParams(formValue)))
        }).pipe(
            tap(params => {
                if ('country' in params || 'city' in params ||
                    'category' in params || 'subcategory' in params || 'queryParams' in params) {
                    this.routerStopListener = false;
                    this.router.navigate([NavPath.Search, ...this.getSlugsFromArrayParams(params)],
                        { queryParams: { ...params.queryParams }, replaceUrl: true });
                } else {
                    this.routerStopListener = false;
                    this.router.navigate([NavPath.Search, 0, 0, 0, 0, btoa(null)],
                        { queryParams: {}, replaceUrl: true });
                }
            })
        );
    }

    public setSearchParam(params: SearchListParams): void {
        const newParams = {
            ...this.params$.value,
            ...params,
        };
        this.searchFilterStateConverter.getSearchFilterState(newParams)
            .pipe(
                take(1),
                concatMap(d => this.searchFromFilter(d)),
            )
            .subscribe(() => this.params$.next(newParams));
    }

    public createUrlFromParams(subcategories: Subcategory[]): Observable<string> {
        const sc = this.searchFilterStateConverter.getSearchListParams(
            { categoryAndSubcategory: { subcategories: subcategories } } as Partial<SearchFilterFormValue>
        ).subcategories;

        return this.selected.pipe(
            take(1),
            switchMap(data => forkJoin({
                country: data?.country,
                city: data?.city,
                category: data.category,
                ...(!!sc && { subcategory: this.subcategoriesApiCache.read(Number(sc)) }),
                queryParams: this.getQueryParams([
                    this.searchFilterStateConverter.getSearchListParams({
                        country: data?.country,
                        city: data?.city,
                        categories: data.category,
                        subcategories: sc
                    } as Partial<SearchFilterFormValue>)
                ])
            }).pipe(
                defaultIfEmpty(null),
                map(dataParams => this.urlSerializer.serialize(
                    this.router.createUrlTree([NavPath.Search, ...this.getSlugsFromParams(dataParams)],
                        { queryParams: { ...dataParams.queryParams } }))
                ))
            )
        );
    }

    public setLocationFromOutside(location: { country: number; city: number }): void {
        this.locationSubj.next({ ...location });
    }

    private initParamsById(params: any): Observable<null> {
        return forkJoin({
            ...(!!params.country && { country: this.countriesApiCache.read(params.country) }),
            ...(!!params.city && { city: this.citiesApiCache.getByEntityId(params.city) }),
            ...(!!params.categories && { category: this.categoriesApiCache.read(params.categories) }),
            ...(!!params.subcategories && { subcategory: this.subcategoriesApiCache.read(params.subcategories) }),
            queryParams: of(this.getQueryParams(params))
        }).pipe(defaultIfEmpty(null),
            tap(dataParams => {
                this.state.patchValue({
                    ...dataParams,
                    location: {
                        country: dataParams?.country?.id ?? null,
                        city: dataParams?.city?.id ?? null
                    }
                });
                this.routerStopListener = false;
                const url = this.urlSerializer.serialize(
                    this.router.createUrlTree([NavPath.Search, ...this.getSlugsFromParams(dataParams)],
                        { queryParams: { ...dataParams.queryParams } }));
                this.navCtrl.navigateForward(url, { animated: false });
            }));
    }

    // @ts-ignore
    private getSlugsFromParams(params): string[] {
        return [
            this.setLowerCaseSlug(params?.country?.tld) ?? '0',
            this.setLowerCaseSlug(params?.city?.slug) ?? '0',
            params?.category?.slug ?? '0',
            params?.subcategory?.slug ?? '0',
            btoa([
                params?.country?.id ?? 0,
                params?.city?.id ?? 0,
                params?.category?.id ?? null,
                params?.subcategory?.id ?? null
            ].toString())
        ];
    }

    private getSlugsFromArrayParams({ country, city, category, subcategory }): string[] {
        return [
            this.setLowerCaseSlug(country?.tld) ?? '0',
            this.setLowerCaseSlug(city?.slug) ?? '0',
            category?.length ? category.map(c => c.slug).join(environment.separator) : '0',
            subcategory?.length ? subcategory.map(c => c.slug).join(environment.separator) : '0',
            btoa([
                country?.id ?? null,
                city?.id ?? null,
                category?.length ? category.map(c => c.id).join(';') : null,
                subcategory?.length ? subcategory.map(c => c.id).join(';') : null
            ].toString())
        ];
    }

    private getQueryParams(params: any): any {
        delete params.location;
        delete params.categoryAndSubcategory;
        delete params.country;
        delete params.city;
        delete params.categories;
        delete params.subcategories;

        return params;
    }

    private canSearch(): Observable<any> {
        return this.router.events.pipe(
            filter(e => e instanceof RoutesRecognized),
            pairwise(),
            tap((event: any[]) => {
                if (event[0].urlAfterRedirects.indexOf('goBack=true') > 0 ||
                    event[1].urlAfterRedirects.indexOf('goBack=true') > 0) {
                    this.routerStopListener = true;
                } else {
                    this.routerStopListener = false;
                }
            }),
        );
    }

    private setLowerCaseSlug(slug?: string): string | null {
        if (slug && slug.length) {
            return slug.toLowerCase().replace(/[.*+?^${}()—–-]/g, '_');
        }

        return null;
    }
}
