import { Injectable } from '@angular/core';
import { Subcategory } from '@app/api/models';
import { ProfessionalsService } from '@app/api/services';
import { ApiCache } from '@app/core/services/cache/api-cache.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

const PAGE_SIZE = 250;

@Injectable()
export class SubcategoriesApiCache extends ApiCache<Subcategory> {
    private readonly listCache: Subcategory[] = [];
    constructor(private readonly professionalsService: ProfessionalsService) {
        super();
    }

    public list(): Observable<Subcategory[]> {
        return of(this.listCache);
    }

    public read(id: number): Observable<Subcategory> {
        const category = this.listCache.find(c => c.id === id);
        if(!category) {
            return this.professionalsService.professionalsSubcategoriesRead(id).pipe(tap(d=> this.listCache.push(d)));
        }

        return of(category);
    }
}
