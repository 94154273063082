import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { NavPath } from '@app/core/constants/navigation.constants';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import ProfessionalPageSelectors from '@app/store/professional-page/professional-page.selectors';
import { ProfessionalList } from '@app/api/models/professional-list';
import { first } from 'rxjs/operators';
import { configuration } from '@conf/configuration';

@Injectable({ providedIn: 'root' })
export class TitleService {

    @Select(ProfessionalPageSelectors.professional)
    public profile$: Observable<ProfessionalList>;

    private tmpTitle = '';

    constructor(
        public readonly router: Router,
        public readonly location: Location,
        public readonly activatedRoute: ActivatedRoute,
        public readonly title: Title,
        public readonly translator: TranslateService,
    ) {
        this.translator.onLangChange.subscribe(() => this.setTitle());
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if(!this.tmpTitle) {
                    this.setTitle();
                } else {
                    this.title.setTitle(this.tmpTitle);
                    this.tmpTitle = '';
                }
            }
        });
    }

    public setTitleFromArg(title: string): void {
        this.tmpTitle = title;
        this.title.setTitle(this.tmpTitle);
    }

    private setTitle(): void {
        const segments = this.router.url.split('?')[0].split('/');
        const url = segments.pop();
        if (url === 'profile') {
            this.getNameFromProfile(segments.indexOf('professional') < 0);

            return;
        }
        this.title.setTitle(this.translator.instant(titleList[this.getTranslationLiteral(url)]
                || configuration.app_title ).replace('{{ app }}', configuration.app_title));
    }

    private getTranslationLiteral(url): string {
        const item = Object.entries(NavPath).find(key => key[1] === url);
        if(item) {
            return `${Object.entries(NavPath).find(key => key[1] === url)[0]}`;
        }

        return '';
    }

    private getNameFromProfile(isCurrent): void {
        if (isCurrent) {
            this.title.setTitle(this.translator.instant(titleList.Profile || configuration.app_title ));
        } else {
            this.profile$.pipe(first(x => !!x)).subscribe(profile => {
                if (profile) {
                    this.title.setTitle(`${profile.user.first_name} ${profile.user.last_name} `);
                }
            });
        }
    }
}

const titleList = {
    Main: 'global.titles.main',
    Profile: 'global.titles.my-account',
    Search: 'global.titles.search',
    Orders: 'global.titles.sent-orders',
    ProfessionalLanding: 'global.titles.become-professional',
    Message: 'global.titles.messages',
    Service: 'global.titles.service-publication',
    Bookmarks: 'global.titles.bookmarks',
    Auth: 'global.titles.log-in',
    Blog: 'global.titles.blog',
    Policy: 'global.titles.policy',
    Eula: 'global.titles.eula',
    About: 'global.titles.about',
    ProfessionalSchedule: 'global.titles.professional-schedule',
    Outbox: 'global.titles.outbox',
    Inbox: 'global.titles.inbox',
    List: 'global.titles.list',
    Registration: 'global.titles.sign-up',
    Login: 'global.titles.log-in',
    PasswordRecover: 'global.titles.password-recover',
    Schedule: 'global.titles.schedule',
    Services: 'global.titles.services',
    StepOne: 'global.titles.step-one',
};
