import { constantizeGenderInRules, declineByRules } from '@app/core/declination-cities/functions/decline-functions';
import { getGenderByRuleSet } from '@app/core/declination-cities/functions/gender-functions';
import { DeclentionStrT } from '@app/core/declination-cities/models/declension.types';
import { GenderStrT } from '@app/core/declination-cities/models/genders.types';
import { DeclensionEnum } from '@app/core/declination-cities/models/declensions.enum';
import declineRules from '@app/core/declination-cities/rules/decline-rules';
import genderRules from '@app/core/declination-cities/rules/gender-rules';

constantizeGenderInRules(declineRules);

export function declineDefault(
    word: string,
    declension: DeclentionStrT = DeclensionEnum.NOMINATIVE,
    gender?: GenderStrT | null
): string {
    return declineByRules(word, declension, gender || getGenderByRuleSet(word, genderRules), declineRules);
}
