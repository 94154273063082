export abstract class ValueAccessor<T> {
    protected onTouched: () => void;

    public onChange:(change: T) => void = () => void 0;

    public registerOnChange(fn: (change: T) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn): void {
        this.onTouched = fn;
    }
}
