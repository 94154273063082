import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponseInterface } from '@app/core/interfaces/auth-response.interface';
import { HTTP_UNAUTHORIZED } from '@app/core/constants/http.constants';
import * as CurrentUserActions from '@app/store/current-user/current-user.actions';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { environment } from '@env/environment';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import {concat, Observable, of, throwError} from 'rxjs';
import {
    catchError,
    distinct, filter,
    finalize,
    first,
    ignoreElements,
    share,
    switchMap, take,
    tap,
} from 'rxjs/operators';
import * as ErrorMessages from '@app/core/error-handling/error-messages';
import { AuthenticationService, ToastService } from '@app/core/services';
import { Router } from '@angular/router';
import { NavBranch, NavPath } from '@app/core/constants/navigation.constants';

const EXCLUDE_URL = [`/${NavPath.ClientApplications}/${NavBranch.Publish}`];

/**
 *  Tries to refresh auth token if it has expired
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    @Select(CurrentUserSelectors.tokens)
    public readonly tokens$!: Observable<AuthResponseInterface>;

    private refresh$?: Observable<never>;

    constructor(
        private readonly toast: ToastService,
        private readonly router: Router,
        private readonly auth: AuthenticationService,
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler, forceRefresh?: boolean): Observable<HttpEvent<any>> {
        const handleRequestErrors: () => Observable<HttpEvent<any>> = () =>
            next.handle(request).pipe(
                catchError(error =>
                    this.tokens$.pipe(
                        first(),
                        tap(d => {
                            if(!d?.access_token && !d?.refresh_token && !EXCLUDE_URL.includes(this.router.url)) {
                                this.auth.logout();
                                this.router.navigate([NavPath.Profile], {replaceUrl: true});
                            }
                        }),
                        switchMap(tokens => error.status === HTTP_UNAUTHORIZED ?
                            (tokens?.access_token && !forceRefresh
                                    ? this.intercept(request, next, true)
                                    : this.handleUnauthorizedResponse()) :
                            throwError(error)
                        ),
                    ),
                ),
            );

        if (forceRefresh && !this.refresh$) {
            this.refresh$ = this.refresh().pipe(
                // @ts-ignore
                finalize(() => (this.refresh$ = null)),
                share(),
            );
        }

        return !this.refresh$ || request.url.endsWith(environment.backend.refresh)
            ? handleRequestErrors()
            : concat(this.refresh$, handleRequestErrors());
    }

    /**
     * Calls the RefreshTokens action
     * Returns a new Observable, which completes when tokens$ change
     */
    private refresh(): Observable<never> {
        let firstRun = true;

        return this.tokens$.pipe(
            filter(x => !!x),
            distinct(),
            take(2),
            tap(() => {
                if (firstRun) {
                    firstRun = false;
                    this.refreshTokens();
                }
            }),
            ignoreElements(),
        );
    }

    private handleUnauthorizedResponse(): Observable<any> {
        this.toast.showMessage(ErrorMessages.AUTHENTICATION_ERROR, { translate: true });
        this.auth.logout();// delete invalid credentials
        const currentUrl = this.router.routerState.snapshot.url;
        if (currentUrl && !currentUrl.includes('/auth/login')) {
            this.router.navigate(['/auth/login'],{
                queryParams: {redirectTo: currentUrl},
                replaceUrl: true
            });
        }

        return of();
    }

    @Dispatch()
    private refreshTokens(): CurrentUserActions.RefreshTokens {
        return new CurrentUserActions.RefreshTokens();
    }
}
