import { Injectable } from '@angular/core';
import { AuthResponseInterface } from '@app/core/interfaces/auth-response.interface';
import { Credentials } from '@app/core/interfaces/credentials';
import * as CurrentUserActions from '@app/store/current-user/current-user.actions';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { UserLocationService } from '@app/store/local-store/user-locations/user-location.service';
import {
    ProfessionalLocationsService
} from '@app/store/local-store/professional-locations/professional-locations.service';

/**
 *  Main authentication service
 */
@Injectable()
export class AuthenticationService {
    public isAuthenticated$: Observable<boolean>;

    @Select(CurrentUserSelectors.tokens)
    public readonly tokens$!: Observable<AuthResponseInterface>;

    constructor(private readonly userLocation: UserLocationService,
                private readonly professionalLocations: ProfessionalLocationsService) {
        // @ts-ignore
        this.isAuthenticated$ = this.tokens$.pipe(
            filter(tokens => !!tokens),
            map(tokens => !!tokens.access_token),
            shareReplay(1),
        );
    }

    @Dispatch()
    public login(credentials: Credentials): CurrentUserActions.Login {
        return new CurrentUserActions.Login(credentials);
    }

    @Dispatch()
    public logout(): CurrentUserActions.Logout {
        // todo (refactoring): locations need to be reset
        this.userLocation.resetLocations();
        this.professionalLocations.resetLocations();

        return new CurrentUserActions.Logout();
    }

    @Dispatch()
    public authenticateWithToken(token: AuthResponseInterface): CurrentUserActions.AuthenticateWithToken {
        return new CurrentUserActions.AuthenticateWithToken(token);
    }
}
