import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
    selector: 'ui-h4',
    templateUrl: './h4.component.html',
    styleUrls: ['./h4.component.scss'],
})
export class H4Component {
    constructor(public readonly platform: Platform) {}
}
