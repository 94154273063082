<ion-item lines="none" class="ion-item-no-side-padding ion-item-no-min-height">
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.875 7.3125H10.8281L9 1.6875L7.17188 7.3125H1.125L6.04688 10.6875L4.14844 16.3125L9 12.7969L13.8516 16.3125L11.9531 10.6875L16.875 7.3125Z"
            stroke="#FFCE00"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.5 12.5417L5.12285 14.9889L6.52062 10.8474L6.64157 10.489L6.32964 10.2751L2.7383 7.8125H7.17188H7.53512L7.64739 7.46704L8.5 4.84363V12.5417Z"
            fill="#FFCE00"
            stroke="#FFCE00"
        />
    </svg>
</ion-item>
