import { Pipe, PipeTransform } from '@angular/core';
import { ContactUnion } from '@app/core/models/contact-union';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { UserSettings } from '@app/api/models/user-settings';
import { Contact } from '@app/api/models/contact';

const exclude = ['facebook', 'instagram'];

const countryExclude = ['ru'];

@Pipe({
    name: 'contactFilter',
})
export class ContactFilterPipe implements PipeTransform {
    @Select(CurrentUserSelectors.settings)
    public readonly settings$: Observable<UserSettings | null>;

    public userSettings: any;
    constructor() {
        this.settings$
            .pipe(
                first(),
                map(data => (this.userSettings = data)),
            )
            .subscribe();
    }

    public transform(contacts): ContactUnion[] | Contact[] {
        if (!contacts) {
            return [];
        }
        if (!Array.isArray(contacts)) {
            return contacts;
        }

        if (contacts && countryExclude.indexOf(this.userSettings.language.toLowerCase()) > -1) {
            return contacts.filter(contact => {
                const name = contact.contact ? contact.contact_display.toLowerCase() : contact.name.toLowerCase();
                if (exclude.indexOf(name) < 0) {
                    return contact;
                }
            });
        }

        return contacts;
    }
}
