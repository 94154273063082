import { environment } from '@env/environment';
import { contactsIcon } from '@app/core/constants/image.constants';

export function fileToBase64(file: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}

export function getNoAvatarLink(): string {
    return 'assets/images/profile/noavatar.png';
}

export function getContactIconName(media: string): string {
    const contacts = {
        facebook: contactsIcon.facebook,
        vk: contactsIcon.vk,
        instagram: contactsIcon.instagram,
        skype: contactsIcon.skype,
        whatsapp: contactsIcon.whatsapp,
        phone: contactsIcon.phone,
        email: contactsIcon.email,
        web: contactsIcon.web,
        default: { src: 'reader-outline' },
    };

    return contacts[media.toLowerCase()].src ?? contacts.default.src;
}
