<ion-card class="ion-no-margin ion-no-padding card">
    <ion-card-content>
        <!--    <ion-card-header class="header"  *ngIf="!service.is_enabled">-->
        <!--        <ion-card-title>-->
        <!--            <ion-row class="ion-align-items-center ion-no-padding ion-margin-vertical warning-row">-->
        <!--                <ion-icon color="warning" name="information-circle"></ion-icon>-->
        <!--                <span class="warning-text ion-padding-start">{{-->
        <!--                    'master-profile.services.service-edit.not-published' | translate-->
        <!--                }}</span>-->
        <!--            </ion-row>-->
        <!--        </ion-card-title>-->
        <!--    </ion-card-header>-->

        <app-info-row *ngIf="!service.is_enabled" [state]="'warning'" class="warning-row">
            {{ 'master-profile.services.service-edit.not-published' | translate }}
        </app-info-row>

        <ion-item class="title-text" lines="none" *ngIf="service.price">
            <app-service-title [service]="service"
                               [activeLink]="!preview"
                               [link]="['/service', canEdit ? service.id + '/edit' : service.id]"></app-service-title>
        </ion-item>
        <ion-item lines="none" class="desc" *ngIf="service.description">
            <app-shorten [text]="service.description" [max]="100"></app-shorten>
        </ion-item>

        <app-image-carousel
            *ngIf="photos || photoFiles"
            class="photos-size ion-margin-vertical"
            [photos]="photos"
            [files]="photoFiles"
            [editable]="false"
            justify="ion-justify-content-start"
        ></app-image-carousel>

        <div lines="none" class="button-container" *ngIf="!preview">
            <ion-button
                *ngIf="!service.is_enabled && !preview"
                class="book-btn ion-no-margin"
                disabled="true"
                color="medium"
                size="medium"
                fill="solid"
            >
                {{ 'master-profile.services.service-edit.book' | translate }}
            </ion-button>
            <ng-container *ngIf="redirect">
                <ion-button
                    (click)="book()"
                    [routerLink]="getServiceOrderUrl(service)"
                    [queryParams]="queryParams"
                    *ngIf="service.is_enabled && !preview"
                    class="book-btn ion-no-margin"
                    color="primary"
                    size="medium"
                    fill="solid"
                    [id]="'bookService-'+service.id"
                >
                    {{ 'master-profile.services.service-edit.book' | translate }}
                </ion-button>
            </ng-container>
            <ng-container *ngIf="!redirect">
                <ion-button
                    (click)="select()"
                    *ngIf="service.is_enabled && !preview"
                    class="book-btn ion-no-margin"
                    color="primary"
                    size="medium"
                    fill="solid"
                    [id]="'bookService-'+service.id"
                >
                    {{ 'master-profile.services.service-edit.book' | translate }}
                </ion-button>
            </ng-container>
        </div>
    </ion-card-content>
</ion-card>
