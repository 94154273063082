import { Injectable } from '@angular/core';
import { Profile, UserLocation } from '@app/api/models';
import * as CurrentUserActions from '@app/store/current-user/current-user.actions';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import UserLocationSelectors from '@app/store/current-user/user-locations/user-locations.selectors';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class UserManagerService {
    @Select(CurrentUserSelectors.isAuthenticated) //@ts-ignore
    public isAuthenticated$: Observable<boolean>;

    @Select(CurrentUserSelectors.profile) //@ts-ignore
    public profile$: Observable<Profile>;

    @Select(UserLocationSelectors.defaultLocation) //@ts-ignore
    public defaultLocation$: Observable<UserLocation>;

    public getCurrentUser(): Observable<Profile> {
        return this.profile$.pipe(first());
    }

    @Dispatch()
    public updateUser(profile: Partial<Profile>): CurrentUserActions.UpdateProfile {
        return new CurrentUserActions.UpdateProfile(profile);
    }
}
