<div class="header-toolbar-wrap" [class.separator]="hasSeparator">
    <ion-toolbar mode="ios">
        <ion-buttons slot="start">
            <ng-container *ngIf="customBack; else mainBackBtn">
                <ion-button (click)="back.emit()">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </ion-button>
            </ng-container>
            <ng-content select="[start]"></ng-content>
        </ion-buttons>
        <ion-title>
            <ng-content></ng-content>
        </ion-title>
        <ng-content select="[content]"></ng-content>
        <ion-buttons slot="end">
            <ng-content select="[end]"></ng-content>
            <ion-button (click)="discard.emit()" *ngIf="hasDiscard">
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</div>

<ng-template #mainBackBtn>
    <ion-button *ngIf="canGoBack$ | async" (click)="navigateBack()">
        <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>
</ng-template>
