import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch-selector',
  templateUrl: './switch-selector.component.html',
  styleUrls: ['./switch-selector.component.scss'],
})
export class SwitchSelectorComponent {
    @Input() public position = '';
    @Input() public masterText = '';
    @Input() public clientText = '';
    @Output() public updatePillPosition = new EventEmitter<string>();

    public segmentChanged($event: any): void {
        this.updatePillPosition.emit($event.detail.value);
    }
}
