import { Component, Input } from '@angular/core';
import { DarkModeService } from '@app/core/services';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AnalyticEventsEnum, AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { MainMenuItem, mainMenuItems } from './main-menu';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
    @Input() public isAuthenticated: boolean;
    @Input() public isMaster: boolean;
    public jobsBoard = {
        title: 'layout.jobs-board',
        icon: 'search-outline',
    };
    public mainMenuItems = mainMenuItems;
    public darkTheme$: Observable<boolean>;
    public jobSearch = 'search-for-orders';

    constructor(
        private readonly platform: Platform,
        private readonly activatedRoute: ActivatedRoute,
        private readonly analytics: AnalyticsService,
        private readonly darkModeService: DarkModeService) {
        this.darkTheme$ = darkModeService.darkTheme$;
    }

    public isShown(item: MainMenuItem): boolean {
        if (item.guestOnly && this.isAuthenticated) {
            return false;
        }

        if (item.desktopOnly && !this.platform.is('desktop')) {
            return false;
        }

        if ((item.userOnly || item.masterOnly || item.clientOnly) && !this.isAuthenticated) {
            return false;
        }

        if (item.clientOnly && this.isMaster) {
            return false;
        }

        return !(item.masterOnly && !this.isMaster);
    }

    public changeTheme(event: CustomEvent): void {
        const toggle = event.target as HTMLIonToggleElement;
        this.darkModeService.setMode(toggle.checked);
    }

    public menuClick(item: MainMenuItem): void {
        switch (item.title) {
            case 'main-menu.search':
                this.analytics.track(AnalyticEventsEnum.SearchServices);
                break;
            case 'main-menu.search-for-orders':
                this.analytics.track(AnalyticEventsEnum.SearchJobs);
                break;
            case 'main-menu.add-service':
                this.analytics.track(AnalyticEventsEnum.OneMoreServiceEvent);
                break;

            default:
                break;
        }
    }

    public checkActiveLink(link: string): boolean {
        /* eslint-disable */
        return this.activatedRoute?.snapshot['_routerState']?.url === link;
    }
}
