import { NavBranch, NavPath } from '../constants/navigation.constants';

export function getProfessionalProfileUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Professional}/${professionalId}/${NavBranch.Profile}` : '';
}

export function getNewProfessionalContactUrl(professionalId: number | string): string {
    return professionalId
        ? `/${NavPath.Professional}/${professionalId}/${NavBranch.Profile}/professional-contact-add`
        : '';
}

export function getNewProfessionalLocationsUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Professional}/${professionalId}/${NavBranch.Profile}/location-add` : '';
}

export function getProfessionalSharePageUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Order}/${NavBranch.Direct}/${professionalId}` : '';
}

export function getProfessionalSharePageServicesListUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Order}/${NavBranch.Direct}/${professionalId}/services` : '';
}

export function getProfessionalServicesUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Professional}/${professionalId}/${NavBranch.Services}` : '';
}

export function getPaymentEnablingUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Professional}/${professionalId}/${NavBranch.EnablePaymentSystem}` : '';
}

export function getPaymentSberUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Professional}/${professionalId}/${NavBranch.Sber}` : '';
}

export function getProfessionalScheduleUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Professional}/${professionalId}/${NavBranch.Schedule}` : '';
}

export function getProfessionalReviewsUrl(professionalId: number | string): string {
    return professionalId ? `/${NavPath.Reviews}/${professionalId}` : '';
}

export function getUserChatUrl(userId: number | string): string {
    return userId ? `/${NavPath.Message}/${NavBranch.Chat}/${userId}` : '';
}

export function getServiceUrl(serviceId: number | string): string {
    return serviceId ? `/${NavPath.Service}/${serviceId}` : '';
}

export function getServiceOrderUrl(serviceId: number | string): string {
    return serviceId ? `/${NavPath.Order}/${serviceId}` : '';
}

export function getServiceCreatedUrl(serviceId: number | string): string {
    return serviceId ? `/${NavPath.Service}/${serviceId}/${NavBranch.Success}` : '';
}
