import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable, forkJoin, from, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { configuration } from '@conf/configuration';
import { TranslateService } from '@ngx-translate/core';
import { ProfessionalsService } from '@app/api/services';
import { City } from '@app/api/models';
import { CitiesApiCache } from '@app/core/services/cache';
import { DeclensionUniversalPipe } from '@app/shared/pipes';
import { Subcategory } from '@app/service/components/tags-step-component/tags.service';


const MAIN_LANGS = ['ru', 'en'];

@Injectable({
    providedIn: 'root'
})
export class SEOService {
    constructor(
        public readonly translator: TranslateService,
        private readonly meta: Meta,
        private readonly title: Title,
        private readonly citiesApi: CitiesApiCache,
        private readonly professionalsService: ProfessionalsService,
        private readonly diclensionPipe: DeclensionUniversalPipe,
    ) { }

    public addMetaTags(): void {
        this.translator.stream('head.description')
            .subscribe(t => this.meta.updateTag({
                name: 'description',
                content: t.replace('{{ app }}', configuration.app_title)
            }));
    }

    public setPrerender404MetaTags(): void {
        this.meta.addTag({ name: 'robots', content: 'noindex' });
        this.meta.addTag({ name: 'prerender-status-code', content: '404' });
    }

    public removePrerender404MetaTags(): void {
        this.meta.removeTag('name = "robots"');
        this.meta.removeTag('name = "prerender-status-code"');
    }

    public blogMeta(description: string): void {
        this.meta.updateTag({ name: 'description', content: description });
    }

    public updateMetaTags(): void {
        this.meta.updateTag({ name: 'description', content: 'Updated: Title and Meta tags examples' });
        this.meta.updateTag({ httpEquiv: 'Content-Type', content: 'application/json' }, 'httpEquiv= "Content-Type"');
        this.meta.updateTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW' });
        this.meta.updateTag({ name: 'keywords', content: 'JavaScript, Angular' });
        this.meta.updateTag({ name: 'date', content: '2018-06-03', scheme: 'YYYY-MM-DD' });
        this.meta.updateTag({ name: 'author', content: 'VXYZ' });
        this.meta.updateTag({ charset: 'UTF-16' }, 'charset= "UTF-8"');
        this.meta.updateTag({ property: 'og:title', content: 'My Text2' });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
    }

    public removeMetaTags(): void {
        //Using removeTag
        this.meta.removeTag('name = "description"');
        this.meta.removeTag('name= "keywords"');
        this.meta.removeTag('name = "viewport"');
        this.meta.removeTag('name = "robots"');

        //Using removeTagElement
        const author: HTMLMetaElement = this.meta.getTag('name = "author"') ?? {} as HTMLMetaElement;
        this.meta.removeTagElement(author);
        const date: HTMLMetaElement = this.meta.getTag('name = "date"') ?? {} as HTMLMetaElement;
        this.meta.removeTagElement(date);
        const contentType: HTMLMetaElement = this.meta.getTag('httpEquiv = "Content-Type"') ?? {} as HTMLMetaElement;
        this.meta.removeTagElement(contentType);
        const charset: HTMLMetaElement = this.meta.getTag('charset') ?? {} as HTMLMetaElement;
        this.meta.removeTagElement(charset);
        const ogTitle: HTMLMetaElement = this.meta.getTag('property = "og:title"') ?? {} as HTMLMetaElement;
        this.meta.removeTagElement(ogTitle);
        const ogType: HTMLMetaElement = this.meta.getTag('property = "og:type"') ?? {} as HTMLMetaElement;
        this.meta.removeTagElement(ogType);
    }


    public addSearchPageMetaTags(text: string): void {
        this.title.setTitle(text);
        if (this.meta.getTag('name = "description"') !== null) {
            this.meta.updateTag({ name: 'description', content: text });
        } else {
            this.meta.addTag({ name: 'description', content: text });
        };
    }

    public setDefaultMetaDescription(language: string): void {
        if (language) {
            from(import(`src/assets/i18n/${language}.json`)).pipe(
                tap(response => {
                    if (response.head.description) {
                        const text = response.head.description.replace('{{ app }}', configuration.app_title);
                        this.meta.updateTag({
                            name: 'description',
                            content: text
                        });
                    };
                }),
                take(1)
            ).subscribe();
        }
    }

    public createMetaTags(
        data: {
            category: string;
            subcategory: string;
            city: number | null;
            language: string;
        }
    ): Observable<any> {
        const language = data.language;

        let preposition$: Observable<string>;
        let cityName$: Observable<string | null>;
        // TODO: Check if the subcategory is zero
        const serviceTitle$ = !!+data.subcategory ? this.professionalsService
            .professionalsSubcategoriesRead(+data.subcategory)
            .pipe(map((response: Subcategory) => response.name)): of('');

        if (language && MAIN_LANGS.includes(language)) {
            preposition$ = from(import(`src/assets/i18n/${language}.json`))
                .pipe(map(config => config['preposition-of-place']));
        } else {
            preposition$ = of('-');
        };

        if (data.city) {
            cityName$ = this.citiesApi
                .getByEntityId(data.city)
                .pipe(map((city: City) => city.name));
        } else {
            cityName$ = of(null);
        };

        return forkJoin([
            preposition$,
            serviceTitle$,
            cityName$
        ])
            .pipe(
                map(([
                    preposition,
                    serviceTitle,
                    cityName
                ]) => ({
                    preposition,
                    serviceTitle,
                    cityName
                })),
                map((forkedData: any) => {
                    let declension = null;
                    if (
                        forkedData.serviceTitle &&
                        forkedData.cityName
                    ) {
                        declension = this.diclensionPipe.combineMetaText({
                            language: language,
                            preposition: forkedData.preposition,
                            serviceTitle: forkedData.serviceTitle,
                            cityName: forkedData.cityName
                        });
                    };

                    return declension;
                }),
                map((dec: string) => dec
                    ? of(this.addSearchPageMetaTags(dec))
                    : this.setDefaultMetaDescription(language)
                )
            );
    }

}
