import { CalendarComponentState } from '@app/shared/components/calendar-component/state/calendar-component-state';
import { CalendarUnit } from '@app/shared/components/calendar-component/calendar-unit';
import { configuration } from '@conf/configuration';
import { CalendarInterval } from '@app/shared/components/calendar-component/calendar-interval';
import { Subject } from 'rxjs';
import { DatePicked } from '@app/shared/components/calendar-component/state/date-picked';
import { CalendarViewState } from '@app/shared/components/calendar-component/state/calendar-view-state';
import { CalendarService } from '@app/shared/components/calendar-component/calendar.service';
import { Selection } from '@app/shared/components/calendar-component/state/selection';
import { FlexibleIteration } from '@app/shared/components/calendar-component/state/flexible-iteration';

export class FlexibleState implements CalendarComponentState {

    public picked = new Subject<DatePicked>();
    private lastUnit: CalendarUnit = null;
    private closeUnit: CalendarUnit = null;
    private intervalStart: Date;
    private intervalEnd: Date;

    constructor(private calendar: CalendarService) {
    }

    public setDatetime(unit: CalendarUnit | null, flexibleServiceLen = 0): void {
        if (!unit) {
            return this.deselect(flexibleServiceLen);
        }
        switch (this.getSelectionState(unit)) {
            case Selection.Deselection:
                this.deselect(flexibleServiceLen);
                break;
            case Selection.Single:
                this.singleSelect(unit, flexibleServiceLen);
                break;
            case Selection.Interval:
                this.intervalSelect(unit);
                break;
            default:
                break;
        }
    }

    public getTimetable(): CalendarInterval[] {
        return this.calendar.timetable;
    }

    public setTimetable(timetable: CalendarInterval[]): void {
        this.calendar.timetable = timetable;
    }

    public deselect(serviceLen: number): void {
        this.calendar.setDefaultState(serviceLen);
        this.intervalStart = null;
        this.intervalEnd = null;
        this.picked.next(null);
    }

    public singleSelect(unit: CalendarUnit, serviceLen: number): void {
        this.deselect(serviceLen);
        this.intervalStart = unit.datetime;
        this.intervalEnd = null;
        this.generateIntervalStart(unit.datetime, serviceLen);
        this.picked.next(null);
    }

    public intervalSelect(unit: CalendarUnit): void {
        this.intervalEnd = unit.datetime;
        this.generateIntervalEnd(unit);
        this.picked.next({
            state: CalendarViewState.Flexible,
            start: this.intervalStart,
            end: this.intervalEnd
        });
    }

    private generateIntervalEnd(unit: CalendarUnit): void {
        this.calendar.timetable.forEach(data => {
            data.units.forEach(u => {
                const t = u.datetime.getTime();
                if (t >= this.intervalStart.getTime() && t <= unit.datetime.getTime()) {
                    u.interval = {selected: true};
                } else {
                    u.interval = undefined;
                }
                if (unit.datetime.getTime() === this.lastUnit.datetime.getTime()) {
                    unit = this.lastUnit;
                }

                if (this.closeUnit?.datetime === u?.datetime) {
                    unit = {...this.closeUnit};
                }
            });
        });
    }

    private generateIntervalStart(selectedTime: Date, serviceLen: number): void {
        let isStart = false;
        let index = 0;
        let previousValue: CalendarUnit = null;
        for (const calendarInterval of this.calendar.timetable) {
            for (const unit of calendarInterval.units) {
                switch (this.getFlexibleIterator(previousValue, unit, selectedTime)) {
                    case FlexibleIteration.Skip:
                        unit.interval = { ...previousValue.interval };
                        continue;
                    case FlexibleIteration.Start:
                        isStart = true;
                        break;
                    default:
                        break;
                }

                if (isStart) {
                    if (index >= serviceLen || unit.datetime === selectedTime) {
                        index = 0;
                        this.lastUnit = unit;
                        unit.interval = {
                            start: true,
                            selected: unit.datetime === selectedTime,
                        };
                        unit.close = false;
                    } else {
                        unit.interval = {
                            disabled: true
                        };
                    }

                    index += configuration.default_calendar_interval;
                    if (!unit.enabled) {
                        this.closeUnit = {...unit};
                        unit.enabled = true;

                        return;
                    }
                } else {
                    unit.interval = {
                        disabled: true
                    };
                }
                previousValue = unit;
            }
        }
    }

    private getSelectionState(unit: CalendarUnit): Selection {
        if (this.intervalStart?.getTime() === unit.datetime.getTime()
            || this.intervalEnd?.getTime() === unit.datetime.getTime()) {
            return Selection.Deselection;
        } else if (!this.intervalStart || (this.intervalStart && this.intervalEnd)) {
            return Selection.Single;
        } else if (this.intervalStart && unit?.interval?.start) {
            return Selection.Interval;
        } else {
            return Selection.Deselection;
        }
    }

    private getFlexibleIterator(previous: CalendarUnit, unit: CalendarUnit, selectedTime: Date): FlexibleIteration {
        if(previous?.datetime.getTime() === unit.datetime.getTime()) {
            return FlexibleIteration.Skip;
        }
        if(unit.datetime === selectedTime) {
            return FlexibleIteration.Start;
        }

        return FlexibleIteration.Continue;
    }
}
