<ion-buttons>
    <ion-button>
        <ion-icon name="logo-facebook"></ion-icon>
    </ion-button>
    <ion-button>
        <ion-icon name="logo-instagram"></ion-icon>
    </ion-button>
    <ion-button>
        <ion-icon name="logo-vk"></ion-icon>
    </ion-button>
    <ion-button>
        <ion-icon name="logo-linkedin"></ion-icon>
    </ion-button>
    <ion-button>
        <ion-icon name="logo-twitter"></ion-icon>
    </ion-button>
    <ion-button>
        <ion-icon name="logo-youtube"></ion-icon>
    </ion-button>
</ion-buttons>
