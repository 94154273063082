<ng-container *ngIf="currency$ | async as currency">
    <ion-row>
        <ion-col class="ion-no-padding" size="9">
            <ion-item lines="none" class="ion-item-bordered ion-margin-end">
                <ng-container *ngIf="value.is_price_fixed; else priceInterval">
                    <ion-label
                        position="stacked">{{ 'service-publish.step-two.fixed-price-title' | translate }}</ion-label>
                    <ion-input type="number" min="0" [value]="value.price"
                               (ionChange)="changeField('price', $event.detail.value)">
                    </ion-input>
                </ng-container>
                <ng-template #priceInterval>
                    <ion-label position="stacked">
                        {{ 'service-publish.step-two.start-price' | translate }}
                    </ion-label>
                    <ion-input
                        type="number" min="0"
                        [value]="value.start_price"
                        (ionChange)="changeField('start_price', $event.detail.value)"
                    >
                    </ion-input>
                    <ion-label position="stacked">
                        {{ 'service-publish.step-two.end-price' | translate }}
                    </ion-label>
                    <ion-input
                        type="number" min="0"
                        [value]="value.end_price"
                        (ionChange)="changeField('end_price', $event.detail.value)">
                    </ion-input>
                </ng-template>
            </ion-item>
        </ion-col>
        <ion-col class="ion-no-padding" size="3">
            <ion-item lines="none" class="ion-item-bordered item-selectable ion-item-no-side-padding">
                <ionic-selectable
                    slot="end"
                    itemValueField="currency"
                    itemTextField="sign"
                    [formControl]="rateControl"
                    [canSearch]="true"
                    [items]="currency.list"
                    closeButtonText="{{ 'ion-selectable.close-button-text' | translate }}"
                    searchFailText="{{ 'ion-selectable.search-fail-text' | translate }}"
                    searchPlaceholder="{{ 'ion-selectable.search-text' | translate }}"
                    [searchPlaceholder]="'global.titles.search' | translate"
                    [clearButtonText]="'global.clear' | translate"
                    [searchFailText]="'ion-selectable.search-fail-text' | translate">
                </ionic-selectable>
            </ion-item>
        </ion-col>
    </ion-row>
    <ng-content select="[errors]"></ng-content>
    <ion-item lines="none" class="ion-item-no-side-padding">
        <ion-checkbox
            slot="start"
            [checked]="value.is_price_fixed"
            (ionChange)="toggleFixedPrice($event)"
        ></ion-checkbox>
        <label>{{ 'service-publish.step-two.fixed-price' | translate }}</label>
    </ion-item>
</ng-container>
