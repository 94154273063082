import { Country, UserLocation } from '@app/api/models';
import { Selector } from '@ngxs/store';
import { ResolvedUserLocation } from '@app/core/interfaces/user-location.interface';
import { UserLocationState, UserLocationStateModel } from './user-locations.state';

export const emptyLocation = {
    country: void 0,
    city: void 0,
};

export default class UserLocationSelectors {
    @Selector([UserLocationState])
    public static locations(data: UserLocationStateModel): UserLocation[] {
        return data.savedLocations ?? [];
    }

    @Selector([UserLocationState])
    public static guessedResolvedLocation(data: UserLocationStateModel): ResolvedUserLocation | undefined {
        return data.resolvedGuessedLocation;
    }

    @Selector([UserLocationState])
    public static defaultLocation(data: UserLocationStateModel): UserLocation {
        // @ts-ignore TODO: fix this shit
        return data.savedLocations?.filter(l => l.is_default)?.pop() ?? emptyLocation as UserLocation;
    }

    @Selector([UserLocationSelectors.locations])
    public static additionalLocations(locations: UserLocation[]): UserLocation[] {
        return locations.filter(l => !l.is_default);
    }

    @Selector([UserLocationSelectors.defaultLocation])
    public static defaultCountry(data: UserLocation): Country['id'] {
        return data?.country;
    }
}
