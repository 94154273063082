import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostListener,
    Inject, Input, OnDestroy,
    Renderer2,
} from '@angular/core';
import { SpellCheckerService } from '@app/api/services/spell-checker.service';
import { IonInput, IonTextarea, PopoverController } from '@ionic/angular';
import { SpellCheckerComponent } from '@app/shared/components/spell-checker/spell-checker.component';

@Directive({
    selector: '[appSpellChecker]',
})
export class SpellCheckerDirective implements AfterViewInit, AfterViewChecked, OnDestroy {
    @Input() public lang = 'ru';

    private textarea!: ElementRef<IonTextarea | IonInput>;
    private readonly sourceRenderer: Renderer2;
    private readonly sourceElementRef: ElementRef<IonTextarea | IonInput>;
    private err = false;
    private lock = false;
    private hintListObject = {};
    private buffContent = '';
    private div: any;

    constructor(@Inject(ElementRef) elementRef: ElementRef<IonTextarea | IonInput>,
                @Inject(Renderer2) renderer: Renderer2,
                private readonly cd: ChangeDetectorRef,
                public popoverController: PopoverController,
                private readonly spellChecker: SpellCheckerService) {
        this.sourceRenderer = renderer;
        this.sourceElementRef = elementRef;
    }

    public ngOnDestroy(): void {
        this.sourceRenderer.destroy();
    }

    public ngAfterViewInit(): void {
        this.addAttributes();
    }

    public ngAfterViewChecked(): void {
        if(!!this.textarea.nativeElement.value && !this.div.innerHTML) {
            this.checkAllText(this.textarea.nativeElement.value as string);
        }
    }

    @HostListener('mousedown', ['$event'])
    public async clickout(event): Promise<void> {
        if(this.err) {
            if (event.target.className === 'spell-error') {
                await this.presentPopover(event.target.id);
            } else {
                this.div.style['z-index'] = '-1';
                setTimeout(() => {
                    this.textarea.nativeElement.setFocus();
                }, 0);
            }
        }
    }

    @HostListener('ionChange', ['$event'])
    public onChange({ detail }: any): void {
        this.checkAllText(detail.value);
    }

    @HostListener('focusout', ['$event'])
    public ionFocus(event: any): void {
        this.div.style['z-index'] = '1';
    }

    public async presentPopover(id: string): Promise<void> {
        this.lock = true;
        if (!Object.keys(this.hintListObject).length || Object.keys(this.hintListObject).indexOf(id) < 0) {
            this.lock = false;

            return;
        }
        const popover = await this.popoverController.create({
            component: SpellCheckerComponent,
            reference: 'trigger',
            trigger: id,
            componentProps: {
                hints: this.hintListObject[id],
                dismissOnSelect: true,
            },
        });
        await popover.present();
        popover.onDidDismiss().then(({ data }) => {
            if (data) {
                this.buffContent = this.buffContent
                    .replace(`<span class="spell-error" id="${id}">${data.word}<\/span>`, data.res);
                const pos = this.hintListObject[id].pos;
                const len = this.hintListObject[id].len;
                let rawString = this.sourceElementRef.nativeElement.value as string;
                rawString =
                    `${rawString.substring(0, pos)}${data.res} ${rawString.substring(pos + len, rawString.length)}`;
                this.sourceElementRef.nativeElement.value = rawString;
            }
            this.lock = false;
        });
    }

    private checkAllText(text: string): void {
        this.err = false;
        this.sourceRenderer.setProperty(this.div, 'innerHTML', text);

        if (text.length > 3) {
            this.spellChecker.checkText(text, this.lang).subscribe((d) => {
                if (d.length) {
                    this.hintListObject = {};
                    d.reverse().forEach((err, i) => {
                        text = this.insertAtIndex(text, '</span>', err.pos + err.len);
                        text = this.insertAtIndex(text,
                            '<span class="spell-error"'+
                            `id="spellerr-${ this.sourceElementRef.nativeElement.name }-${i}">`, err.pos);
                        this.hintListObject[`spellerr-${ this.sourceElementRef.nativeElement.name }-${i}`] = err;
                        this.err = true;
                    });
                    this.buffContent = text.replace('\n', '<br/>');
                    this.sourceRenderer.setProperty(this.div, 'innerHTML', this.buffContent);
                }
            });
        }
        this.err = true;
    }

    private insertAtIndex(str, substring, index): string {
        return str.slice(0, index) + substring + str.slice(index);
    }

    private addAttributes(): void {
        this.div = this.sourceRenderer.createElement('div');
        this.sourceRenderer.appendChild(this.sourceElementRef.nativeElement, this.div);
        this.textarea = this.sourceElementRef;
        this.sourceRenderer.setAttribute(this.sourceElementRef.nativeElement, 'auto-grow', 'true');
        this.sourceRenderer
            .setAttribute(this.sourceElementRef.nativeElement,                'class',
                `${(this.sourceElementRef.nativeElement as any).classList  } textarea-spell-checker-container`);
        this.sourceRenderer
            .setAttribute(this.sourceElementRef.nativeElement, 'autocapitalize', 'sentences');
        this.sourceRenderer.setStyle(this.sourceElementRef.nativeElement, 'overflow', 'visible');
        this.sourceRenderer.setAttribute(this.div, 'class', 'textarea-spell-checker');
        this.sourceRenderer
            .setAttribute((this.sourceElementRef.nativeElement as any).parentNode, 'class',
                `${(this.sourceElementRef.nativeElement as any).parentNode.classList  } item-spell-checker`);
    }
}
