import { Component, Input } from '@angular/core';
import { configuration } from '@conf/configuration';

@Component({
    selector: 'app-service-publish-agreement',
    templateUrl: './service-publish-agreement.component.html',
    styleUrls: ['./service-publish-agreement.component.scss'],
})
export class ServicePublishAgreementComponent {
    @Input() public button!: string;
    public readonly configuration = configuration;
}
