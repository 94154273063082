import { Injectable } from '@angular/core';
import { Contact } from '@app/api/models';
import { ContactsService } from '@app/api/services';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class ContactsApiCache {
    private readonly cache = new Map<string, Observable<Contact>>();
    private readonly listCache$: Observable<Contact[]>;
    private listByCountryCache$?: Observable<Contact[]>;
    private countryId?: number;

    constructor(private readonly contactsService: ContactsService) {
        this.listCache$ = this.contactsService.contactsContactsList({}).pipe(
            map(({ results }) => results),
            shareReplay(1),
        );
    }

    public list(): Observable<Contact[]> {
        return this.listCache$;
    }

    public listByCountry(countryId: number): Observable<Contact[]> {
        if (countryId !== this.countryId) {
            this.countryId = countryId;
            this.listByCountryCache$ = this.contactsService.contactsContactsList({ byCountry: countryId }).pipe(
                map(({ results }) => results),
                shareReplay(1),
            );
        }

        return this.listByCountryCache$ as Observable<Contact[]>;
    }

    public listDefaultByCountry(countryId: number): Observable<Contact[]> {
        return this.listByCountry(countryId).pipe(
            map(contacts => contacts
                .filter(contact => Boolean(contact.is_default))));
    }

    public listSharableByCountry(countryId: number): Observable<Contact[]> {
        if (countryId !== this.countryId) {
            this.countryId = countryId;
            this.listByCountryCache$ = this.contactsService.contactsContactsList({
                byCountry: countryId,
                isDefault: true,
                isSharable: true
            }).pipe(
                map(({ results }) => results),
                shareReplay(1),
            );
        }

        return this.listByCountryCache$ as Observable<Contact[]>;
    }

    public getByEntityId(id: string): Observable<Contact> {
        if (!this.cache.has(id)) {
            this.cache.set(id, this.read(id).pipe(shareReplay(1)));
        }

        return this.cache.get(id) as Observable<Contact>;
    }

    private read(id: string): Observable<Contact> {
        return this.contactsService.contactsContactsRead(parseInt(id, 10));
    }
}
