import { Injectable } from '@angular/core';
import { UserLocation } from '@app/api/models';
import { AccountsService, LocationService } from '@app/api/services';
import { ResolvedUserLocation } from '@app/core/interfaces/user-location.interface';
import { Storage } from '@ionic/storage';
import { CurrentLocationService } from '@app/core/services/location/current-location.service';
import { Action, State, StateContext } from '@ngxs/store';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { DefaultSettingsDetectorService } from '@app/core/services/default-settings-detector.service';
import * as UserLocationActions from './user-locations.actions';

const USER_LOCATION_STORAGE_KEY = 'user_location';

export const emptyUserLocationState: UserLocationStateModel = {};

export interface UserLocationStateModel {
    guessedLocation?: UserLocation;
    savedLocations?: UserLocation[];
    resolvedGuessedLocation?: ResolvedUserLocation;
}

interface UserLocationStorageModel {
    location: ResolvedUserLocation;
}

@Injectable()
@State<UserLocationStateModel>({
    name: 'UserLocation',
    defaults: emptyUserLocationState,
})
export class UserLocationState {
    constructor(
        private readonly accountsService: AccountsService,
        private readonly currentLocationService: CurrentLocationService,
        private readonly storage: Storage,
        private readonly settingsUpdater: DefaultSettingsDetectorService,
        private readonly locationApi: LocationService,
    ) {}

    @Action(UserLocationActions.LoadLocations)
    public loadLocations({ patchState }: StateContext<UserLocationStateModel>): Observable<any> {
        return this.accountsService.accountsLocationsList({}).pipe(
            tap(({ results }) => patchState({ savedLocations: results })),
        );
    }

    @Action(UserLocationActions.LoadAllUserLocations)
    public loadAllUserLocations({ dispatch }: StateContext<UserLocationStateModel>): Observable<any> {
        return dispatch([
            new UserLocationActions.LoadLocations(),
            new UserLocationActions.LoadGuessedLocation(),
        ]);
    }

    @Action(UserLocationActions.LoadGuessedLocation)
    public loadGuessedLocation({ patchState }: StateContext<UserLocationStateModel>): Observable<any> {
        return from(this.storage.get(USER_LOCATION_STORAGE_KEY)).pipe(
            tap((l: UserLocationStorageModel) => {
                if (l?.location?.country && l?.location?.city) {
                    patchState({ resolvedGuessedLocation: { country: l.location.country, city: l.location.city } });
                } else {
                    patchState({ resolvedGuessedLocation: { country: null, city: null } });
                }
            }),
        );
    }

    @Action(UserLocationActions.CreateUserLocation)
    public createUserLocation(
        { dispatch }: StateContext<UserLocationStateModel>,
        { location }: UserLocationActions.CreateUserLocation,
    ): Observable<any> {
        return this.accountsService.accountsLocationsCreate(location).pipe(
            mergeMap(() => dispatch(new UserLocationActions.LoadLocations())),
        );
    }

    @Action(UserLocationActions.UpdateUserLocation)
    public updateUserLocation(
        { dispatch }: StateContext<UserLocationStateModel>,
        { location }: UserLocationActions.UpdateUserLocation,
    ): Observable<any> {
        return this.accountsService
            .accountsLocationsUpdate({ id: location.id, data: location })
            .pipe(mergeMap(() => dispatch(new UserLocationActions.LoadLocations())));
    }

    @Action(UserLocationActions.DeleteUserLocation)
    public deleteUserLocation(
        { dispatch }: StateContext<UserLocationStateModel>,
        { locationId: locationIdToDelete }: UserLocationActions.DeleteUserLocation,
    ): Observable<any> {
        return this.accountsService.accountsLocationsDelete(locationIdToDelete).pipe(
            mergeMap(() => dispatch(new UserLocationActions.LoadLocations())),
        );
    }

    @Action(UserLocationActions.UpdateResolvedLocation)
    public updateResolvedLocation(
        { patchState, getState }: StateContext<UserLocationStateModel>,
    ): Observable<any> {
        const state = getState()?.resolvedGuessedLocation;
        if (state && state?.country && state?.city) {
            return forkJoin({
                country: !!state?.country?.id ? this.locationApi.locationCountriesRead(state.country.id): of(null),
                city: !!state?.city?.id ? this.locationApi.locationCitiesRead(state.city.id): of(null),
            }).pipe(
                tap(data => patchState({ resolvedGuessedLocation: { country: data.country, city: data.city } })),
                tap(data => this.storage.set(USER_LOCATION_STORAGE_KEY, {location: data})),
            );
        }

        return of(void 0);
    }

    @Action(UserLocationActions.GuessCurrentLocation)
    public guessCurrentLocation({ patchState, dispatch }: StateContext<UserLocationStateModel>): Observable<any> {
        const getSaved$ = from(this.storage.get(USER_LOCATION_STORAGE_KEY)) as Observable<UserLocationStorageModel>;
        const save$: (model: UserLocationStorageModel) => Observable<any> = model =>
            from(this.storage.set(USER_LOCATION_STORAGE_KEY, model));
        const guessAndSave$ = this.currentLocationService
            .guessLocation()
            .pipe(
                mergeMap(location =>
                    (location?.country?.id && location?.city?.id)
                        ? save$({ location }).pipe(map(() => location))
                        : of<ResolvedUserLocation>(null),
                ),
                tap(l => !!l ? void 0 : this.settingsUpdater.updateFromNavigator()),
            );

        return getSaved$.pipe(
            mergeMap(savedModel => (savedModel?.location ? of(savedModel.location) : guessAndSave$)),
            tap(() => dispatch(new UserLocationActions.LoadGuessedLocation())),
            tap(location =>
                patchState({
                    guessedLocation: {
                        country: location?.country?.id,
                        city: location?.city?.id,
                    },
                }),
            ),
        );
    }

    @Action(UserLocationActions.RemoveLocations)
    public removeLocations({ patchState }: StateContext<UserLocationStateModel>): Observable<any> {
        return from(this.storage.get(USER_LOCATION_STORAGE_KEY).then(({location}) => {
            patchState({
                guessedLocation:{country: location?.country?.id, city: location?.city?.id},
                savedLocations:[],
            });
        }));
    }
}
