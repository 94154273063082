import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgDestroyService } from '@app/core/services';

@Component({
    selector: 'app-typeahead',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.scss'],
    providers: [NgDestroyService],
})
export class TypeaheadComponent {

    @Input() public typeaheadList = [];
    @Output() public query = new EventEmitter<string>();
    @Output() public hideList = new EventEmitter<void>();

    constructor(destroy$: NgDestroyService) {
        fromEvent(document, 'click')
            .pipe(
                filter(e => e.target instanceof Element && !e.target?.classList.contains('searchbar-input')),
                takeUntil(destroy$)
            )
            .subscribe(e => {
                if ((e.target as Element)?.classList.contains('typeahead-item')) {
                    this.hideList.emit();
                }
            });
    }

    public setQuery($event: MouseEvent, query: string): void {
        this.typeaheadList = [];
        this.query.emit(query);
        this.hideList.emit();
    }
}
