import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
} from '@angular/forms';
import { configuration } from '@conf/configuration';
import { ScheduleEditorFormFields } from './schedule-editor-form-fields.enum';

const CALENDAR_INTERVAL = configuration.default_calendar_interval;

function getHoursNumber(timeString: string): number {
    return Number.parseInt(timeString.slice(0, 2), 10);
}

function getMinutesNumber(timeString: string): number {
    return Number.parseInt(timeString.slice(3, 5), 10);
}

function getTimeMinutes(group: FormGroup): { startTimeMinutes: number; endTimeMinutes: number } {
    const startTime = group.get(ScheduleEditorFormFields.StartTime).value as string;
    const endTime = group.get(ScheduleEditorFormFields.EndTime).value as string;

    return {
        startTimeMinutes: getHoursNumber(startTime) * 60 + getMinutesNumber(startTime),
        endTimeMinutes: getHoursNumber(endTime) * 60 + getMinutesNumber(endTime),
    };
}

export function timeIntervalValidator(group: FormGroup): ValidationErrors | null {
    const { startTimeMinutes, endTimeMinutes } = getTimeMinutes(group);

    return startTimeMinutes >= endTimeMinutes ? { timeCausalityError: true } : null;
}

export function timeFormatValidator(control: FormControl): ValidationErrors | null {
    const time: string = control.value;
    if (!time) {
        return null;
    }

    return getMinutesNumber(time) % CALENDAR_INTERVAL !== 0
        ? { timeIntervalError: { interval: CALENDAR_INTERVAL } }
        : null;
}

function filterIdenticalDays(controls: AbstractControl[]): AbstractControl[] {
    const indexes = [];
    const result = [];
    controls.forEach(c => {
        if (indexes.includes(c.value.day_of_week)) {
            result.push(c.value.day_of_week);
        }
        indexes.push(c.value.day_of_week);
    });

    return controls.filter(c => result.includes(c.value.day_of_week));
}

function timeOverlap(arr: AbstractControl[]): boolean {
    if (!arr.length) {
        return false;
    }


    const buff = {};
    arr.forEach(c => {
        if (c.enabled) {
            if (!(c.value.day_of_week in buff)) {
                buff[c.value.day_of_week] = { value: [] };
            }
            buff[c.value.day_of_week].value.push(c);
        }
    });
    let result = false;
    Object.keys(buff).forEach(key => {
        const last = buff[key].value.pop();
        const lastTime = getTimeMinutes(last as FormGroup);
        buff[key].value.every(c => {
            const controlTime = getTimeMinutes(c as FormGroup);
            if (lastTime.startTimeMinutes < controlTime.endTimeMinutes &&
                lastTime.endTimeMinutes > controlTime.startTimeMinutes) {
                result = true;
            }
        });
    });

    return result;
}

export function timeOverlapValidator(form: FormArray): ValidationErrors | null {

    if (form.controls.length > 1) {
        const res = filterIdenticalDays(form.controls);

        if (res.length > 1 && timeOverlap(res)) {
            return { timeOverlapError: true };
        }

        return null;
    }

    return null;
}
