<div lines="none" *ngIf="service">
    <ng-container *ngIf="detailsLink; else noLink">
        <a routerLink="{{ detailsLink }}" [queryParams]="queryParams" class="link" [id]="'service-' + service.id">
            {{ service.name }}
        </a>
    </ng-container>
    <ng-template #noLink>
        <span class="link">
            {{ service.name }}
        </span>
    </ng-template>
    <div  class="ion-align-self-start ion-padding-vertical ion-text-wrap price">
        <app-price [price]="orderPrice || service?.price"></app-price>
        <span class="duration">
            &nbsp;/&nbsp;
            <ng-container *ngIf="orderPrice; else serviceDuration">
                <app-duration-viewer [durationObject]="orderTime"></app-duration-viewer>
            </ng-container>
            <ng-template #serviceDuration>
                <app-duration-viewer [duration]="service?.duration"></app-duration-viewer>
            </ng-template>
        </span>
    </div>
</div>
