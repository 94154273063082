<ion-row class="ion-justify-content-between ion-margin-vertical" *ngIf="currentlyViewedDate$.value">
    <ion-button size="medium" class="ion-align-self-center"
                (click)="shiftDate(-1)" [disabled]="shiftBackDisabled() || loading">
        <ion-icon name="chevron-back-outline"></ion-icon>
    </ion-button>
    <div class="col">
        <ion-label color="medium" class="date-label"> {{ 'calendar.picked-date' | translate }} </ion-label>
        <ion-text class="cursor-pointer date-string" color="primary" (click)="pickDate()">
            {{ getSelectedDateStr() }}
        </ion-text>
    </div>
    <ion-button size="medium" class="ion-align-self-center" (click)="shiftDate(1)" [disabled]="loading">
        <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-button>
</ion-row>
<ng-container *ngIf="state?.getTimetable()?.length || loading; else empty">
    <ng-container *ngFor="let interval of state?.getTimetable()">
        <app-section-heading>{{ interval.title }}</app-section-heading>
        <div class="timetable">
            <ion-button
                *ngFor="let unit of interval.units; let i = index"
                class="interval-button"
                mode="ios"
                [fill]="getUnitFill(unit)"
                [color]="getUnitColor(unit)"
                [disabled]="getDisabled(unit)"
                (click)="state.setDatetime(unit,flexibleServiceLen)"
            >
                {{ getButtonText(unit) }}
            </ion-button>
        </div>
    </ng-container>
</ng-container>

<ng-template #empty>
    <app-info-row class="info-row">
        {{ 'calendar.empty' | translate }}
    </app-info-row>
</ng-template>

