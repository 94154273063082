// @ts-nocheck
import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output, ViewChild,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessor } from '@app/core/abstract/filter-value-accessor';
import { NgDestroyService } from '@app/core/services';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-place-selector',
    templateUrl: 'place-selector.component.html',
    styleUrls: ['place-selector.component.scss'],
    providers: [
        NgDestroyService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PlaceSelectorComponent),
            multi: true,
        },
    ]
}) // TODO: fix the whole class
export class PlaceSelectorComponent extends ValueAccessor<number> implements AfterViewInit {
    public get locationTextList(): any[] {
        return this._locationTextList.filter(l => this.requiredAddress ? this.checkAddress(l?.text) : true);
    }
    @Input()
    public set locationTextList(value: any[]) {
        this._locationTextList = value;
        this._bufferTextList = value;
        this.initValue();
    }
    @Input() public requiredAddress = false;
    @Input() public title: string = 'service-details.location.choose-address';
    @Input() public initLocation!: Subject<number>;
    @Output() public addItem = new EventEmitter<void>();
    @Output() public delete = new EventEmitter<number>();
    @Output() public valueIsSet = new EventEmitter<number>();
    @Output() public navigation = new EventEmitter<number>();

    @ViewChild(IonicSelectableComponent, {read: IonicSelectableComponent})
    public readonly selectable!: IonicSelectableComponent;
    public currentLocation = null;
    public formLocation = new FormControl();
    public warningMessage = false;
    public defaultLocation: { id: number; text: string; is_default?: boolean } | null = null;
    private _locationTextList: any[] = [];
    private _bufferTextList: any[] = [];
    private _initLocation;

    constructor(private readonly destroy$: NgDestroyService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.initLocation.pipe(
            tap(d => {
                this._initLocation = d ?? this._initLocation;
                this.initValue();
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    public emptyOpen(): void {
        this.addItem.emit();
    }

    public onClose(): void {
        this.warningMessage = false;
        this.valueIsSet.emit(this.formLocation?.value?.id ?? null);
    }

    public onChangeLocation($event: { component: IonicSelectableComponent; value: any }): void {
        this.valueIsSet.emit($event.value?.id);
    }

    public addItemHandler({component}): void {
        component.close();
        this.addItem.emit();
    }

    public initValue(): void {
        if (!this._locationTextList.length) {
            this.currentLocation = this._locationTextList?.find(l => l.id === this._initLocation);
            this.formLocation.setValue(this.currentLocation);

            return;
        }

        this.defaultLocation = this._locationTextList.find(l => l.is_default);
        let result = this.defaultLocation?.id ?? this._locationTextList[0]?.id;

        if (!!this._initLocation) {
            result = this._initLocation;
        }
        this.currentLocation = this._locationTextList?.find(l => l.id === result);
        this.valueIsSet.emit(this.currentLocation?.id ?? this.currentLocation);
        this.formLocation.setValue(this.currentLocation);
    }

    public open(type: 'online' | 'client' | 'professional'): void {
        switch (type) {
            case 'client':
                if (!this.checkAddress(this.formLocation.value?.text)) {
                    this.checkCurrentLocation();
                }

                break;
            case 'professional':
                if (!this.checkAddress(this.formLocation.value?.text)) {
                    this.checkCurrentLocation();
                }
                break;
            case 'online':
                this.returnValue();
                break;
            default:
                break;
        }
    }

    private checkCurrentLocation(): void {
        this._locationTextList = this._locationTextList.filter(l => this.checkAddress(l.text));
        this.formLocation.reset();
        if(!this._locationTextList.length) {
            this.valueIsSet.emit(null);
        } else {
            this.selectable?.open();
        }
    }

    private returnValue(): void {
        this._locationTextList = [...this._bufferTextList];
        this.formLocation.setValue(this.currentLocation);
        this.valueIsSet.emit(this.currentLocation?.id ?? this.currentLocation);
    }

    private checkAddress(text: string): boolean {
        return (text && text.split(',').length >= 3);
    }
}
