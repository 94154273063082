import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { ProfessionalPlaceSelectorComponent } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/professional-place-selector/professional-place-selector.component';
import { Subject } from 'rxjs';
import { UserPlaceSelectorComponent } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/user-place-selector/user-place-selector.component';

@Component({
    selector: 'app-place-selector-wrapper',
    templateUrl: './place-selector-wrapper.component.html',
    styleUrls: ['./place-selector-wrapper.component.scss'],
}) // TODO: fix the whole class with all dependencies
export class PlaceSelectorWrapperComponent {
    @Input() public title: string = 'service-details.location.choose-address';
    @Input() public state!: 'client' | 'professional';
    @Input() public requiredAddress!: boolean;
    public initLocation = new Subject<number>();
    @Output() public valueIsSet = new EventEmitter<number>();
    public warningMessage = false;
    public required: boolean = false;

    @ViewChild(UserPlaceSelectorComponent, {read: UserPlaceSelectorComponent})
    private readonly userPlaceSelector!: UserPlaceSelectorComponent;

    @ViewChild(ProfessionalPlaceSelectorComponent, {read: ProfessionalPlaceSelectorComponent})
    private readonly professionalPlaceSelector!: ProfessionalPlaceSelectorComponent;

    public initOnEnter(id: number): void {
        this.initLocation.next(id);
        // @ts-ignore don't call child component methods from parent ones
        this.userPlaceSelector?.init();
        // @ts-ignore
        this.professionalPlaceSelector?.init();
    }

    public onClose(): void {
        this.warningMessage = false;
    }

    public checkOpen(type: 'online' | 'client' | 'professional'): void {
        // @ts-ignore
        this.userPlaceSelector?.open(type);
        // @ts-ignore
        this.professionalPlaceSelector?.open(type);
    }

    public setLocationValue($event: number): void {
        this.valueIsSet.emit($event);
    }
}
