<ion-header *ngIf="context$ | async as context">
    <ion-toolbar class="toolbar">
        <ion-buttons slot="start">
            <ion-menu-toggle menu="main-menu">
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>

        <div class="container">
            <ion-item class="item" lines="none" routerLink="/">
            <div *ngIf="longLogo; else mobile" class="long-logo">
                <ion-img [src]="imagePaths.longLogo"></ion-img>
            </div>
            <ng-template #mobile>
                <div class="short-logo">
                    <ion-img [src]="imagePaths.shortLogo"></ion-img>
                </div>
            </ng-template>
            </ion-item>
            <div class="become-professional__wrap" *ngIf="headerButtons">
                <div class="become-professional__empty"></div>
                <div class="become-professional">
                    <ion-item
                        lines="none"
                        data-testid="creative-banners"
                        routerLink="/{{ NavPath.CreativeBanners }}">
                        {{ 'layout.create-order' | translate }}
                    </ion-item>
                    <ion-item
                        lines="none"
                        data-testid="for-professionals"
                        routerLink="/{{ NavPath.ProfessionalLanding }}">
                        {{ 'layout.become-professional' | translate }}
                    </ion-item>
                    <ion-item
                        [routerLink]
                        appAccessToJobsBoard
                        lines="none"
                        data-testid="for-professionals">
                        {{ 'layout.jobs-board' | translate }}
                    </ion-item>
                </div>
            </div>
        </div>

        <ng-container *ngTemplateOutlet="context.isAuthenticated ? hasAuth : unAuth"></ng-container>

        <ion-buttons slot="end" *ngIf="configuration.flagMenu; else other">
            <ion-menu-toggle menu="flag-menu">
                <ion-button *ngIf="currentLanguage$ | async as lang">
                    <ion-icon name="globe-outline"></ion-icon>
                    <ion-label class="ion-padding-horizontal">{{
                        configuration.lang_list?.length > 1 ? ('global.' + lang | translate) : ''
                        }}</ion-label>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ng-template #other>
            <div class="pad" slot="end"></div>
        </ng-template>
    </ion-toolbar>
    <ng-content></ng-content>
</ion-header>

<ng-template #hasAuth>
    <ion-buttons slot="end">
        <ion-button fill="clear" color="dark" slot="end" routerLink="/message">
            <ion-icon name="chatbubbles-outline"></ion-icon>
            <ion-badge *ngIf="countOfUnreadMessages$ | async as count" color="danger">{{ count }}</ion-badge>
        </ion-button>
    </ion-buttons>
</ng-template>

<ng-template #unAuth>
    <ion-buttons slot="end" class="auth-buttons">
        <ion-button routerLink="/auth/login" class="custom-auth-button login">
            {{ 'layout.log-in' | translate }}
        </ion-button>
        <ion-button *ngIf="headerButtons"
                    routerLink="/auth/registration"
                    fill="solid"
                    color="primary"
                    class="custom-auth-button">
            {{ 'layout.sign-up' | translate }}
        </ion-button>
    </ion-buttons>
</ng-template>

<ng-container #dynamic></ng-container>
