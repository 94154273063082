// @ts-nocheck
import { Injectable } from '@angular/core';
import { BaseService as __BaseService } from '@app/api/base-service';
import { ApiConfiguration as __Configuration } from '@app/api/api-configuration';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable as __Observable } from 'rxjs/internal/Observable';
import { StrictHttpResponse as __StrictHttpResponse } from '@app/api/strict-http-response';
import { filter as __filter } from 'rxjs/operators';
import { map as __map } from 'rxjs/internal/operators/map';
import { Spell } from '@app/api/models/spell';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SpellCheckerService extends __BaseService {
    static readonly spellCheckerUrl = environment.yandex_speller;

    constructor(
        config: __Configuration,
        http: HttpClient,
    ) {
        super(config, http);
    }

    public checkText(text: string, lang?: string): __Observable<Spell[]> {
        return this.servicesServicePhotosListResponse(text, lang).pipe(
            __map(_r => _r.body as Spell[]),
        );
    }

    private servicesServicePhotosListResponse(text: string, lang?: string): __Observable<__StrictHttpResponse<Spell[]>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __params = __params.set('text', text);
        __params = __params.set('lang', lang);
        let req = new HttpRequest<any>(
            'GET',
            SpellCheckerService.spellCheckerUrl,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json',
            });

        return this.http.request<Spell[]>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Spell[]>;
            }),
        );
    }
}
