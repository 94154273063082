<ng-container *ngIf="!expanded; else content">
    <ion-item lines="none">
        <ion-label class="cursor-pointer" color="primary" (click)="expandHandler()">
            {{ 'accordion.expand' | translate }}
        </ion-label>
    </ion-item>
</ng-container>

<ng-template #content>
    <ng-content></ng-content>
    <ion-item lines="none">
        <ion-label class="cursor-pointer" color="primary" (click)="expandHandler()">
            {{ 'accordion.collapse' | translate }}
        </ion-label>
    </ion-item>
</ng-template>
