import { FormControl, ValidationErrors } from '@angular/forms';
import { configuration } from '@conf/configuration';

export function minutesValidator(control: FormControl): ValidationErrors | null {
    if (control.value % configuration.calendar_interval !== 0) {
        return { durationError: true };
    }

    return null;
}
