import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appPriceInput]',
})
export class PriceInputDirective {
    private readonly sourceRenderer: Renderer2;
    private readonly sourceElementRef: ElementRef;
    private readonly regex: RegExp = new RegExp(/^[0-9 ]+$/g);
    private readonly specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Renderer2) renderer: Renderer2) {
        this.sourceRenderer = renderer;
        this.sourceElementRef = elementRef;
    }

    @HostListener('ngModelChange', ['$event', '$event'])
    // @ts-ignore TODO: refactor it, typings
    public onChange(event, value: string): void {
        if(!event || !value) {
            return;
        }
        value = value.replace( /[^0-9]/g,'');
        this.sourceRenderer.setAttribute(this.sourceElementRef.nativeElement, 'value', this.setSpace(value));
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.sourceElementRef.nativeElement.value || '';
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    private setSpace(value: string): string {
        for (let i = value.length, j = 0; i >= 0; i--, j++) {
            if (j % 3 === 0 && j > 0) {
                value = `${value.slice(0, i)} ${value.slice(i)}`;
            }
        }

        return value;
    }
}
