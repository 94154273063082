<image-cropper
    [imageFile]="image"
    [format]="format"
    [resizeToWidth]="avatarSize"
    [resizeToHeight]="avatarSize"
    [cropperMinWidth]="avatarSize"
    [cropperMinHeight]="avatarSize"
    maintainAspectRatio="1"
    aspectRatio="1"
    onlyScaleDown="1"
    (imageCropped)="onImageCropped($event)"
    (loadImageFailed)="onLoadFailed()"
></image-cropper>

<ion-button expand="full" color="success" (click)="save()">{{ 'global.save' | translate }}</ion-button>
