import { GenderRuleSetT } from '@app/core/declination-cities/models/genders.types';

export default {
    exceptions: {
        neuter: [],
        male: [],
        female: [],
    },
    suffixes: {
        neuter: [
            'щи', // Мытищи
            'ки', // Химки
        ],
        male: [
            'аба',
            'б',
            'ав',
            'ев',
            'ов',
            'во',
            'г',
            'д',
            'ж',
            'з',
            'би',
            'ди',
            'жи',
            'ли',
            'гани',
            'ит',
            'до',
            'айт',
            'юм',
            'ив',
            'ги',
            'улла',
            'улль',
            'фи',
            'аль',
            'ми',
            'бель',
            'була',
            'йль',
            'яй',
            'бо',
            'бу',
            'кр',
            'ыль',
            'ыт',
            'вва',
            'вель',
            'вни',
            'аба',
            'бала',
            'улу',
            'рза',
            'ши',
            'го',
            'но',
            'ет',
            'ят',
            'ре',
            'ри',
            'ай',
            'ей',
            'ий',
            'ой',
            'ый',
            'к',
            'л',
            'ам',
            'ем',
            'им',
            'ом',
            'ум',
            'ым',
            'ям',
            'ан',
            'бен',
            'вен',
            'ген',
            'ден',
            'ин',
            'сейн',
            'он',
            'ун',
            'ян',
            'ио',
            'ло',
            'ро',
            'то',
            'шо',
            'п',
            'ар',
            'др',
            'ер',
            'ир',
            'ор',
            'тр',
            'ур',
            'ыр',
            'яр',
            'ас',
            'ес',
            'ис',
            'йс',
            'кс',
            'мс',
            'ос',
            'нс',
            'рс',
            'ус',
            'юс',
            'яс',
            'ат',
            'кт',
            'нт',
            'рт',
            'ст',
            'ут',
            'ф',
            'х',
            'ш',
            'ы',
            'сь',
            'тау', // Актау, Ерейментау, Каратау, Кокшетау, Кентау
            'ен', // Жанаозен, Каскелен
            'коль', // Акколь
            'рау', // Атырау
            'обе', // Актобе, Уштобе
            'убе',
            'иль', // Есиль, Адиль
            'ево', // Булаево
            'ово', // Медведково
            'шу', // Шу,
            'ду', // Катманду
            'пуль', // Ливерпуль
            'нко', // Ливерпуль
            'су', // Аксу, Карасу
            'вль', // Ярославль
            'оль', // Ставрополь, Севастополь, Симферополь
            'ёв', // город Королёв
            'ём', // Артём
            'ец', // Елец, Череповец
            'сс', // Миасс
            'льс', // Энгельс
            'хт', // Утрехт
            'рн', // Апелдорн
            'дт', // Кронштадт
        ],
        female: [
            'иба',
            'люба',
            'лава',
            'ева',
            'га',
            'да',
            'еа',
            'иза',
            'иа',
            'ика',
            'нка',
            'ска',
            'ела',
            'ила',
            'илла',
            'эла',
            'има',
            'на',
            'ра',
            'са',
            'та',
            'фа',
            'еса',
            'сса',
            'гуль',
            'куль',
            'нуэль',
            'гюль',
            'нэ',
            'ая',
            'ея',
            'ия',
            'йя',
            'ля',
            'мя',
            'оя',
            'ря',
            'ся',
            'вья',
            'лья',
            'мья',
            'нья',
            'рья',
            'сья',
            'тья',
            'фья',
            'зя',
            'нша', // Тайынша
            'ха', // Шемонаиха
            'тка', // Мамлютка
            'нь', // Сарань, Тюмень
            'чь', // Сарань, Керчь
            'мь', // Сарань, Пермь
            'ерь', // Тверь
            'овка', // Покровка
            'евка', // Сергеевка
            'мба', // Эмба
            'зь', // Бизь, Грязь
            'лка',
            'сала',
            'бла',
            'арь',
            'ума',
            'пи',
            'дель',
            'не',
            'жка',
            'си',
            'ибе',
            'абе',
            'елла',
            'ие',
            'фе',
            'фя',
        ],
    },
} as GenderRuleSetT;