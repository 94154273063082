export function skippedLocationNotification(): MethodDecorator {
    // @ts-ignore
    return function (_target: Function, _key: string, descriptor: any): MethodDecorator {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args: any[]) {


            return originalMethod.apply(this, args);
        };

        return descriptor;
    };
}
