<ion-list>
    <ion-item
        *ngFor="let contact of contacts | contactFilter"
        class=""
        [routerLink]="getUrl(contact)"
        [queryParams]="getParams()"
        detail
        detail-icon="create-outline"
    >
        <ion-img class="contact-icon-img" [src]="getContactIcon(contact.contact_code ?? contact.contact_display)"></ion-img>
        <ion-text class="ion-margin-start">
            {{ contact.value || '—' }}
        </ion-text>
    </ion-item>
    <app-add-button [routerLink]="addNewContactUrl" [queryParams]="getParams()">
        {{ 'contact-edit-page.add-new' | translate }}
    </app-add-button>
</ion-list>
