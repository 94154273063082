import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserLanguage } from '@app/api/models';
import { LanguagesApiCache, UserLanguagesApiCache } from '@app/core/services/cache';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/core/operators/take-until-destroyed';

@DecorateUntilDestroy()
@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss'],
})
export class LanguageComponent {
    public languageName?: string;

    @Input()
    public inline: boolean = false;

    constructor(
        private readonly userLanguagesApiCache: UserLanguagesApiCache,
        private readonly languagesApiCache: LanguagesApiCache,
        private readonly changeDetector: ChangeDetectorRef,
    ) {
    }

    @Input()
    public set language(value: UserLanguage) {
        this.languagesApiCache.getByEntityId(value.language)
            .pipe(takeUntilDestroyed(this))
            .subscribe(language => {
                this.languageName = language.name;
                this.changeDetector.markForCheck();
            });
    }

    @Input()
    public set languageId(id: number) {
        this.userLanguagesApiCache.getByEntityId(id)
            .pipe(takeUntilDestroyed(this))
            .subscribe(language => (this.language = language));
    }
}
