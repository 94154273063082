import { Injectable } from '@angular/core';
import { DateStepDataInterface } from '@app/book/interfaces/date-step-data-interface';
import { DatetimeStepDataInterface } from '@app/book/interfaces/datetime-step-data-interface';
import { ClientDetailsStepDataInterface } from '@app/book/interfaces/client-details-step-data-interface';
import { ServiceList } from '@app/api/models/service-list';
import { addMinutes } from '@app/core/functions/datetime.functions';
import { differenceInMinutes } from 'date-fns';

export interface BookingStateIntervalInterface {
    start: Date;
    end: Date;
}

@Injectable({
    providedIn: 'root',
})
export class BookingStateService {
    private data: {
        service?: ServiceList;
        direct?: boolean;
        0?: DateStepDataInterface;
        1?: DatetimeStepDataInterface;
        2?: ClientDetailsStepDataInterface;
    } = {};

    public reset(): void {
        this.data = {};
    }

    public isDirect(): boolean {
        return this.data.direct === undefined ? false : this.data.direct;
    }

    public setIsDirect(v: boolean): void {
        this.data.direct = v;
    }

    public setService(service: ServiceList): void {
        this.data.service = service;
    }

    public getService(): ServiceList | undefined {
        return this.data.service;
    }

    public setDateStep(date: Date): void {
        this.data['0'] = { date };
    }
    public getDateStep(): DateStepDataInterface | null {
        return this.data['0'] ? this.data['0'] : null;
    }

    public setDateTime(datetime: Date): void {
        this.data['1'] = { datetime };
    }
    public getDateTime(): Date | null {
        return this.data['1']?.datetime ? this.data['1'].datetime : null;
    }

    public setInterval(interval: BookingStateIntervalInterface): void {
        this.data['1'] = { interval };
    }

    public getInterval(): BookingStateIntervalInterface | null {
        if(this.data['1']?.interval) {
            const duration = differenceInMinutes(this.data['1']?.interval.end, this.data['1']?.interval.start);
            // @ts-ignore TODO: fix this, can be undefined
            const r = duration % this.data.service.duration;
            if(r !== 0) {
                return {
                    start: this.data['1']?.interval.start,
                    end: addMinutes(this.data['1']?.interval.start, duration - r)
                };
            }

            return this.data['1']?.interval;
        }

        return null;
    }

    public setClientDetails(details: ClientDetailsStepDataInterface): void {
        this.data['2'] = details;
    }
    public getClientDetails(): ClientDetailsStepDataInterface | undefined {
        return this.data['2'];
    }

    public isThirdStepReady(): boolean {
        return !!this.data?.service && (Boolean(this.data['1']?.datetime) || Boolean(this.data['1']?.interval));
    }
}
