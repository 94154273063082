import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { NavQueryParams } from '@app/core/constants/navigation.constants';
import { Observable, of } from 'rxjs';
import { NavController } from '@ionic/angular';
import { WINDOW } from '@app/core/injection-tokens';

@Injectable({
    providedIn: 'root'
})
export class BackableRedirectService {

    constructor(
        private readonly route: ActivatedRoute,
        private readonly navController: NavController,
        @Inject(WINDOW) private readonly window: Window,
    ) {
    }

    public goBackOr(url: string): void {
        this.needToGoBack().pipe(
            take(1),
            tap(back => {
                if (back && this.hasHistory()) {
                    return this.navController.back();
                }
                if (url) {
                    this.navController.navigateBack(url);
                }
            })
        ).subscribe();
    }

    private needToGoBack(): Observable<boolean> {
        return this.route.queryParamMap?.pipe(map(paramMap => paramMap.has(NavQueryParams.goBack))) ?? of(false);
    }

    private hasHistory(): boolean {
        return this.window.history.length > 1;
    }
}
