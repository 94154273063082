<ion-card class="ion-no-margin ion-no-padding card">
    <ion-card-content>
        <app-professional-card
            [showProfessionalDescription]="true"
            *ngIf="data?.professional"
            [professional]="data?.professional"
            class="ion-margin-bottom"
        ></app-professional-card>
        <ng-container *ngIf="data?.photos?.length > 0">
            <div class="top-separator"></div>
            <section class="photos-section">
                <ion-label class="photos-title">
                    <b>{{ 'master-profile.info.favorite-photos' | translate }}</b>
                </ion-label>
                <app-image-carousel
                    class="ion-margin-vertical"
                    [photos]="data?.photos"
                    [editable]="false"
                ></app-image-carousel>
            </section>
        </ng-container>
        <div class="top-separator"></div>
        <app-service-title *ngFor="let service of serviceList"
                           [service]="service"
                           [activeLink]="true"
                           [link]="['/service', service.id]"></app-service-title>
        <div class="top-separator"></div>
        <div *ngIf="hasMoreServices" class="more-services">
            <a [routerLink]="professionalServicesUrl" [queryParams]="queryParams">
                {{ 'search.result.all-services' | translate }}
                {{ serviceCount | declension : 'services' }}
            </a>
        </div>
        <div class="bottom-buttons">
            <ion-button
                *ngIf="data?.services?.length === 1"
                size="medium"
                color="primary"
                [routerLink]="serviceOrderUrl"
                [queryParams]="queryParams"
                [id]="'bookService-'+ data?.professional.id"
            >
                {{ 'search.result.order' | translate }}
            </ion-button>
            <ion-button
                *ngIf="data?.services?.length > 1"
                size="medium"
                color="primary"
                (click)="book()"
                [routerLink]="professionalServicesUrl"
                [queryParams]="queryParams"
                [id]="'bookService-'+ data?.professional.id"
            >
                {{ 'search.result.order' | translate }}
            </ion-button>
            <ng-container *ngIf="(isUserAuthor$ | async) === false">
                <ion-button
                    color="primary"
                    fill="outline"
                    (click)="chat()"
                    [routerLink]="professionalChatUrl"
                    [queryParams]="getQueryParams(data)"
                    [id]="'professionalChat-'+ data?.professional.id"
                    *ngIf="isShort; else onlyIcon"
                >
                    {{ 'search.result.chat' | translate }}
                    <ion-icon slot="end" name="chatbubbles-outline"></ion-icon>
                </ion-button>
                <ng-template #onlyIcon>
                    <ion-button
                        size="small"
                        color="primary"
                        fill="outline"
                        style="height: inherit"
                        (click)="chat()"
                        [routerLink]="professionalChatUrl"
                        [queryParams]="getQueryParams(data)"
                        [id]="'professionalChat-'+ data?.professional.id">
                        <ion-icon slot="icon-only" name="chatbubbles-outline"></ion-icon>
                    </ion-button>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="data?.professional?.contacts.length">
                <ion-button
                    size="medium"
                    color="primary"
                    class="right-button"
                    *ngIf="isShort; else onlyIcon"
                    (click)="openContacts()" slot="end">
                    {{ 'master-profile.info.contacts' | translate }}
                </ion-button>
                <ng-template #onlyIcon>
                    <ion-button
                        size="small"
                        style="height: inherit"
                        class="right-button"
                        color="primary" slot="end"
                        (click)="openContacts()"
                    >
                        <ion-icon slot="icon-only" name="call-outline"></ion-icon>
                    </ion-button>
                </ng-template>
            </ng-container>
        </div>
    </ion-card-content>
</ion-card>

