import { Observable, Subject, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ɵNG_PIPE_DEF, ɵPipeDef, Type } from '@angular/core';

const DESTROYED_STREAM_DEFAULT_NAME = '_destroyed_';
const DEFAULT_DESTROY_METHOD_NAME = 'ngOnDestroy';
const DECORATOR_APPLIED = Symbol('UntilDestroy');
// eslint-disable-next-line camelcase
const NG_PIPE_DEF = ɵNG_PIPE_DEF as 'ɵpipe';

interface IDestroyedStreamOptions {
    destroyMethod?: Function;
}

interface PipeType<T> extends Type<T> {
    ɵpipe: ɵPipeDef<T>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function DecorateUntilDestroy(): ClassDecorator {
    return (target: Function) => {
        const destroyedStreamName = getDestroyStreamName(DEFAULT_DESTROY_METHOD_NAME);

        if (isPipe(target)) {
            const def = target.ɵpipe;
            def.onDestroy = getNewDestroyMethod(def.onDestroy, destroyedStreamName);
        } else {
            target.prototype.ngOnDestroy = getNewDestroyMethod(target.prototype.ngOnDestroy, destroyedStreamName);
        }
        target.prototype[DECORATOR_APPLIED] = true;
    };
}

export function takeUntilDestroyed<T, TClass>(
    instance: TClass,
    options: IDestroyedStreamOptions = {},
): MonoTypeOperatorFunction<T> {
    const destroyMethodName = options.destroyMethod
        ? getClassMethodName(instance, options.destroyMethod) ?? DEFAULT_DESTROY_METHOD_NAME
        : DEFAULT_DESTROY_METHOD_NAME;
    const destroyedStreamName = getDestroyStreamName(destroyMethodName);

    if (!options.destroyMethod) {
        if (!(DECORATOR_APPLIED in Object.getPrototypeOf(instance).constructor.prototype)) {
            throwError(instance, 'Missed \'@DecorateUntilDestroy\' decorator usage');
        }
        // @ts-ignore
    } else if (!instance[destroyedStreamName]) {
        // @ts-ignore
        if (!instance[destroyMethodName]) {
            throwError(instance, `Missed destroy method '${destroyMethodName}'`);
        }
        // @ts-ignore
        instance[destroyMethodName] = getNewDestroyMethod(instance[destroyMethodName], destroyedStreamName);
    }
    // @ts-ignore
    if (!instance[destroyedStreamName]) {
        // @ts-ignore
        instance[destroyedStreamName] = new Subject<void>();
    }

    // @ts-ignore
    return (source: Observable<T>) => source.pipe(takeUntil(instance[destroyedStreamName]));
}

function throwError<T>(target: T, textPart: string): void {
    throw new Error(`takeUntilDestroyed: ${textPart} in '${Object.getPrototypeOf(target).constructor.name}'`);
}

function getClassMethodName<T>(classObj: T, method: Function): string | null {
    // @ts-ignore
    const methodName = Object.getOwnPropertyNames(classObj).find(prop => classObj[prop] === method);

    if (methodName) {
        return methodName;
    }

    const proto = Object.getPrototypeOf(classObj);
    if (proto) {
        return getClassMethodName(proto, method);
    }

    return null;
}

function getNewDestroyMethod(
    originalDestroy: ((...args: unknown[]) => unknown) | null | undefined,
    destroyedStreamName: string,
): () => unknown {
    return function (this: Function, ...args: unknown[]): unknown {
        let result: unknown | undefined;
        if (originalDestroy) {
            result = originalDestroy.call(this, ...args);
        }

        // @ts-ignore
        if (this[destroyedStreamName]) {
            // @ts-ignore
            this[destroyedStreamName].next();
        }

        return result;
    };
}

function getDestroyStreamName(destroyMethodName: string): string {
    return DESTROYED_STREAM_DEFAULT_NAME + destroyMethodName;
}

export function isPipe<T>(target: Object): target is PipeType<T> {
    // @ts-ignore
    return !!target[NG_PIPE_DEF];
}
