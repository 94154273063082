<ion-header>
    <ion-toolbar>
        <ion-title>{{ 'master-profile.info.contacts' | translate }}</ion-title>
        <ion-buttons slot="end" class="close-button">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" name="close" color="dark" size="medium"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <app-contacts-view [contacts]="contacts" (closeWindow)="close()"></app-contacts-view>
</ion-content>
