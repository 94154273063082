import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Pipe({ name: 'safe' })
export class SafeUrlPipe implements PipeTransform {
    constructor(
        private readonly domSanitizer: DomSanitizer
    ) { }

    public transform(url: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}