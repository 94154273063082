import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retryWithBackoff } from '@app/core/functions/http.functions';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, tap, timeout } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/services';
import { Router } from '@angular/router';
import { NavPath } from '@app/core/constants/navigation.constants';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import * as CurrentUserActions from '@app/store/current-user/current-user.actions';
import { Select } from '@ngxs/store';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { AuthResponseInterface } from '@app/core/interfaces/auth-response.interface';

export interface ErrorResponse {
    headers: any;
    status: number;
    statusText: string;
    url: string;
    ok: boolean;
    name: string;
    message: string;
    error: Error;
}

@Injectable({
    providedIn: 'root'
})
export class RetryService implements HttpInterceptor {

    @Select(CurrentUserSelectors.tokens)
    public readonly tokens$: Observable<AuthResponseInterface>;

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req);
    }

    @Dispatch()
    private refreshTokens(): CurrentUserActions.RefreshTokens {
        return new CurrentUserActions.RefreshTokens();
    }
}
