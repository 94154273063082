import { Injectable } from '@angular/core';
import { Country } from '@app/api/models/country';
import { UserSettings } from '@app/api/models/user-settings';
import { clear, isEmptyObject } from '@app/core/functions/object.functions';
import * as CurrentUserActions from '@app/store/current-user/current-user.actions';
import { Store } from '@ngxs/store';
import { configuration } from '@conf/configuration';

type UserLanguages = 'en' | 'fr' | 'de' | 'ru' | 'es' | 'ar' | 'el'  | 'pt';

const LANG = configuration.default_lang;
const LANG_LIST = configuration.lang_list;
const CURRENCY_LIST = configuration.currency_list;

@Injectable({
    providedIn: 'root',
})
export class DefaultSettingsDetectorService {

    constructor(private readonly store: Store) {
    }

    public updateFromNavigator(): void {
        if (!LANG) {
            return;
        }
        const settings: Partial<UserSettings> = {};

        if (LANG_LIST.includes(LANG)) {
            settings.language = LANG as UserLanguages;
        }
        this.update(settings, LANG);
    }

    public updateSettings(country: Country, force: boolean = false): void {
        if (!country) {
            return;
        }
        const settings: Partial<UserSettings> = {};
        const currency = country?.currency;
        let lang = country?.language_codes?.split(',')?.shift();
        if(!configuration.lang_list.includes(lang)) {
            lang = configuration.default_lang;
        }
        if (CURRENCY_LIST.includes(currency)) {
            settings.currency = currency as 'CAD' | 'EUR' | 'RUB' | 'USD';
        }
        if (LANG_LIST.includes(lang)) {
            settings.language = lang as UserLanguages;
        }

        this.update(settings, country?.language_codes?.split(',')?.shift(), force);
    }

    private update(settings: Partial<UserSettings>, lang: string, force: boolean = false): void{
        if (!settings.language) {
            settings.language = this.getLanguageFromCode(lang);
        }
        if (!settings.currency) {
            settings.currency = this.getCurrencyFromLang(settings.language);
        }
        if (!isEmptyObject(settings)) {
            if (force) {
                this.store.dispatch(new CurrentUserActions.ChangeUserSettings(clear(settings)));
            } else {
                this.store.dispatch(new CurrentUserActions.ChangeGuessedUserSettings(clear(settings)));
            }
        }
    }

    private getCurrencyFromLang(lang: string): 'CAD' | 'EUR' | 'RUB' | 'USD' | any {
        switch (lang) {
            case 'ru':
                return 'RUB';
            case 'en':
                return 'USD';
            case 'fr':
                return 'EUR';
            case 'es':
                return 'EUR';
            case 'pt':
                return 'EUR';
            case 'de':
                return 'EUR';
            case 'el':
                return 'EUR';
            default:
                return configuration.default_currency;
        }
    }

    private getLanguageFromCode(lang: string): 'en' | 'fr' | 'de' | 'ru' | 'es' | any {
        if (this.checkLang('en', lang)) {
            return 'en';
        }
        if (this.checkLang('ru', lang)) {
            return 'ru';
        }
        if (this.checkLang('fr', lang)) {
            return 'fr';
        }
        if (this.checkLang('de', lang)) {
            return 'de';
        }
        if (this.checkLang('es', lang)) {
            return 'es';
        }

        return configuration.default_lang;
    }

    private checkLang(lang: string, detected: string): boolean {
        return detected === lang || detected.includes(`${lang}-`, 0);
    }
}
