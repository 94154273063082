import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountsService } from '@app/api/services/accounts.service';
import { ToastService } from '@app/core/services';
import {
    ImageApiServiceFactoryService,
    PhotoModelType,
    PhotoType
} from '@app/core/services/image-load/image-api-service-factory.service';

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    private readonly cachedImages = new Map();

    constructor(
        private readonly http: HttpClient,
        private readonly accountsService: AccountsService,
        private readonly toast: ToastService,
        private readonly imageUpdater: ImageApiServiceFactoryService
    ) {
    }

    public getImage(url: string): Observable<any> {

        if (this.cachedImages.has(url)) {
            return of(URL.createObjectURL(this.cachedImages.get(url)));
        }

        return this.http.get(url, { responseType: 'blob' }).pipe(
            tap(blob => this.checkAndCacheImage(url, blob)),
        );
    }

    public setFav(photo: PhotoModelType, photoType: PhotoType, isFavorite: boolean): Observable<PhotoModelType> {
        return this.imageUpdater.update(
            { ...photo, is_favorite: isFavorite },
            photoType
        ).pipe(
            catchError(error => {
                this.toast.showMessage(error.message);

                return of();
            }),
        );
    }

    private checkAndCacheImage(url: string, blob: ArrayBuffer): void {
        if (!this.cachedImages.has(url)) {
            this.cachedImages.set(url, blob);
        }
    }
}
