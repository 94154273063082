import { Injectable } from '@angular/core';
import { Category, City, Country } from '@app/api/models';
import { ProfessionalsService } from '@app/api/services';
import { ApiCache } from '@app/core/services/cache/api-cache.service';
import { concat, Observable, of } from 'rxjs';
import { map, shareReplay, tap, toArray } from 'rxjs/operators';

const PAGE_SIZE = 250;

@Injectable()
export class CategoriesApiCache extends ApiCache<Category> {
    private readonly listCache: Category[] = [];
    constructor(private readonly professionalsService: ProfessionalsService) {
        super();
    }

    public list(): Observable<Category[]> {
        return of(this.listCache);
    }

    public findCategoryBySlug(slug: string): Observable<Category> {
        if (!slug) {
            return of(null);
        }

        return this.list()
            .pipe(map(categoryList => categoryList.find(category => category.slug === slug)));
    }

    public read(id: number): Observable<Category> {
        const category = this.listCache.find(c => c.id === id);
        if(!category) {
            return this.professionalsService.professionalsCategoriesRead(id).pipe(tap(d=> this.listCache.push(d)));
        }

        return of(category);
    }
}
