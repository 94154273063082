import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export abstract class StoreLocation<S, T> {
    public readonly updateLocation$ = new Subject<T>();
    public readonly deleteLocation$ = new Subject<number>();
    protected readonly locationsSubject$ = new ReplaySubject<T[]>(1);
    protected _locations: T[] = [];
    protected _defaultLocation: T | undefined;

    abstract loadLocations:() => Observable<T[]>;
    abstract getLocationById:(id: number) => Observable<T | null>;
    abstract deleteLocation:(id: number) => Observable<T[]>;
    abstract updateLocation:(location: T) => Observable<{ allLocations: T[]; location: T }>;
    abstract createLocation:(location: T) => Observable<{ allLocations: T[]; location: T }>;

    constructor(protected readonly locationService: S) {}

    public initLocations():Observable<T[]> {
        const getLocationsSubject$ = (data: T[]): Observable<T[]> => {
            this.locationsSubject$.next(data);

            return this.locationsSubject$.asObservable();
        };

        return !this._locations.length ?
            this.loadLocations().pipe(switchMap(data => getLocationsSubject$(data))) :
            getLocationsSubject$(this._locations);
    }

    public setLocations(locations: T[]): void {
        // @ts-ignore
        this._locations = [...locations].filter(location => !!location?.country && !!location?.city);
            this.locationsSubject$.next(this._locations);
    }

    public getDefaultLocation(): T | undefined {
        return this._defaultLocation;
    }

    public resetLocations(): void {
        this._locations = [];
    }

    protected prepareLocations(value: T[]): T[] {
        this._locations = [...value];
        this.setDefaultUserLocation(value);

        return this._locations;
    }

    protected setDefaultUserLocation(value: T[]): void {
        // @ts-ignore
        this._defaultLocation = value.find(location => location.is_default) ?? value[0];
    }
}
