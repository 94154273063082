import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { configuration } from '@conf/configuration';

@Pipe({
  name: 'onlyLongDate',
  pure: false
})
export class OnlyLongDatePipe implements PipeTransform {
    constructor(private readonly translator: TranslateService) {
    }
    public transform(value?: string): string {
        const datePipe = new DatePipe(this.translator.currentLang ?? configuration.default_lang);

        return datePipe.transform(value, 'longDate');
    }
}
