import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    HostListener, OnInit,
    ViewContainerRef,
} from '@angular/core';
import { AccessToJobsBoardComponent } from '@app/shared/banners/access-to-jobs-board/access-to-jobs-board.component';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { Observable } from 'rxjs';
import { NgDestroyService } from '@app/core/services';
import { Router } from '@angular/router';
import { NavBranch, NavPath } from '@app/core/constants/navigation.constants';

@Directive({
    selector: '[appAccessToJobsBoard]',
})
export class AccessToJobsBoardDirective implements OnInit {
    @Select(CurrentUserSelectors.isMaster)
    public readonly isMaster$!: Observable<boolean>;
    private componentRef?: ComponentRef<AccessToJobsBoardComponent>;
    private isMaster = false;

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly destroy$: NgDestroyService,
        private readonly router: Router,
        private readonly viewRef: ViewContainerRef,
    ) {
    }

    @HostListener('click', ['$event'])
    public clickEvent(): void {
        if (this.isMaster) {
            this.router.navigate([NavPath.ClientApplications, NavBranch.OrderSearch], { replaceUrl: true });
        } else {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AccessToJobsBoardComponent);
            this.componentRef = this.viewRef.createComponent(componentFactory);
            this.componentRef.instance.destroy
                .pipe(
                    take(1),
                    tap(() => this.viewRef.clear()),
                    takeUntil(this.destroy$),
                ).subscribe();
        }
    }

    public ngOnInit(): void {
        this.isMaster$.pipe(takeUntil(this.destroy$)).subscribe(d => this.isMaster = d);
    }
}
