import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getLocalDateString } from '@app/core/functions/datetime.functions';
import {
    SearchFilterFormControls,
    SearchFilterFormValue,
} from '@app/search/interfaces/search-filter-form-value.interface';

/**
 * Search for services up to 2 years in future
 */
const FUTURE_TIMESPAN_YEARS = 2;

@Injectable()
export class SearchFilterStateService {
    public controls: SearchFilterFormControls = {
        query: new FormControl(null),
        location: new FormControl(null),
        categoryAndSubcategory: new FormControl(null),
        tags: new FormControl(null),
        isOnlineBooking: new FormControl(null),
        isInstantBooking: new FormControl(null),
        rangeDate: new FormControl(null),
        rangePrice: new FormControl(null),
        serviceTypes: new FormControl(null),
        rating: new FormControl(null),
        profTraits: new FormControl(null),
        onlyWithPhotos: new FormControl(null),
        onlyWithFixedPrice: new FormControl(null),
        experience: new FormControl(null),
    };
    public form = new FormGroup(this.controls);
    public needToRenderFilters = false;

    public minDate: string;
    public maxDate: string;

    constructor() {
        this.setMinMaxDates();
    }

    public patchValue(formValue: SearchFilterFormValue): void {
        this.form.patchValue(formValue, { emitEvent: false });
    }

    public updateLocation(location: { country: number; city: number }): void {
        if (location) {
            this.controls.location.setValue({ ...location });
        }
    }

    private setMinMaxDates(): void {
        const now = new Date(Date.now());
        this.minDate = getLocalDateString(now);
        this.maxDate = getLocalDateString(new Date(now.setFullYear(now.getFullYear() + FUTURE_TIMESPAN_YEARS)));
    }
}
