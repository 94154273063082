import { Component, EventEmitter, Output } from '@angular/core';
import { NavBranch, NavPath } from '@app/core/constants/navigation.constants';
import { map } from 'rxjs/operators';
import { UserSettingsService } from '@app/core/services/facades';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-professional-promo',
    templateUrl: './professional-promo.component.html',
    styleUrls: ['./professional-promo.component.scss'],
})
export class ProfessionalPromoComponent {
    @Output()
    public hideButtonClicked = new EventEmitter<void>();
    public closeButtonClass$: Observable<string>;

    constructor(
        private readonly userSettings: UserSettingsService,
        private readonly router: Router
    ) {
        this.closeButtonClass$ = this.userSettings.settings$.pipe(
            map(s => s?.language === 'ar' ? '-arabic' : ''),
        );
    }

    public navigation(): void {
        this.router.navigate([NavPath.Service, NavBranch.Publish]).then();
    }
}
