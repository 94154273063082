import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { throwIfAlreadyLoaded } from '@app/core/functions/module.functions';
import { CalendarGeneratorService } from '@app/core/services/calendar-generator.service';
import { MustBeAuthorizedGuard } from '@app/core/services/guards/must-be-authorized.guard';
import { CurrentUserFacadeService, UserSettingsService } from '@app/core/services/facades';
import {
    AuthInterceptor,
    HeadersInterceptor,
    LangInterceptorService,
    TimezoneInterceptor,
} from '@app/core/services/interceptors';
import {
    FirebaseService,
    LoadingIndicatorService,
    PlatformService,
    TranslationService,
    DarkModeService,
    AuthenticationService,
    ToastService,
    CurrentLocationService,
    MasterManagerService,
    ServiceManagerService,
    UserManagerService,
    IpServiceList,
    GuessLocationByExtremeIpLookupCom,
    GuessLocationByIpApiCo,
    GuessLocationByIpApiCom,
    GuessLocationByIpNf,
    GuessLocationByIpwhoisApp,
    LocationResolverService,
    ApiClientService,
    IsUserRegisteredApiService,
    SearchQueryService,
    SearchFilterStateService,
    SearchFilterStateConverter,
    ContactsMergeToDefaultService,
    StorageManagerService,
    TagsManagerService,
    NewMessagesNotificationService,
    IntervalService,
    GuessLocationByApiIpdataCo,
} from '@app/core/services';
import {
    CategoriesApiCache,
    CitiesApiCache,
    ContactsApiCache,
    CountriesApiCache,
    DistrictsApiCache,
    LanguagesApiCache,
    PostalCodeApiCache,
    RatesApiCache,
    RegionsApiCache,
    SubcategoriesApiCache,
    SubregionsApiCache,
    UserLanguagesApiCache,
} from '@app/core/services/cache';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { RetryService } from '@app/core/services/interceptors/retry.service';

import { environment } from '@env/environment';

@NgModule({
    imports: [
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ServiceWorkerModule.register('firebase-messaging-sw.js', { enabled: true }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeadersInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LangInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimezoneInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryService,
            multi: true,
        },
        FirebaseService,
        LoadingIndicatorService,
        PlatformService,
        TranslationService,
        DarkModeService,
        AuthenticationService,
        ToastService,
        CurrentLocationService,
        MasterManagerService,
        ServiceManagerService,
        UserManagerService,
        IpServiceList,
        GuessLocationByApiIpdataCo,
        GuessLocationByExtremeIpLookupCom,
        GuessLocationByIpApiCo,
        GuessLocationByIpApiCom,
        GuessLocationByIpNf,
        GuessLocationByIpwhoisApp,
        LocationResolverService,
        StorageManagerService,
        TagsManagerService,

        ApiClientService,
        IsUserRegisteredApiService,

        CategoriesApiCache,
        CitiesApiCache,
        ContactsApiCache,
        CountriesApiCache,
        DistrictsApiCache,
        LanguagesApiCache,
        PostalCodeApiCache,
        RatesApiCache,
        RegionsApiCache,
        SubcategoriesApiCache,
        SubregionsApiCache,
        UserLanguagesApiCache,

        MustBeAuthorizedGuard,

        CurrentUserFacadeService,
        UserSettingsService,

        SearchFilterStateService,
        SearchFilterStateConverter,
        SearchQueryService,

        ContactsMergeToDefaultService,
        NewMessagesNotificationService,
        IntervalService,
        CalendarGeneratorService,
    ],
})
export class CoreModule {
    constructor(
        // instantiating necessary services
        private readonly _platformService: PlatformService,
        private readonly _loadingIndicatorService: LoadingIndicatorService,
        private readonly _translationService: TranslationService,
        private readonly _firebaseService: FirebaseService,
        // checking if the Core module is already loaded
        @Optional() @SkipSelf() parentModule: CoreModule,
    ) {
        throwIfAlreadyLoaded(parentModule);
    }
}
