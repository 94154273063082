import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UserLocationService } from '@app/store/local-store/user-locations/user-location.service';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { LocationResolverService, NgDestroyService } from '@app/core/services';
import { PlaceSelectorDirective } from '@app/shared/components/place-selector/place-selector-wrapper/place-selector-components/place-selector.directive';
import { NavBranch, NavPath, NavQueryParams } from '@app/core/constants/navigation.constants';
import { Router } from '@angular/router';
import { Profile } from '@app/api/models/profile';
import { Select } from '@ngxs/store';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';

@Component({
    selector: 'app-user-place-selector',
    templateUrl: './user-place-selector.component.html',
    styleUrls: ['./user-place-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPlaceSelectorComponent extends PlaceSelectorDirective implements AfterViewInit {

    public profile: Profile;

    @Select(CurrentUserSelectors.profile)
    public profile$: Observable<Profile>;

    constructor(private readonly router: Router,
                protected readonly cd: ChangeDetectorRef,
                private readonly destroy$: NgDestroyService,
                private readonly fullLocationService: LocationResolverService,
                private readonly userLocationService: UserLocationService) {
        super();
    }

    public ngAfterViewInit(): void {
        this.init();
        this.userLocationService.updateLocation$.pipe(
            tap(d => this.initLocation.next(d.id)),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    public navigation(id: number): void {
        this.router.navigate([NavPath.Profile, NavBranch.LocationEdit, `${id}`],
            {
                queryParams: {
                    ...(this.requiredAddress && {required: true}),
                    [NavQueryParams.goBack]: true
                }
            });
    }

    public addItem(): void {
        this.router.navigate([NavPath.Profile, NavBranch.LocationAdd], {
            queryParams: {
                ...(this.requiredAddress && {required: true}),
                [NavQueryParams.goBack]: true
            }
        });
    }

    protected override init(): void {
        this.profile$.pipe(
            tap(d => this.profile = d),
            takeUntil(this.destroy$)
        ).subscribe();

        this.userLocationService.loadLocations().pipe(
            switchMap(list => forkJoin(
                list.map(x => this.fullLocationService.getTextLocation(x)))),
            tap(d => this.locationTextList = d),
            tap(() => this.cd.detectChanges()),
            takeUntil(this.destroy$)).subscribe();

        this.userLocationService.updateLocation$.pipe(
            mergeMap(ul => this.fullLocationService.getTextLocation(ul)),
            tap(ul => {
                let notexist = true;
                this.locationTextList.forEach(loc => {
                    if (loc.id === ul.id) {
                        loc.text = ul.text;
                        notexist = false;
                    }
                });
                if (notexist) { this.locationTextList = [...this.locationTextList, ul]; }
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.userLocationService.deleteLocation$.pipe(
            tap(ulId => this.locationTextList
                = this.locationTextList.filter(l => l.id !== ulId)),
            takeUntil(this.destroy$)).subscribe();
    }
}
