import { Injectable } from '@angular/core';
import { StorageManagerService } from '@app/core/services/storage-manager.service';
import { StepFiveDataInterface } from '@app/service/interfaces/step-five-data-interface';
import { StepFourDataInterface } from '@app/service/interfaces/step-four-data-interface';
import { StepOneDataInterface } from '@app/service/interfaces/step-one-data-interface';
import { StepSevenDataInterface } from '@app/service/interfaces/step-seven-data-interface';
import { StepSixDataInterface } from '@app/service/interfaces/step-six-data-interface';
import { StepThreeDataInterface } from '@app/service/interfaces/step-three-data-interface';
import { StepTwoDataInterface } from '@app/service/interfaces/step-two-data-interface';
import { StepDataInterfaceType } from '@app/service/types/step-data-interface-type';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ServicePublishDataHolderService {

    public initialized: Observable<boolean>;
    private readonly initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private stepsData: {
        0?: StepOneDataInterface;
        1?: StepTwoDataInterface;
        2?: StepThreeDataInterface;
        3?: StepFourDataInterface;
        4?: StepFiveDataInterface;
        5?: StepSixDataInterface;
        6?: StepSevenDataInterface;
    } = {};
    private readonly storageKey = 'service_publish_data';

    constructor(private readonly storageManager: StorageManagerService) {
        this.initialized = this.initialized$.asObservable();
        this.loadFromStorage();
    }

    public setStepData<T extends StepDataInterfaceType>(step: number, data: T): Promise<void> {
        // @ts-ignore
        this.stepsData[step] = data;

        return this.updateStorage();
    }

    public assignStepData(step: number, data: object): Promise<void> {
        // @ts-ignore
        if (!this.stepsData[step]) {
            // @ts-ignore
            this.stepsData[step] = {};
        }
        // @ts-ignore
        this.stepsData[step] = {
            // @ts-ignore
            ...this.stepsData[step],
            ...data,
        };

        return this.updateStorage();
    }

    public getStepData<T>(step: number): T {
        // @ts-ignore
        return this.stepsData[step];
    }

    public getPartialStepData<T>(step: number, data: string): T | undefined {
        try {
            // @ts-ignore
            return this.stepsData[step][data];
        } catch (e) {
            return undefined;
        }
    }

    public isset(step: number): boolean {
        // @ts-ignore
        return !!Object.keys(this.stepsData[step] ?? {}).length;
    }

    public issetSevenStep(): boolean {
        if (
            this.stepsData[6]
            && Object.keys(this.stepsData[6]).length === 1
            && this.stepsData[6]?.hasOwnProperty('timetable')
        ) {
            return false;
        }

        return this.isset(6);
    }

    public issetStepPartialData(step: number, data: string): boolean {
        // @ts-ignore
        return this.stepsData[step] !== undefined && this.stepsData[step][data] !== undefined;
    }

    public reset(): Promise<void> {
        this.stepsData = {};

        return this.storageManager.remove(this.storageKey);
    }

    public getFullData(): object {
        return this.stepsData;
    }

    private updateStorage(): Promise<void> {
        return this.storageManager.set(this.storageKey, this.stepsData);
    }

    private async loadFromStorage(): Promise<void> {
        const data = await this.storageManager.get(this.storageKey);
        if (data) {
            this.stepsData = data;
        }

        return this.initialized$.next(true);
    }
}
