<ion-row class="ion-justify-content-center ion-item-no-side-padding">
    <ion-col size="12" class="agreement-text">
        {{ 'agreement.part-1' | translate: { btn: button | translate } }}
        <a routerLink="/eula">
            {{ 'agreement.eula' | translate: { app: configuration.app_title | translate } }}
        </a>
        {{ 'agreement.part-2' | translate }}
        <a routerLink="/policy">
            {{ 'agreement.rules' | translate }}
        </a>
        {{ 'agreement.part-3' | translate }}
    </ion-col>
</ion-row>
