import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, HostListener, OnInit } from '@angular/core';
import { AuthenticationService, MasterManagerService, NgDestroyService } from '@app/core/services';
import { NewMessagesNotificationService } from '@app/core/services/new-messages-notification.service';
import CurrentUserSelectors from '@app/store/current-user/current-user.selectors';
import { Select } from '@ngxs/store';
import { NavPath } from '@app/core/constants/navigation.constants';
import { configuration } from '@conf/configuration';
import { imagePaths } from '@conf/image-paths';
import HeaderContext from './header-context.interface';

const MAX_FOR_LOGO = 968;
const MAX_FOR_BUTTONS = 640;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [NgDestroyService]
})
export class HeaderComponent implements OnInit {
    public context$: Observable<HeaderContext>;
    @Select(CurrentUserSelectors.language)
    public currentLanguage$!: Observable<string>;
    public countOfUnreadMessages$ = this.unreadMessagesService.unreadMessages$.pipe(map(response => response.count));
    public headerButtons: boolean = true;
    public longLogo: boolean = false;
    public NavPath = NavPath;
    public readonly configuration = configuration;
    public readonly imagePaths = imagePaths;
    private readonly isAuthenticated$: Observable<boolean>;

    constructor(
        public readonly unreadMessagesService: NewMessagesNotificationService,
        authenticator: AuthenticationService,
        masterManager: MasterManagerService,
    ) {
        this.isAuthenticated$ = authenticator.isAuthenticated$;
        this.context$ = combineLatest([this.isAuthenticated$, masterManager.isMaster$]).pipe(
            map(([isAuthenticated, isMaster]) => ({isAuthenticated, isMaster})),
        );
    }

    public ngOnInit(): void {
        this.headerButtons = window.innerWidth > MAX_FOR_BUTTONS;
        this.longLogo = window.innerWidth > MAX_FOR_LOGO;
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this.headerButtons = window.innerWidth > MAX_FOR_BUTTONS;
        this.longLogo = window.innerWidth > MAX_FOR_LOGO;
    }
}
