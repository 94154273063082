import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { once } from '@app/core/decorators/once';
import { NavigationService } from '@app/core/services/navigation.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsComService {

    constructor(private readonly nav: NavigationService, @Inject(DOCUMENT) private readonly doc: any) {
    }

    @once
    public init(): void {
        if (environment.ga_id_com) {
            const s = this.doc.createElement('script');
            s.type = 'text/javascript';
            // eslint-disable-next-line max-len
            s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${environment.ga_id_com}');`;
            const head = this.doc.getElementsByTagName('head')[0];
            const body = this.doc.getElementsByTagName('body')[0];
            head.appendChild(s);

            const nos = this.doc.createElement('noscript');
            // eslint-disable-next-line max-len
            nos.innerHTML = `<!-- Google Tag Manager (noscript) --><noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${environment.ga_id_com}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript><!-- End Google Tag Manager (noscript) -->`;
            body.prepend(nos);
        }
    }
}
