import { environment } from '@env/environment';

export function skippedLocationNotification(): MethodDecorator {
    return function (target: Function, key: string, descriptor: any) {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args: any[]) {


            return originalMethod.apply(this, args);
        };

        return descriptor;
    };
}
