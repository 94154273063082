import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Spell } from '@app/api/models/spell';

@Component({
  selector: 'app-spell-checker',
  templateUrl: './spell-checker.component.html',
  styleUrls: ['./spell-checker.component.scss'],
})
export class SpellCheckerComponent {
    public hints!: Spell;

    constructor(
        private readonly popoverController: PopoverController
    ) {}

    public async setValue(val: string): Promise<void> {
        await this.popoverController.dismiss({ res: val, word: this.hints.word });
    }
}
