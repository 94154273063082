import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { configuration } from '@conf/configuration';

@Injectable()
export class ApiClientService {
    constructor(private readonly http: HttpClient) {}

    public get<T>(url: string, params?: { [param: string]: string | string[] }): Observable<T> {
        return this.http.get<T>(this.getHost() + url, { params });
    }

    public post<T, V>(url: string, data: V): Observable<T>;
    public post<T>(url: string, data: T): Observable<T> {
        return this.http.post<T>(this.getHost() + url, data);
    }

    public put<T extends { id: number }>(url: string, data: T): Observable<T> {
        return this.http.put<T>(this.getHost() + url, data);
    }

    public patch<T extends { id: number }>(url: string, data: object = {}): Observable<T> {
        return this.http.patch<T>(this.getHost() + url, data);
    }

    public delete(url: string, params?: { [param: string]: string | string[] }): Observable<any> {
        return this.http.delete(this.getHost() + url, params);
    }

    public options<T>(url: string, params?: { [param: string]: string | string[] }): Observable<T> {
        return this.http.options<T>(this.getHost() + url, params);
    }

    private getHost(): string {
        return configuration.backend.url;
    }
}
