import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appTrim]',
})
export class TrimOnBlurDirective {
    private readonly _sourceRenderer: Renderer2;
    private readonly _sourceElementRef: ElementRef;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Renderer2) renderer: Renderer2) {
        this._sourceRenderer = renderer;
        this._sourceElementRef = elementRef;
    }

    @HostListener('input', ['$event.type', '$event.target.value'])
    @HostListener('blur', ['$event.type', '$event.target.value'])
    @HostListener('change', ['$event.type', '$event.target.value'])
    // @ts-ignore TODO: refactor it, typings
    public onChange(event, value): void {
        this._sourceRenderer.setAttribute(this._sourceElementRef.nativeElement, 'value', value.trim());
    }
}
