<ion-content>
    <main class="main" [class.bgLight]="isBgLight" [class.large]="isWidthMiddle" [class.wide]="isWidthWide">
        <div class="head" *ngIf="hideHeader" [class.hide]="hideHeader | async" [class.headerFull]="headerFull">
            <ng-content select="[header]"></ng-content>
        </div>
        <div class="content" [class.bgTransparent]="isBgTransparent">
            <ng-content></ng-content>
        </div>
        <ng-content select="[more-content]"></ng-content>
        <div class="actions" [class.actionsFull]="actionsFull">
            <ng-content select="[actions]"></ng-content>
        </div>
    </main>
    <ng-content select="[footer]"></ng-content>
</ion-content>
