import { Component, Input } from '@angular/core';
import { Price } from '@app/api/models/price';
import { configuration } from '@conf/configuration';

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss'],
})
export class PriceComponent {
    @Input() public price!: Partial<Price>;
    public readonly digits = '1.0-2'; // 0..2 decimal digits
    public readonly locale = configuration.locales[0].id ?? 'fr-CA'; // whitespace grouping separator
    public readonly configuration = configuration;

    public typeofPriceIsNotNumber(): boolean {
        return this.price && isNaN(Number(this.price.price));
    }
}
