import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavQueryParams } from '@app/core/constants/navigation.constants';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BackableRedirectService } from '@app/core/services/backable-redirect.service';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/core/operators/take-until-destroyed';

@DecorateUntilDestroy()
@Component({
    selector: 'app-column-header',
    templateUrl: './column-header.component.html',
    styleUrls: ['./column-header.component.scss'],
})
export class ColumnHeaderComponent {
    @Input()
    public set backUrl(value: string) {
        this.backUrl$.next(value);
    }

    @Input()
    public set forceBackUrl(value: string) {
        this.forceBackUrl$.next(value);
    }

    @Input()
    public hasDiscard: boolean = false;

    @Input()
    public hasSeparator: boolean = true;

    @Input()
    public customBack: boolean = false;

    @Output()
    public willBack = new EventEmitter<void>();

    @Output()
    public discard = new EventEmitter<void>();

    @Output()
    public back = new EventEmitter<void>();

    public canGoBack$: Observable<boolean>;

    private readonly backUrl$: BehaviorSubject<string>;
    private readonly forceBackUrl$: BehaviorSubject<string>;
    private readonly preferHistory$: Observable<boolean>;

    constructor(
        private readonly navController: NavController,
        route: ActivatedRoute,
        private readonly backableRedirect: BackableRedirectService
    ) {
        this.backUrl$ = new BehaviorSubject<string>('');
        this.forceBackUrl$ = new BehaviorSubject<string>('');
        this.preferHistory$ =
            route.queryParamMap?.pipe(map(paramMap => paramMap.has(NavQueryParams.goBack))) ?? of(false);
        this.canGoBack$ = combineLatest([this.preferHistory$, this.backUrl$]).pipe(
            map(([preferHistory, url]) => preferHistory || Boolean(url)),
        );
    }

    public navigateBack(): void {
        this.willBack.emit();
        combineLatest([this.backUrl$, this.forceBackUrl$])
            .pipe(
                first(),
                takeUntilDestroyed(this),
            )
            .subscribe(([backUrl, forceBackUrl]) => {
                if (forceBackUrl) {
                    this.navController.navigateBack(forceBackUrl);
                } else {
                    this.backableRedirect.goBackOr(backUrl);
                }
            });
    }
}
