import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Rate, UserLocation } from '@app/api/models';
import { NgDestroyService } from '@app/core/services';
import { RatesApiCache } from '@app/core/services/cache';
import { UserManagerService } from '@app/core/services/managers/user-manager.service';
import { UserSettingsService } from '@app/core/services/facades/user-settings.service';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-flag-menu',
    templateUrl: './flag-menu.component.html',
    styleUrls: ['./flag-menu.component.scss'],
    providers: [NgDestroyService],
})
export class FlagMenuComponent implements OnInit { // TODO: refactor it
    public defaultLocation$: Observable<UserLocation>;
    public rateList$: Observable<Rate[]>;
    public formFields = {
        currency: 'currency',
        units: 'units',
        is_monday_start_of_a_week: 'is_monday_start_of_a_week',
    };
    public languageControl = this.fb.control(null);
    public form: FormGroup = this.fb.group({
        [this.formFields.currency]: null,
        [this.formFields.units]: null,
        [this.formFields.is_monday_start_of_a_week]: null,
    });
    public langList: { code: string; view: string }[] = [];

    constructor(
        public readonly userSettingsService: UserSettingsService,
        private readonly fb: FormBuilder,
        private readonly destroy$: NgDestroyService,
        private readonly tr: TranslateService,
        userManager: UserManagerService,
        rates: RatesApiCache,
    ) {
        this.defaultLocation$ = userManager.defaultLocation$.pipe(filter(x => !!x));
        this.rateList$ = rates.list();
        this.subscribeUserSettings();
        this.subscribeFormValueChanges();
        this.subscribeLanguageControlValueChanges();
    }

    public ngOnInit(): void {
        // @ts-ignore
        this.langList = this.userSettingsService.langList.map(lang =>
            // @ts-ignore
            ({ code: lang, view: this.crutch(lang) }));
    }

    private subscribeUserSettings(): void {
        this.userSettingsService.userSettings$.pipe(takeUntil(this.destroy$)).subscribe(userSettings => {
            this.form.patchValue(userSettings, { emitEvent: false });
            this.languageControl.setValue(
                // @ts-ignore
                { code: userSettings.language, view: this.crutch(userSettings.language) },
                { emitEvent: false }
            );
        });
    }

    private subscribeFormValueChanges(): void {
        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
            this.userSettingsService.saveSettings(changes);
        });
    }

    private subscribeLanguageControlValueChanges(): void {
        this.languageControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
            this.userSettingsService.saveSettingsLanguage(changes.code);
        });
    }

    private crutch(lang: string): string {
        switch (lang) {
            case 'en':
                return 'English';
            case 'ru':
                return 'Русский';
            case 'fr':
                return 'Français';
            case 'de':
                return 'Deutsch';
            case 'el':
                return 'Ελληνικά';
            case 'es':
                return 'Español';
            case 'pt':
                return 'Português';
            case 'tr':
                return 'Türk';
            case 'ko':
                return '한국어';
            case 'ar':
                return 'عرب';
            default:
                return 'English';
        }
    }
}
