import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProfessionalList } from '@app/api/models';
import { getProfessionalProfileUrl, getProfessionalReviewsUrl } from '@app/core/functions/navigation.functions';
import { getProfessionalName } from '@app/core/functions/professional.functions';
import { Router } from '@angular/router';
import { NavQueryParams } from '@app/core/constants/navigation.constants';

@Component({
    selector: 'app-professional-card',
    templateUrl: './professional-card.component.html',
    styleUrls: ['./professional-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfessionalCardComponent {
    @Input() public professional!: ProfessionalList;
    @Input() public showProfessionalDescription: boolean = false;
    @Input() public previewMode = false;
    @Input() public showSavedProfessionalToggle: boolean = true;
    public readonly queryParams = { [NavQueryParams.goBack]: true };

    constructor(private readonly router: Router) {
    }

    public get professionalName(): string {
        return getProfessionalName(this.professional);
    }

    public get defaultCityId(): number | undefined {
        if (!this.professional?.locations) {
            return NaN;
        }
        for (const loc of this.professional.locations) {
            if (loc.is_default) {
                return loc.city ?? void 0;
            }
        }

        return this.professional.locations[0]?.city ?? void 0;
    }

    public navToProfile(): void {
        if (!this.previewMode) {
            this.router.navigate([getProfessionalProfileUrl(this.professional?.id as number)],
                { queryParams: this.queryParams }).then();
        }
    }

    public navToReviewsUrl(): void {
        this.router.navigate(
            [getProfessionalReviewsUrl(this.professional?.id as number)],
            { queryParams: this.queryParams }
        );
    }
}
