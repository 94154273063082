import { DeclensionRuleSetT } from '@app/core/declination-cities/models/declension.types';

export default {
    exceptions: [],
    suffixes: [
        {
            gender: 'neuter',
            test: ['лок'],
            mods: ['--ка', '--ку', '.', '--кос', '--ке'],
        },
        {
            gender: 'neuter',
            test: ['ки'],
            mods: ['-ов', '-ам', '.', '-ами', '-ах'],
        },
        {
            gender: 'neuter',
            test: ['щи'],
            mods: ['-ев', '-ам', '.', '-ами', '-ах'],
        },
        {
            gender: 'neuter',
            test: ['е', 'ё', 'и', 'о', 'у', 'ы', 'э', 'ю'],
            mods: ['.', '.', '.', '.', '.'],
        },
        {
            gender: 'male',
            test: ['уа', 'иа'],
            mods: ['.', '.', '.', '.', '.'],
        },
        {
            gender: 'female',
            test: [
                'б',
                'в',
                'г',
                'д',
                'ж',
                'з',
                'й',
                'к',
                'л',
                'м',
                'н',
                'п',
                'р',
                'с',
                'т',
                'ф',
                'х',
                'ц',
                'ч',
                'ш',
                'щ',
                'ъ',
                'иа',
                'ль',
            ],
            mods: ['.', '.', '.', '.', '.'],
        },
        {
            gender: 'female',
            test: ['ь'],
            mods: ['-и', '-и', '.', 'ю', '-и'],
        },
        {
            gender: 'male',
            test: ['ь'],
            mods: ['-я', '-ю', '-я', '-ем', '-е'],
        },
        {
            gender: 'neuter',
            test: ['га', 'ка', 'ха', 'ча', 'ща', 'жа'],
            mods: ['-и', '-е', '-у', '-ой', '-е'],
        },
        {
            gender: 'female',
            test: ['ша'],
            mods: ['-и', '-е', '-у', '-ей', '-е'],
        },
        {
            gender: 'male',
            test: ['ша', 'ча', 'жа'],
            mods: ['-и', '-е', '-у', '-ей', '-е'],
        },
        {
            gender: 'neuter',
            test: ['а'],
            mods: ['-ы', '-е', '-у', '-ой', '-е'],
        },
        {
            gender: 'female',
            test: ['ия'],
            mods: ['-и', '-и', '-ю', '-ей', '-и'],
        },
        {
            gender: 'female',
            test: ['ка', 'га', 'ха'],
            mods: ['-и', '-е', '-у', '-ой', '-е'],
        },
        {
            gender: 'female',
            test: ['ца'],
            mods: ['-ы', '-е', '-у', '-ей', '-е'],
        },
        {
            gender: 'female',
            test: ['а'],
            mods: ['-ы', '-е', '-у', '-ой', '-е'],
        },
        {
            gender: 'female',
            test: ['я'],
            mods: ['-и', '-е', '-ю', '-ей', '-е'],
        },
        {
            gender: 'male',
            test: ['ия'],
            mods: ['-и', '-и', '-ю', '-ей', '-и'],
        },
        {
            gender: 'male',
            test: ['я'],
            mods: ['-и', '-е', '-ю', '-ей', '-е'],
        },
        {
            gender: 'male',
            test: ['ий'],
            mods: ['-я', '-ю', '-я', '-ем', '-и'],
        },
        {
            gender: 'male',
            test: ['ый', 'кий', 'хий'],
            mods: ['--ого', '--ому', '--ого', '-м', '--ом'],
        },
        {
            gender: 'male',
            test: ['ей', 'й'],
            mods: ['-я', '-ю', '-я', '-ем', '-е'],
        },
        {
            gender: 'male',
            test: ['ш', 'ж'],
            mods: ['а', 'у', 'а', 'ем', 'е'],
        },
        {
            gender: 'male',
            test: ['ёл'],
            mods: ['--ла', '--лу', '--ла', '--лом', '--ле'],
        },
        {
            gender: 'male',
            test: ['ёк'],
            mods: ['--ька', '--ьку', '--ька', '--ьком', '--ьке'],
        },
        {
            gender: 'male',
            test: [
                'б',
                'в',
                'г',
                'д',
                'ж',
                'з',
                'к',
                'л',
                'м',
                'н',
                'п',
                'р',
                'с',
                'т',
                'ф',
                'х',
                'ц',
                'ч',
            ],
            mods: ['а', 'у', 'а', 'ом', 'е'],
        },
        {
            gender: 'neuter',
            test: ['ния', 'рия', 'вия'],
            mods: ['-и', '-и', '-ю', '-ем', '-ем'],
        },
    ],
} as DeclensionRuleSetT;