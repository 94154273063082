import { forkJoin, Observable, of } from 'rxjs';
import { Country } from '@app/api/models/country';
import { City } from '@app/api/models/city';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LocationService } from '@app/api/services';

export interface GeolocationData {
    countryCode: string;
    city: string;
}

export abstract class AbstractIpService {

    constructor(protected http: HttpClient, protected api: LocationService){ };
    public abstract guess(): Observable<{ country: Country; city: City }>;
    protected abstract getGeolocationData(data: any): GeolocationData;

    protected extractData<T>(source$: Observable<T>): Observable<{ country: Country; city: City }> {
        return source$.pipe(
            mergeMap(data => {
                if (null === data) {
                    throw new Error();
                }

                return this.getFromApi(this.getGeolocationData(data));
            }),
        );
    }

    protected getFromApi(data: GeolocationData): Observable<{ country: Country; city: City }> {
        return this.api.locationCountriesList({ code: data.countryCode }).pipe(
            mergeMap(countries => {
                const country = countries.results[0];

                return forkJoin([
                    of(country),
                    this.api.locationCitiesList({ country: `${country.id}`, byName: data.city }),
                ]);
            }),
            map(([country, cities]) => ({ country, city: cities.results[0] })),
        );
    }
}
