import { Injectable } from '@angular/core';
import { ServicePhoto } from '@app/api/models/service-photo';
import { ProfessionalPhoto } from '@app/api/models/professional-photo';
import { AccountsService } from '@app/api/services/accounts.service';
import { Observable } from 'rxjs';
import { clear } from '@app/core/functions/object.functions';

export enum PhotoType {
    professional = 'professional',
    service = 'service'
}

export type PhotoModelType = ServicePhoto | ProfessionalPhoto;

@Injectable({
    providedIn: 'root'
})
export class ImageApiServiceFactoryService {

    constructor(private readonly accountsService: AccountsService) {
    }

    public update(photo: PhotoModelType, type: PhotoType): Observable<PhotoModelType> {
        // @ts-ignore
        photo = clear<PhotoModelType>({ ...photo, photo: null, photo_thumbnail: null });
        switch (type) {
            case PhotoType.professional:
                return this.accountsService.accountsProfessionalPhotosPartialUpdate(
                    { id: photo.id as number, data: photo as ProfessionalPhoto }
                );
            case PhotoType.service:
                return this.accountsService.accountsServicePhotosPartialUpdate(
                    { id: photo.id as number, data: photo as ServicePhoto }
                );
            default:
                throw new Error('wrong photo type');
        }
    }
}
