<form [formGroup]="uploadForm" class="form">
    <div class="upload">
        <input class="file-upload" type="file" multiple (input)="upload($event.target.files)" #fileUpload />
        <div class="image-hint" fileUpload><ion-icon name="image-outline"></ion-icon>
            <div>{{ hint }}</div>
        </div>

        <div class="download-button">
            <ion-button slot="" fill="outline" (click)="fileUpload.click()">
                {{ 'client-applications.image.download' | translate }}
                <ion-icon name="download-outline"></ion-icon>
            </ion-button>
        </div>
    </div>
        <ion-slides
            class="slides"
            appViewImageSlider
            [options]="slideOptions"
            [canDelete]="true"
            (delete)="closeImage.emit(images[$event])"
        >
            <div class="preview">
                <div *ngFor="let photo of images" class="image-block">
                    <ion-icon name="close" class="icon-close" (click)="closeImage.emit(photo)"></ion-icon>
                    <ion-img appViewOnClick [src]="photo.photo_thumbnail" [fullSizeSrc]="photo.photo" [photo]="photo"></ion-img>
                </div>
            </div>
        </ion-slides>
</form>
